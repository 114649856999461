import { Component, OnInit } from "@angular/core";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { IsMobileService } from "src/app/Services/is-mobile.service";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-controle",
  templateUrl: "./controle.component.html",
  styleUrls: ["./controle.component.css"],
})
export class ControleComponent implements OnInit {
  constructor(public auth: Auth, public bar: BarInfo) {}

  ngOnInit(): void {}
}
