import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { SortColumn, SortEvent } from "../../CustomerApp/statistics.directive";
import { NgbdSortableHeader } from "src/app/Helpers/directive/sort-event.directive";

import { Timestamp } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PaymentModalComponent } from "../payment-modal/payment-modal.component";

@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.css"],
})
export class PaymentsComponent implements OnInit {
  dateStart: Date = new Date();
  dateEnd = new Date();
  isLive: boolean = false;
  payments: CCVPaymentStatus[] = [];
  filteredPayments: CCVPaymentStatus[] = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  page: number = 1;
  pageSize: number = 15;
  sortColumn: SortColumn;
  sortDirection: string;
  constructor(
    public alert: AlertsService,
    public loading: LoadingService,
    public db: StaffAppDatabase,
    public modal: NgbModal
  ) {
    //start to this morning
    this.dateStart.setHours(0, 0, 0, 0);
    //end to today
    this.dateEnd.setHours(23, 59, 59, 999);
  }

  ngOnInit(): void {
    this.getData();
  }

  dateChanged(isStart: boolean, event: any) {
    console.log(event);
    this.isLive = false;
    this.db.stopPaymentsLive();
    this.dateStart = new Date(event.target.value);
    this.dateStart.setHours(0, 0, 0, 0);
    this.dateEnd = new Date(this.dateStart);
    this.dateEnd.setHours(23, 59, 59, 999);
  }

  toggleLive() {
    if (this.isLive) {
      this.isLive = false;
      this.db.stopPaymentsLive();
      //stop live
    } else {
      this.dateStart = new Date();
      this.dateStart.setHours(0, 0, 0, 0);
      this.dateEnd = new Date();
      this.dateEnd.setHours(23, 59, 59, 999);
      this.isLive = true;
      this.getData();
      //start live
    }
  }

  async getData() {
    //is start to end more than 24 hour?
    if (this.dateEnd.getTime() - this.dateStart.getTime() > 86400000 * 2) {
      this.alert.nextEmit(
        AlertsService.error("Date range must be less than 48 hours")
      );
      return;
    }

    this.loading.show();
    console.log(this.dateStart, this.dateEnd);
    if (this.isLive) {
      this.db
        .getPaymentsLive(this.dateStart, this.dateEnd)
        .subscribe((list) => {
          this.alert.nextEmit(AlertsService.good("Payments loaded"));
          this.payments = list as CCVPaymentStatus[];
          this.sort();
          console.log(this.payments);
          this.loading.hide();
        });
    } else {
      this.payments = await this.db
        .getPayments(this.dateStart, this.dateEnd)
        .then((list) => {
          this.alert.nextEmit(AlertsService.good("Payments loaded"));
          return list as CCVPaymentStatus[];
        })
        .catch((err) => {
          this.alert.nextEmit(AlertsService.error("Error getting payments"));
          return [];
        });

      this.sort();
      console.log(this.payments);
      this.loading.hide();
    }
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;
    this.sort();
  }

  sort() {
    //sort payments based on sortColumn and sortDirection (if they are null default to date and asc)
    console.log("sorting: ", this.sortColumn, this.sortDirection);
    if (this.sortColumn == null) this.sortColumn = "date";
    if (this.sortDirection == null) this.sortDirection = "desc";

    this.payments = this.payments.sort((a, b) => {
      let isAsc = this.sortDirection === "asc";
      switch (this.sortColumn) {
        case "date":
          return PaymentsComponent.compare(a.date, b.date, isAsc);
        case "amount":
          return PaymentsComponent.compare(a.amount, b.amount, isAsc);
        case "status":
          return PaymentsComponent.compare(a.status, b.status, isAsc);
        case "deviceId":
          return PaymentsComponent.compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });

    this.filteredPayments = this.payments.slice(
      this.pageSize * (this.page - 1),
      this.pageSize * this.page
    );
    console.log(this.payments);
  }

  static compare(a: any, b: any, isAsc: boolean) {
    if (a == b) return 0;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openPayment(payment) {
    var modalInsance = this.modal.open(PaymentModalComponent, {
      size: "lg",
      centered: true,
    });
    modalInsance.componentInstance.payment = payment;
  }
}

export class CCVPaymentStatus {
  amount: number;
  bar: string;
  ccvId: string;
  ccvRequestId: string;
  date: number;
  deviceId: string;
  ejournal: string;
  errors: string;
  id: string;
  orderItems: any[];
  pinCashAmount: number;
  pinStatus: string;
  qrAmount: number;
  qrStatus: string;
  status: string;
  table: number;
  tillStatus: string;
  tip: any;
  type: string;
  untilId: string; //this is currently wrong in the database

  customerReceipt: string[];
  merchantReceipt: string[];

  customerSignature: string;
  merchantSignature: string;
  customerIdentification: string;
}
