import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { CountingServiceService } from "src/app/Services/counting-service.service";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-counting-modal",
  templateUrl: "./counting-modal.component.html",
  styleUrls: ["./counting-modal.component.scss"],
})
export class CountingModalComponent implements OnInit {
  @Input() data: any;
  @Input() date: Date;
  worked: any[] = [];

  tab: number = 0;
  tab2: number = 0;
  all1 = false;
  all2 = false;
  moreInfo1 = false;
  moreInfo2 = false;
  constructor(
    public alert: AlertsService,
    public loading: LoadingService,
    public counting: CountingServiceService,
    public modal: NgbActiveModal,
    public db: StaffAppDatabase,
    public bi: BarInfo
  ) {
    console.log(this.data);
    console.log(this.date);
  }

  ngOnInit(): void {
    this.loading.show();

    this.getData();
  }

  async getData() {
    console.log("getting date: " + this.date);
    this.worked = await this.db.getHoursForDate2(this.bi.bar, this.date);
    console.log(this.worked);

    var val = this.data.till;
    var expected: any = {};
    if (val.internet) {
      expected.internet = Number(val.internet);
    }

    if (val.other) {
      expected.other = Number(val.other);
    }
    if (val.expectedCash) {
      expected.cash = Number(val.expectedCash);
    }
    if (val.expectedPin) {
      expected.pin = Number(val.expectedPin);
    }
    if (val.staffDrinks) {
      try {
        console.log(val.staffDrinks);
        const results = [].concat.apply(
          [],
          val.staffDrinks.map((a) => {
            const items = [].concat.apply(
              [],
              a.map((b) => {
                return [].concat.apply(
                  [],
                  b.items.map((c) => {
                    var str = [];

                    str.push({
                      id: c.articleId,
                      quantity: c.quantity,
                      price: c.singlePrice,
                      discount: c.discount,
                    });

                    return str;
                  })
                );
              })
            );

            return items.map((a) => a.quantity * a.price);
          })
        );

        expected.staffDrinks = results.reduce((a, b) => a + b, 0);
      } catch (e) {
        expected.staffDrinks = 0;
      }
    }

    this.data.stock.reason = await this.db.getIncorrectionReason(
      this.bi.bar,
      this.date
    );

    console.log(this.data);

    this.data.till.expected = expected;
    this.loading.hide();
  }

  incorrect(list: any[], all = false) {
    if (all) {
      return list;
    }
    return list.filter(
      (a) => a.difference != 0 || (a.difference == 0 && a.currentOffset != 0)
    );
  }
  dismiss() {
    this.modal.close("dismiss");
  }

  isFinite(number) {
    return Number.isInteger(number);
  }
}
