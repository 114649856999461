import {
  AngularFireDatabase,
  SnapshotAction,
} from "@angular/fire/compat/database";

import { Observable, Subscription, BehaviorSubject, of, Subject } from "rxjs";
import { Inject, Injectable, PipeTransform } from "@angular/core";
import { HttpEventType } from "@angular/common/http";
import { validateEventsArray } from "@angular/fire/compat/firestore/public_api";

import { LoadingService } from "./loading.service";
import {
  debounceTime,
  delay,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";

import { DecimalPipe } from "@angular/common";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { isatty } from "tty";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { cloneDeep } from "lodash";
import { initializeApp } from "firebase/app";
import { environment } from "src/environments/environment";
import { Artist } from "../Classes/artist";
import { Song } from "../Classes/song";
import { SortColumn } from "../Helpers/directive/sort-event.directive";
import { BarInfo } from "../Helpers/functions/BarInfo";
import { MyDate } from "../Helpers/functions/MyDate";
import { MyUnorg } from "../Helpers/functions/MyUnorg";
import { EquationModel } from "../Classes/equation-model";
import { CurrentPrice } from "../Classes/current-price";
import { PriceStatistics } from "../Classes/price-statistics";
import { UserFlag } from "../Classes/user-flag";

interface SearchResult {
  countries: Artist[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: any;
  reviewedCheck: boolean;
  duplicates: boolean;
  badtimes: boolean;
}

const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(
  countries: Artist[],
  column: SortColumn,
  direction: string
): Artist[] {
  return [...countries].sort((a, b) => {
    try {
      if (!a || !a.name) {
        console.log("no name", a);
        return -1;
      } else if (!b || !b.name) {
        console.log("no name", b);
        return 1;
      }
      const res = compare(a.name.toLowerCase(), b.name.toLowerCase());
      return res;
    } catch (e) {
      console.log("error", e);
      return -1;
    }
  });
}

function matches(
  catalogue: Artist[],
  country: Artist,
  term: string,
  reviewedCheck: boolean,
  pipe: PipeTransform,
  duplicates: boolean,
  badtimes: boolean
) {
  const pattern = /^[0-9]{1,2}:[0-5][0-9](\.[0-9]+)?$/;
  try {
    return (
      (!badtimes ||
        country.songs.filter((a) => !isValidTimeFormat(a.length)).length) &&
      (!reviewedCheck ||
        country.songs.filter((a) => a.reviewed != "reviewed").length) &&
      (!duplicates ||
        catalogue.filter((a) => a.name.includes(country.name)).length > 1) &&
      (country.name.toLowerCase().includes(term.toLowerCase()) ||
        country.songs.filter((a) =>
          a.name.toLowerCase().includes(term.toLowerCase())
        ).length)
    );
  } catch (e) {
    console.log("error", e);
    return false;
  }
}

function isValidTimeFormat(inputString) {
  // Check if the inputString is null, undefined, "0:00", "0.0", or "0"
  if (
    inputString === null ||
    inputString === undefined ||
    inputString === "0:00" ||
    inputString === "0.0" ||
    inputString === "0" ||
    inputString === ""
  ) {
    return false;
  }

  // Define the regular expression pattern for the format mm:ss or mm:ss.s or m:ss or m:ss.s
  const pattern = /^[0-9]{1,2}:[0-5][0-9](\.[0-9]+)?$/;

  // Use the test() method to check if the inputString matches the pattern
  return pattern.test(inputString);
}

@Injectable({
  providedIn: "root",
})
export class Firebase {
  items: Observable<any>;
  sub: Subscription;
  func: MyUnorg;
  myDate: MyDate;

  catalogue: Artist[] = [];

  user: Observable<any>;

  constructor(
    @Inject("firebaseFunctions1") private functions: AngularFireFunctions,
    @Inject("firebaseStorage") private storage: AngularFireStorage,
    @Inject("firebaseProject1") private db: AngularFireDatabase,
    private barInfo: BarInfo,
    private loading: LoadingService,
    private pipe: DecimalPipe,
    @Inject("firebaseAuthProject1") public afAuth: AngularFireAuth
  ) {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._countries$.next(result.countries);
        this._total$.next(result.total);
      });

    this.user = this.afAuth.authState;
  }

  async getVoucherHistory(key) {
    return await this.db
      .list("voucherUsage/" + key)
      .snapshotChanges()
      .pipe(
        take(1),
        map((a) =>
          a.map((b) => {
            return { key: b.key, date: b.payload.val() };
          })
        )
      )
      .toPromise();
  }
  async moveSongPhoto(cur, fur) {
    return await this.functions
      .httpsCallable("movePhoto")({ current: cur, future: fur })
      .pipe(take(1))
      .toPromise();
  }
  async getCreditCost(bar: string, day: number) {
    return this.db
      .object("bars/" + bar + "/price/" + day + "/euros")
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async setCreditCost(
    bar: string,
    day: number,
    value: number,
    credits: number
  ) {
    if (Number(value) && Number(value) > 0 && day >= 0 && day <= 6) {
      return this.db
        .object("bars/" + bar + "/price/" + day)
        .set({ euros: value, credits });
    } else {
      throw new Error("values not correct");
    }
  }

  async setAuth(isAdmin: boolean) {
    const user = await this.user
      .pipe(take(1))
      .toPromise()
      .then((a) => a)
      .catch((e) => null);
    const var3 = await this.functions
      .httpsCallable("setAdmin")({ uid: user.uid, admin: isAdmin })
      .pipe(take(1))
      .toPromise()
      .then((a) => a)
      .catch((e) => e);
    console.log(var3);
  }

  async login(email: string, password: string) {
    await this.afAuth.signInWithEmailAndPassword(email, password);
    this.afAuth.authState.subscribe((val) => {
      console.log(val);
      val.getIdTokenResult().then((a) => {
        console.log(a);
      });
    });
  }
  async getQrScanned(bar) {
    return await this.db
      .list("QRScanned/" + bar, (query) => query.limitToLast(1000))
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async buyRequest(request) {
    await this.db
      .object("songRequests/" + request.ref + "/bought")
      .set(request.bought);
    if (request.bought) {
      // await this.db.object('songsToAdd/' + request.ref).set(request);
    }
  }

  async getCanDutch(bar) {
    return await this.db
      .object("bars/" + bar + "/canDutch/")
      .valueChanges()
      .pipe(
        take(1),
        map((a) => Boolean(a))
      )
      .toPromise();
  }

  getCanDutchSub(bar) {
    return this.db.object("bars/" + bar + "/canDutch/").valueChanges();
  }

  async setCanDutch(bar, canDutch: boolean) {
    console.log("setting can dutch", bar, canDutch);
    return await this.db.object("bars/" + bar + "/canDutch/").set(canDutch);
  }

  getCanDutchSchedule(bar) {
    return this.db
      .list("bars/" + bar + "/languageSchedule")
      .snapshotChanges()
      .pipe(
        map((a) => {
          var schedule = {};
          a.forEach((b) => {
            const data: any = b.payload.val();
            const key = b.payload.key;
            schedule[key] = data;
          });

          return schedule;
        })
      );
  }

  async setCanDutchSchedule(bar, day, canDutch: boolean) {
    console.log("setting can dutch", bar, canDutch);
    return await this.db
      .object("bars/" + bar + "/languageSchedule/" + day + "/canDutch")
      .set(canDutch);
  }

  async getSongsToAdd() {
    return this.db
      .list("songsToAdd")
      .snapshotChanges()
      .pipe(
        map((a) =>
          a.map((b) => {
            const data: any = b.payload.val();
            const ref = b.payload.key;
            return { ref, ...data };
          })
        ),
        take(1)
      )
      .toPromise();
  }

  async addedSongToAdd(request) {
    return await this.db.list("songsToAdd/" + request.ref).remove();
  }
  async logout() {
    return await this.afAuth.signOut();
  }

  async forgotPassword(email) {
    return await this.afAuth.sendPasswordResetEmail(email);
  }

  getSong(key, index) {
    return this.db.object("tempCatalogue/" + key + "/songs/" + index);
  }

  getUser(key) {
    return this.db.object("users/" + key);
  }

  getUserReceipts(key) {
    return this.db
      .list("UserQrScanned/" + key, (query) => query.limitToLast(100))
      .snapshotChanges()
      .pipe(
        take(1),
        map((a) =>
          a.map((b) => {
            return { key: b.key, ...(b.payload.val() as any) };
          })
        )
      )
      .toPromise();
  }

  getUserAsync(key) {
    return this.db
      .object("users/" + key)
      .valueChanges()
      .pipe(
        take(1),
        map((a: any) => {
          a.key = key;
          return a;
        })
      )
      .toPromise();
  }
  async init() {
    this.loading.nextEmit("on");
    this._loading$.next(true);
    this.catalogue = await this.getArtists()
      .pipe(
        take(1),
        map((actions) =>
          actions.map((a: any, index) => {
            const data = a.payload.val(); // DB Questions
            if (data && data.songs) {
              data.songs = data.songs
                .map((song: Song, index: number) => {
                  song.index = index + "";
                  var so = Object.assign(new Song(), song);
                  so.review();
                  return so;
                })
                .filter((a) => {
                  return true;
                });
            } else {
              data.songs = [];
            }

            const key = a.payload.key;
            return { key, ...data };
          })
        )
      )
      .toPromise()
      .then((a) => a)
      .catch((e) => {
        console.error(e);
        return [];
      });
    console.log(this.catalogue);
    this._search$.next();
    this.loading.nextEmit("");
    this._loading$.next(false);
  }

  fixCredits() {
    return this.functions
      .httpsCallable("testFixCredits")({})
      .pipe()
      .toPromise();
  }

  getUsersFiltered(filter): Observable<any[]> {
    return this.db
      .list("users", filter)
      .snapshotChanges()
      .pipe(
        take(1),
        map((actions) =>
          actions.map((a: any, index) => {
            const data = a.payload.val(); // DB Questions

            const key = a.payload.key;
            return { key, ...data };
          })
        )
      );
  }
  getUsers(name, searchType): Observable<any[]> {
    const nextName =
      String.fromCharCode(name[0].charCodeAt() + 1) +
      name.slice(1, name.length);
    console.log("getting email", name, nextName, searchType);

    var snapshotChanges: Observable<SnapshotAction<unknown>[]>;
    if (searchType === "key") {
      snapshotChanges = this.db
        .list("users", (ref) => ref.orderByKey().startAt(name).endAt(nextName))
        .snapshotChanges();
    } else if (searchType == "email") {
      snapshotChanges = this.db
        .list("users", (ref) =>
          ref.orderByChild("email").startAt(name).endAt(nextName)
        )
        .snapshotChanges();
    } else if (searchType == "userName") {
      snapshotChanges = this.db
        .list("users", (ref) =>
          ref.orderByChild("userName").startAt(name).endAt(nextName)
        )
        .snapshotChanges();
    }

    return snapshotChanges.pipe(
      take(1),
      map((actions) =>
        actions.map((a: any, index) => {
          const data = a.payload.val(); // DB Questions

          const key = a.payload.key;
          return { key, ...data };
        })
      )
    );
  }

  getSongDemands(bar: string): Observable<any[]> {
    return this.db.list("bars/" + bar + "/songDemands").snapshotChanges();
  }

  //save name and save credits
  async saveUser(user) {
    var res = await this.db
      .object("users/" + user.key + "/userName")
      .set(user.userName)
      .then((a) => null)
      .catch((e) => e);
    if (res) {
      return res;
    }
    await this.db
      .object("users/" + user.key + "/credits")
      .set(Number(user.credits))
      .then((a) => null)
      .catch((e) => e);
    return res;
  }

  getArtists() {
    console.log("getting artists");
    return this.db.list("tempCatalogue").snapshotChanges();
  }

  async getArtistsSync() {
    return await this.db
      .list("tempCatalogue")
      .snapshotChanges()
      .pipe(
        map((a) =>
          a.map((b) => {
            const data: any = b.payload.val();

            const key = b.payload.key;
            return { key, ...data };
          })
        ),
        take(1)
      )
      .toPromise();
  }

  async getStatistics(bar) {
    return await this.db
      .list("bars/" + bar + "/betterstatistics")
      .snapshotChanges()
      .pipe(
        map((a) =>
          a.map((b) => {
            const data: any = b.payload.val();
            const key = b.payload.key;
            return { key, sung: data };
          })
        ),
        take(1)
      )
      .toPromise();
  }

  async editArtist(uid, name) {
    for (var a of this.catalogue) {
      if (a.key === uid) {
        a.name = name;
        break;
      }
    }

    this._search$.next();
    return await this.db.object("/tempCatalogue/" + uid + "/name").set(name);
  }

  async removeArtist(uid) {
    console.log("removing:", uid);
    if (!uid) {
      throw new Error("no id!");
    }
    this.loading.nextEmit("on");
    try {
      this.catalogue = this.catalogue.filter((a) => a.key != uid);
      var aw = await this.db.object("/tempCatalogue/" + uid).remove();
      this.loading.nextEmit("");
      this._search$.next();
      return aw;
    } catch (e) {
      this.loading.nextEmit("");
      return e;
    }
  }

  async addArtist(artist: string) {
    const art = new Artist();
    art.name = artist;
    if (
      this.catalogue.filter((a) => a.name.toLowerCase() == artist.toLowerCase())
        .length
    ) {
      console.log("artist already exists");
      throw new Error("artist already exists!");
    }
    this.loading.nextEmit("on");
    try {
      const res = await this.db.list("/tempCatalogue/").push(art);
      console.log(res, res.key);
      if (res.key) {
        await this.db.object("/tempCatalogue/" + res.key + "/key").set(res.key);
        art.key = res.key;
        this.catalogue.push(art);
        this.loading.nextEmit("");
        this._search$.next();
      }

      return;
    } catch (e) {
      this.loading.nextEmit("");
      return e;
    }
  }

  async markForDelete(song: Song) {
    const key = this.getArtistKey(song.key);
    return await this.db
      .object("/tempCatalogue/" + key[0] + "/songs/" + key[1] + "/toDelete")
      .set(true);
  }

  async addSong(song: Song) {
    song.review();
    song.editedBy = await this.user
      .pipe(take(1))
      .toPromise()
      .then((a) => a.uid)
      .catch((e) => "na");

    const key = this.getArtistKey(song.key);
    for (var a of this.catalogue) {
      if (a.key == key[0]) {
        var found = false;
        a.songs = a.songs.map((so) => {
          if (so.key == song.key) {
            so = song;
            found = true;
          }
          return so;
        });
        if (!found) {
          a.songs.push(song);
        }
        break;
      }
    }
    const res = await this.db
      .object("/tempCatalogue/" + key[0] + "/songs/" + key[1])
      .set(song);

    this._search$.next();
    return res;
  }

  async removeSong(song: Song) {
    this.loading.nextEmit("on");
    const key = this.getArtistKey(song.key);
    this.catalogue.forEach((a) => {
      if (a.key === key[0]) {
        a.songs = a.songs.filter((a) => a.key != song.key);
      }
      return a;
    });
    if (!key[0] || !key[1]) {
      this.loading.nextEmit("");
      throw new Error("No Key!");
    }
    const res = await this.db
      .object("/tempCatalogue/" + key[0] + "/songs/" + key[1])
      .remove();

    this._search$.next();
    this.loading.nextEmit("");
    return res;
  }

  async updateSongPhoto(song: Song, photo: File) {
    console.log("uploading photo");
    return new Promise((res, rej) => {
      try {
        const path = "songs/" + song.key;
        const ref = this.storage.ref(path);
        const uploadTask = this.storage.upload(path, photo);

        uploadTask.percentageChanges().subscribe((num) => {
          console.log(num);
          this.loading.nextEmit("on", num);
          if (num >= 100) {
            this.loading.nextEmit("");
            return res(num);
          }
        });
      } catch (e) {
        return rej(e);
      }
    });
  }

  async getPhoto(songKey) {
    return this.storage.ref("songs/" + songKey).getDownloadURL();
  }

  songReview(review) {
    console.log(review);
    let key = this.getArtistKey(review.new.key);
    console.log(key);
    if (!key[0] || !key[1]) {
      console.log("naayyy");
      return;
    }

    this.db
      .object("/tempCatalogue/" + key[0] + "/songs/" + key[1])
      .set(review.new);
    this.db
      .object("/tempCatalogue/" + key[0] + "/songs/" + key[1] + "/reviewed")
      .set("reviewed");
  }

  getArtistKey(key) {
    let str = "";
    let count = 0;
    let start = key;
    while (
      count < 100 &&
      start.charAt(start.length - 1) !== "_" &&
      start.charAt(start.length - 1) !== "-"
    ) {
      count++;
      str = start.charAt(start.length - 1) + str;
      start = start.slice(0, start.length - 1);
    }
    start = start.slice(0, start.length - 1);

    return [start, str];
  }

  removeReview(review) {
    let key = this.getArtistKey(review.new.key);
    this.db
      .object("/tempCatalogue/" + key[0] + "/songs/" + key[1] + "/reviewed")
      .remove();
    this.db.list("/admin/songInformationReview/" + review.new.key).remove();
  }

  approveReview(review) {
    console.log("approving", review);

    let splits = this.getArtistKey(review.new.key);
    review.new.reviewed = "reviewed";

    console.log("updating database", splits, review.new);
    this.db
      .list("/tempCatalogue/" + splits[0] + "/songs/")
      .set(splits[1], review.new);
    console.log("Removing remivew", review);
    this.db.list("/admin/songInformationReview/" + review.new.key).remove();
  }

  getSongReview() {
    return this.db.list("/admin/songInformationReview").valueChanges();
  }

  async getSongPhoto(key, index) {
    console.log(key, index);
    return "";
  }

  addPictureFix(uid) {
    this.db
      .object("/admin/staff/" + uid + "/pictureCorrections")
      .query.ref.transaction((count) => (count ? ++count : 1));
  }

  addSongFix(uid) {
    this.db
      .object("/admin/staff/" + uid + "/songCorrections")
      .query.ref.transaction((count) => (count ? ++count : 1));
  }

  getDate() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    return dd + mm + yyyy;
  }

  //Artist Service
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _countries$ = new BehaviorSubject<Artist[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: "",
    sortColumn: "",
    sortDirection: "",
    reviewedCheck: false,
    duplicates: false,
    badtimes: false,
  };

  get countries$() {
    return this._countries$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get reviewedCheck() {
    return this._state.reviewedCheck;
  }
  get duplicates() {
    return this._state.duplicates;
  }

  get badtimes() {
    return this._state.badtimes;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set reviewedCheck(reviewedCheck: boolean) {
    this._set({ reviewedCheck });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection) {
    this._set({ sortDirection });
  }
  set duplicates(duplicates) {
    this._set({ duplicates });
  }

  set badtimes(badtimes) {
    this._set({ badtimes });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const {
      sortColumn,
      sortDirection,
      pageSize,
      page,
      searchTerm,
      reviewedCheck,
      duplicates,
    } = this._state;

    // 1. sort
    let countries = sort(this.catalogue, sortColumn, sortDirection);
    console.log(countries);
    // 2. filter
    const temp = cloneDeep(countries);
    countries = countries.filter((country) =>
      matches(
        temp,
        country,
        searchTerm,
        reviewedCheck,
        this.pipe,
        duplicates,
        this.badtimes
      )
    );
    const total = countries.length;

    // 3. paginate
    countries = countries.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
    return of({ countries, total });
  }

  //, ref => ref.child('blacklisted').equalTo('false')
  getSongRequests() {
    return this.db
      .list("songRequests")
      .snapshotChanges()
      .pipe(
        map((a) =>
          a.map((b) => {
            const data: any = b.payload.val();
            const ref = b.payload.key;
            return { ref, ...data };
          })
        ),
        take(1)
      )
      .toPromise();
  }

  async removeSongRequest(ref: string, request: any) {
    return await this.db
      .object("songRequests/" + ref + "/blacklisted")
      .set(request.blacklisted);
  }

  async markWished(ref, request) {
    return await this.db
      .object("songRequests/" + ref + "/wished")
      .set(request.wished);
  }

  async updateSongRequest(ref, song) {
    return await this.db.object("songRequests/" + ref).set(song);
  }

  async markDutch(ref, req) {
    return await this.db
      .object("songRequests/" + ref + "/dutch")
      .set(req.dutch);
  }

  async getEquationModel(bar: string): Promise<EquationModel> {
    return await this.db
      .object("liveCreditPrices/bars/" + bar + "/model")
      .valueChanges()
      .pipe(
        take(1),
        map((a) => {
          return Object.assign(new EquationModel(), a);
        })
      )
      .toPromise();
  }

  async setEquationModel(bar: string, model: EquationModel) {
    return await this.db
      .object("liveCreditPrices/bars/" + bar + "/model")
      .set(model);
  }

  async liveCreditPrices(bar: string): Promise<CurrentPrice> {
    return await this.db
      .object("liveCreditPrices/bars/" + bar)
      .valueChanges()
      .pipe(
        take(1),
        map((a) => {
          return Object.assign(new CurrentPrice(), a);
        })
      )
      .toPromise();
  }

  async liveCreditStatistics(
    bar: string,
    startDate: number,
    endDate: number
  ): Promise<PriceStatistics[]> {
    console.log("getting statistics", bar, startDate, endDate);
    return await this.db
      .list("liveCreditStatistics/bars/" + bar, (query) =>
        query
          .orderByKey()
          .startAt(startDate + "")
          .endAt(endDate + "")
      )
      .valueChanges()
      .pipe(
        take(1),
        map((a) => {
          return a.map((b) => {
            const data: any = b;
            return Object.assign(new PriceStatistics(), data);
          });
        })
      )
      .toPromise();
  }

  followLiveCreditStatistics(
    bar: string,
    startDate: number,
    endDate: number
  ): Observable<PriceStatistics[]> {
    console.log(
      "getting statistics",
      bar,
      new Date(startDate),
      new Date(endDate)
    );
    return this.db
      .list("liveCreditStatistics/bars/" + bar, (query) =>
        query
          .orderByKey()
          .startAt(startDate + "")
          .endAt(endDate + "")
      )
      .valueChanges()
      .pipe(
        map((a) => {
          return a.map((b) => {
            const data: any = b;
            return Object.assign(new PriceStatistics(), data);
          });
        })
      );
  }

  async getUserFlags(): Promise<UserFlag[]> {
    return await this.db
      .list("flags")
      .snapshotChanges()
      .pipe(
        take(1),
        map((a) =>
          a.map((b) => {
            const data: any = b.payload.val();
            const key = b.payload.key;
            const flag = Object.assign(new UserFlag(), data);
            flag.key = key;
            return flag;
          })
        )
      )
      .toPromise();
  }

  async getUsersFromList(list: string[]) {
    //remove duplicates
    list = list.filter((a, index) => list.indexOf(a) === index);
    //remove null or undefined
    list = list.filter((a) => a);

    var promises = [];
    for (var a of list) {
      promises.push(this.getUserAsync(a));
    }

    return await Promise.all(promises);
  }
}
