<div class="staff-info-title">
  <div class="action-side"></div>
  <h3 style="margin: 5px 0px 2px 0px">{{ stock.name | capitalize }}</h3>
  <button class="action-side close-button red" (click)="modal.dismiss()">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="actions actions-header">
  <button
    class="reactive-button-title"
    (click)="tab = 0"
    [ngClass]="tab == 0 ? 'green' : ''"
  >
    General
  </button>
  <button
    class="reactive-button-title"
    (click)="tab = 1"
    [ngClass]="tab == 1 ? 'green' : ''"
  >
    Bar
  </button>
  <button
    class="reactive-button-title"
    (click)="tab = 2"
    [ngClass]="tab == 2 ? 'green' : ''"
  >
    Invoice
  </button>
</div>

<ng-container *ngIf="tab == 0">
  <span>Id: {{ editingStock.id }}</span>
  <br />

  <app-input
    name="Stock Item Name:"
    type="text"
    placeholder="Stock Name"
    [model]="editingStock.name"
    (changed)="editingStock.name = $event"
  ></app-input>
  <app-input
    name="Stock Item Category:"
    type="select"
    placeholder="Category"
    listHideAll="true"
    [model]="editingStock.type"
    [list]="categories"
    (changed)="editingStock.type = $event"
  ></app-input>
  <br />
  <div *ngFor="let bar of bars">
    <div>{{ bar }}</div>
    <app-input
      [name]="'Choose drink for: ' + bar"
      type="select"
      listHideAll="true"
      [model]="editingStock.bars[bar]?.name"
      (changed)="barChanged(bar, $event)"
      [list]="barStockNameStrings.get(bar)"
    ></app-input>
  </div>
</ng-container>

<ng-container *ngIf="tab == 1">
  <a
    [href]="'https://henksmit.nl/zoeken/?query=' + editingStock.name"
    target="_blank"
    >Search Henk</a
  >
  <a
    [href]="'https://www.excellentdrinks.nl/zoeken/?q=' + editingStock.name"
    target="_blank"
    >Search Excellent</a
  >
  <app-input
    name="Supplier:"
    type="select"
    listHideAll="true"
    placeholder="henksmit, excellentdrinks"
    [model]="editingStock.supplier"
    (changed)="editingStock.supplier = $event"
    [list]="suppliers"
  ></app-input>
  <app-input
    name="Supplier URL:"
    type="string"
    placeholder="https://henksmit.nl/artikel/fles-ketel-one-vodka-1ltr/"
    [model]="editingStock.url"
    (changed)="editingStock.url = $event"
  ></app-input>

  <div class="app-divider"></div>
  <app-input
    name="Price Per Unit (PPU):"
    type="number"
    placeholder="Price for the bottle/crate/key"
    [model]="editingStock.ppu"
    (changed)="editingStock.ppu = $event"
  ></app-input>
  <app-input
    name="Resale Percentage (RP) (0-100):"
    max="100"
    min="0"
    step="1"
    type="number"
    placeholder="Percentage added to PPU to pake PPRU"
    [model]="editingStock.percentage"
    (changed)="percentageChanged($event)"
  ></app-input>
  <app-input
    name="Price Per Resale Unit (PPRU):"
    type="number"
    placeholder="Price for the bottle/crate/key selling to bar!"
    [model]="editingStock.resalePerUnit"
    (changed)="
      editingStock.resalePerUnit = $event;
      unitPriceChanged($event, editingStock.units)
    "
  ></app-input>
  <app-input
    name="Singles Per Unit (SPU)"
    type="number"
    placeholder="How many singles Per Unit"
    [model]="editingStock.units"
    (changed)="
      editingStock.units = $event;
      unitPriceChanged(editingStock.resalePerUnit, $event)
    "
  ></app-input>
  <app-input
    name="Price Per Single (PPS):"
    type="number"
    placeholder="Price for a single drink"
    [model]="editingStock.singlePrice"
    [editable]="false"
  ></app-input>
</ng-container>

<ng-container *ngIf="tab == 2">
  <app-input
    name="Quantity:"
    type="number"
    placeholder="Quantity of drinks in the HO (individual bottles)"
    [model]="editingStock.quantity"
    (changed)="quantityChanged($event)"
  ></app-input>
  <app-input
    name="Crate Size:"
    type="number"
    placeholder="Size of Crate"
    [model]="editingStock.crateSize"
    (changed)="crateSizeChanged($event)"
  ></app-input>
</ng-container>

<div class="actions actions-header">
  <button class="reactive-button-title orange" (click)="cancel()">
    Cancel
  </button>
  <button class="reactive-button-title green" (click)="saveStock()">
    Save
  </button>
  <button class="reactive-button-title red" (click)="deleteStock()">
    Delete
  </button>
</div>
