export class Click {
  battery: number;
  clickType: string;
  serialNumber: string;
  timestamp: number;
  data: any;

  constructor() {
    this.battery = 0;
    this.clickType = "";
    this.serialNumber = "";
    this.timestamp = 0;
    this.data = null;
  }
}
