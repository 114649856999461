import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  SnapshotAction,
} from "@angular/fire/compat/database";

import { BarInfo } from "../Helpers/functions/BarInfo";
import { firstValueFrom, map } from "rxjs";
import { Click } from "../Classes/click";
import { Device } from "../Classes/device";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: "root",
})
export class SensorService {
  constructor(
    private staffAppBase: AngularFireDatabase,
    public bi: BarInfo,
    private firestore: AngularFirestore
  ) {}

  getDevices() {
    //from firestore
    return firstValueFrom(
      this.firestore
        .collection("devices")
        .snapshotChanges()
        .pipe(
          map((devices) =>
            devices.map((device) =>
              Object.assign(new Device(), device.payload.doc.data())
            )
          )
        )
    );
  }

  createDevice(device: Device) {
    //to firestore
    if (device.serialNumber == null) {
      throw new Error("Serial number is required");
    }
    return this.firestore
      .collection("devices/" + device.serialNumber)
      .add(device);
  }

  saveDevice(deviceD: Device) {
    //to firestore
    const device = { ...deviceD };
    console.log(device);
    return this.firestore
      .collection("devices")
      .doc(device.serialNumber)
      .set(device);
  }

  deleteDevice(device: Device) {
    //to firestore
    return this.firestore
      .collection("devices")
      .doc(device.serialNumber)
      .delete();
  }

  getClicks(from: Date, to: Date, deviceId) {
    //from database
    return firstValueFrom(
      this.staffAppBase
        .list(`devices/clicks/` + deviceId, (q) => {
          return q
            .orderByChild("timestamp")
            .startAt(from.getTime())
            .endAt(to.getTime());
        })
        .valueChanges()
        .pipe(
          map((clicks) =>
            clicks.map((click) => Object.assign(new Click(), click))
          )
        )
    );
  }
}
