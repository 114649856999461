import { Component, Input, OnInit } from "@angular/core";
import { HOStock } from "src/app/Components/Bestellingen/inkoop-kosten/inkoop-kosten.component";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { AlertsService } from "../alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-hqstock",
  templateUrl: "./hqstock.component.html",
  styleUrls: ["./hqstock.component.css"],
})
export class HQStockComponent implements OnInit {
  @Input() stock: HOStock;
  @Input() barStock: Map<string, any[]>;
  barStockNameStrings: Map<string, string[]> = new Map<string, string[]>();
  bars: string[] = [];
  categories = ["crates", "glasses", "wine", "kegs", "liqueur", "other"];
  suppliers = ["excellentdrinks", "henksmit"];
  editingStock: HOStock;
  tab: number = 0;
  constructor(
    public db: StaffAppDatabase,
    public alert: AlertsService,
    public loading: LoadingService,
    public modal: NgbActiveModal,
    public auth: Auth
  ) {}

  ngOnInit(): void {
    this.editingStock = Object.assign({}, this.stock);
    console.log(this.barStock);
    this.bars = Array.from(this.barStock.keys());

    console.log(this.barStock["Amsterdam"]);

    this.bars.forEach((value) => {
      this.barStockNameStrings.set(
        value,
        this.barStock
          .get(value)
          .map((value) => {
            return value.name;
          })
          .sort((a, b) => {
            return (
              this.levensteinDistance(a, this.stock.name) -
              this.levensteinDistance(b, this.stock.name)
            );
          })
      );
    });

    console.log(this.barStockNameStrings);
  }

  levensteinDistance(a: string, b: string) {
    if (a.length == 0) return b.length;
    if (b.length == 0) return a.length;

    var matrix = [];

    // increment along the first column of each row
    var i;
    for (i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    // increment each column in the first row
    var j;
    for (j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) == a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            Math.min(
              matrix[i][j - 1] + 1, // insertion
              matrix[i - 1][j] + 1
            )
          ); // deletion
        }
      }
    }

    return matrix[b.length][a.length];
  }

  barChanged(bar, event) {
    console.log(bar);
    console.log(event);

    if (
      event == null ||
      event == "" ||
      event == undefined ||
      event == "undefined"
    ) {
      //remove bar
      delete this.editingStock.bars[bar];
      return;
    }
    var drink = this.barStock.get(bar).find((value) => {
      return value.name === event;
    });

    this.editingStock.bars[bar] = {
      name: event,
      id: drink.serverId,
      type: drink.category,
    };
  }

  percentageChanged(event) {
    if (!event) {
      this.alert.nextEmit(AlertsService.error("Percentage is required"));
      return;
    }

    if (isNaN(event)) {
      this.alert.nextEmit(AlertsService.error("Percentage must be a number"));
      return;
    }

    if (event < 0 || event > 100) {
      this.alert.nextEmit(
        AlertsService.error("Percentage must be between 0 and 100")
      );
      return;
    }

    this.editingStock.percentage = event;
    console.log(event);
    this.editingStock.resalePerUnit =
      Math.round(this.editingStock.ppu * (1 + event / 100) * 100) / 100;
    this.unitPriceChanged(
      this.editingStock.resalePerUnit,
      this.editingStock.units
    );
  }

  async quantityChanged(event) {
    this.editingStock.quantity = Number(event);
  }

  async crateSizeChanged(event) {
    this.editingStock.crateSize = Number(event);
  }

  async saveStock() {
    if (!this.editingStock.name) {
      this.alert.nextEmit(AlertsService.error("Name is required"));
      return;
    }

    if (!this.editingStock.type) {
      this.alert.nextEmit(AlertsService.error("Type is required"));
      return;
    }

    if (!window.confirm("Are you sure you want to save this stock?")) {
      return;
    }

    this.loading.show();

    var error = await this.db.saveHOStockItem(this.editingStock);

    if (error) {
      this.alert.nextEmit(
        AlertsService.error("Error saving stock", error.message)
      );
      this.loading.hide();
      return;
    }

    this.modal.close();

    console.log("saved", this.editingStock);
    console.log(this.auth, this.auth.accessUser);
    try {
      await this.db.changedRecord(
        this.db.bar,
        this.auth.accessUser.uid,
        this.editingStock,
        "HQStock",
        this.editingStock.UID,
        "editing",
        "theendadmin.nl/instellingen/inkoop-kosten",
        "Stock Updated: ",
        this.db.getDifferences(this.stock, this.editingStock)
      );
    } catch (e) {
      console.error(e);
    }

    this.alert.nextEmit(AlertsService.success("Stock saved"));

    this.loading.hide();
  }

  unitPriceChanged(resalePerUnit, units) {
    if (!resalePerUnit || !units) {
      return;
    }
    if (!resalePerUnit || isNaN(resalePerUnit)) {
      this.alert.nextEmit(AlertsService.error("Resale per unit is required"));
      return;
    }

    if (!units || isNaN(units)) {
      this.alert.nextEmit(AlertsService.error("Units is required"));
      return;
    }

    this.editingStock.singlePrice =
      Math.round((resalePerUnit / units) * 100) / 100;
  }

  async deleteStock() {
    if (!window.confirm("Are you sure you want to delete this stock?")) {
      return;
    }

    this.loading.show();

    var error = await this.db.deleteHOStock(this.editingStock);

    if (error) {
      this.alert.nextEmit(
        AlertsService.error("Error deleting stock", error.message)
      );
      this.loading.hide();
      return;
    }

    this.modal.close();

    this.alert.nextEmit(AlertsService.success("Stock deleted"));

    this.loading.hide();
  }

  async cancel() {
    this.modal.dismiss();
  }
}
