export class Device {
  alerts: any[] = [];
  bar: string = "";
  batteryPercentage: number = 0;
  clicks: number = 0;
  description: string = "";
  name: string = "";
  serialNumber: string = "";
  deviceType: string = "";
  selected: boolean = false;
}
