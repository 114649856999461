import { Component } from '@angular/core';

@Component({
  selector: 'app-drink-price-scraper',
  templateUrl: './drink-price-scraper.component.html',
  styleUrls: ['./drink-price-scraper.component.scss']
})
export class DrinkPriceScraperComponent {

}
