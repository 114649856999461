<div class="page-chooser">
  <button
    class="page-chooser-button"
    [class.active]="page == 0"
    (click)="pageClicked(0)"
  >
    Devices
  </button>
  <button
    class="page-chooser-button"
    [class.active]="page == 1"
    (click)="pageClicked(1)"
  >
    History
  </button>
  <button
    class="page-chooser-button"
    [class.active]="page == 2"
    (click)="pageClicked(2)"
  >
    Groups
  </button>
</div>

<div class="pages">
  <div
    class="page-container"
    style="justify-content: flex-start"
    *ngIf="page == 0"
  >
    <button *ngIf="auth.accessUser.access > 6" (click)="createItem()">
      Create Device
    </button>
    <div class="my-table-header">
      <div class="stock-item-sml">
        <app-id-circle [id]="1"></app-id-circle>
      </div>
      <div class="stock-item-large" (click)="setSortBy('name')">
        <div>Name</div>
        <i
          [style.display]="sortBy.key == 'name' ? 'flex' : 'none'"
          [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
        ></i>
      </div>
      <div class="stock-item-imp" (click)="setSortBy('serialNumber')">
        <div>ID</div>
        <i
          [style.display]="sortBy.key == 'serialNumber' ? 'flex' : 'none'"
          [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
        ></i>
      </div>
      <div class="stock-item-imp" (click)="setSortBy('bar')">
        <div>Bar</div>
        <i
          [style.display]="sortBy.key == 'bar' ? 'flex' : 'none'"
          [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
        ></i>
      </div>
      <div class="stock-item-imp" (click)="setSortBy('deviceType')">
        <div>Type</div>
        <i
          [style.display]="sortBy.key == 'deviceType' ? 'flex' : 'none'"
          [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
        ></i>
      </div>
    </div>

    <div class="my-table">
      <div
        *ngFor="let s of devices"
        class="my-table-item"
        (click)="openItem(s)"
      >
        <div class="stock-item-sml">
          <app-id-circle [id]="s.serialNumber"></app-id-circle>
        </div>
        <div class="my-table-item-large">
          {{ s.name }}
        </div>
        <div class="my-table-item-imp">
          {{ s.serialNumber }}
        </div>
        <div class="my-table-item-imp">
          {{ s.bar }}
        </div>
        <div class="my-table-item-imp">
          {{ s.deviceType }}
        </div>
      </div>
    </div>
  </div>

  <div class="page-container rows" *ngIf="page == 1">
    <div class="outer-row">
      <div class="row-item" style="background-color: transparent">
        <div
          *ngFor="let s of devices"
          class="my-table-item"
          [class.selected]="s.selected"
          (click)="selectDevice(s)"
        >
          <div class="stock-item-sml">
            <app-id-circle [id]="s.serialNumber"></app-id-circle>
          </div>
          <div class="my-table-item-large">
            {{ s.name }}
          </div>
        </div>
      </div>

      <div class="row-item">
        <h3>Battery (%)</h3>
        <canvas
          baseChart
          #baseChart="base-chart"
          [type]="'line'"
          [data]="batteryChartData"
          [options]="batteryChartOptions"
          style="width: 400px; height: 400px"
        >
        </canvas>
      </div>
      <div class="row-item">
        <h3>Temperature (°C)</h3>
        <canvas
          baseChart
          width="5"
          height="4"
          #tempChart="base-chart"
          [type]="'line'"
          [data]="temperatureChartData"
          [options]="temperatureChartOptions"
          style="width: 400px; height: 400px"
        >
        </canvas>
      </div>
    </div>

    <div class="outer-row">
      <div class="row-item">
        <h3>Humidity (%)</h3>
        <canvas
          baseChart
          width="5"
          height="4"
          #humidityChart="base-chart"
          [type]="'line'"
          [data]="humidityChartData"
          [options]="humidityChartOptions"
          style="width: 400px; height: 400px"
        ></canvas>
      </div>
      <div class="row-item">
        <h3>Illuminance (Lux)</h3>
        <canvas
          baseChart
          width="5"
          height="4"
          #pressureChart="base-chart"
          [type]="'line'"
          [data]="luminanceChartData"
          [options]="luminanceChartOptions"
          style="width: 400px; height: 400px"
        >
        </canvas>
      </div>
      <div class="row-item">
        <h3 *ngIf="!isButton">Door</h3>
        <h3 *ngIf="isButton">Clicks</h3>
        <canvas
          *ngIf="!isButton"
          baseChart
          width="5"
          height="4"
          #luminanceChart="base-chart"
          [type]="'bar'"
          [data]="doorChartData"
          [options]="doorChartOptions"
          style="width: 400px; height: 400px"
        ></canvas>
        <canvas
          *ngIf="isButton"
          baseChart
          width="5"
          height="4"
          style="width: 400px; height: 400px"
          #doorChart="base-chart"
          [type]="'bar'"
          [data]="clickChartData"
          [options]="clickChartOptions"
        ></canvas>
      </div>
    </div>
  </div>
  <div class="page-container" *ngIf="page == 2"></div>
</div>
