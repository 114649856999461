import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "statusIcon",
})
export class StatusIconPipe implements PipeTransform {
  transform(value: number): unknown {
    if (value == 0) {
      return "fas fa-times";
    }

    if (value == 1) {
      return "fas fa-exclamation";
    }

    if (value == 2) {
      return "fas fa-check";
    }
  }
}
