import { Injectable, Inject } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { Auth } from "src/app/auth/auth";
import { Firebase } from "src/app/Services/firebase";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Injectable({
  providedIn: "root",
})
export class BarInfo {
  onlyDuncan: Map<string, boolean> = new Map<string, boolean>();
  barSubject = new BehaviorSubject<any>(null);
  barObserver = this.barSubject.asObservable();

  barsSubject = new BehaviorSubject<any>([]);
  barsObserver = this.barsSubject.asObservable();

  bars = [];
  filteredBars: String[] = [];
  bar = "";
  companyInfo: any = {};
  barOptions: string[] = ["All"];
  constructor(private staffDatabase: StaffAppDatabase, private router: Router) {
    this.onlyDuncan.set("Pertempto", true);
    this.onlyDuncan.set("Old Staff", true);

    if (localStorage.getItem("bar")) {
      this.bar = localStorage.getItem("bar");
      this.barSubject.next(localStorage.getItem("bar"));
    }
  }

  hasBar() {
    return localStorage.getItem("bar");
  }

  setBar(value) {
    this.bar = value;
    localStorage.setItem("bar", value);
    this.barSubject.next(localStorage.getItem("bar"));
    //this.router.navigate(['']);
  }

  remove() {
    localStorage.removeItem("bar");
  }

  getCompanyInfo(bar: string) {
    return this.staffDatabase.getCompanyInfo(bar);
  }

  getBars() {
    this.staffDatabase.barsObserver.subscribe(async (values) => {
      this.bars = [];
      this.filteredBars = [];
      if (values) {
        this.barsSubject.next(values);
        this.bars = values;

        this.filteredBars = this.bars.filter(
          (a) => a != "Old Staff" && a != "Pertempto"
        );

        this.barOptions = ["All", ...(values as any[])];
      }

      for (let i = 0; i < this.bars.length; i++) {
        this.getCompanyInfo(this.bars[i])
          .then((a) => {
            this.companyInfo[this.bars[i]] = a;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    });
    this.staffDatabase.getBars();
  }

  private errorSubject = new BehaviorSubject<any>(null);
  errorObserver = this.errorSubject.asObservable();

  nextError(error) {
    this.errorSubject.next(error);
  }
}
