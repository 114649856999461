import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { BarInfo } from "../Helpers/functions/BarInfo";
import { firstValueFrom, map, take } from "rxjs";
import { ShiftReport } from "../Components/Controle/shift-check/shift-check.component";

@Injectable({
  providedIn: "root",
})
export class CountingServiceService {
  constructor(private staffAppBase: AngularFireDatabase, public bi: BarInfo) {}

  async getStaff() {
    if (!this.bi.bar) {
      throw new Error("No bar found");
    }

    const ref = this.staffAppBase.list(`staff`, (ref) =>
      ref.orderByChild("bar").equalTo(this.bi.bar)
    );

    return ref.valueChanges();
  }

  getStock(month, year, barName) {
    return firstValueFrom(
      this.staffAppBase
        .list("bars/" + barName + "/records/stock/" + year + "/" + month)
        .snapshotChanges()
        .pipe(
          map((daysRef) => {
            var days = [];
            daysRef.forEach((dayRef) => {
              var day = dayRef.payload.val();
              var data: any = Object.assign(
                { dayNumber: dayRef.key, month: month, year: year },
                day
              );
              days.push(data);
            });
            return days;
          })
        )
    );
  }

  getTill(month, year, barName) {
    ////console.log( year, barName);
    return firstValueFrom(
      this.staffAppBase
        .list("bars/" + barName + "/records/till", (ref) =>
          ref
            .orderByKey()
            .startAt(year + "-" + month)
            .endAt(year + "-" + month + "~")
        )
        .snapshotChanges()
        .pipe(
          map((values) => {
            const list = values.map((d: any) => {
              let temp: any;
              temp = d.payload.val();
              temp.dayNumber = d.key.split("-")[2];
              ////console.log(temp);
              if (temp.end) {
                temp.end.realCash = Number(temp.end.realCash);
                temp.end.realPin = Number(temp.end.realPin);
              }

              if (temp.start) {
                temp.start.original = Number(temp.start.original);
                temp.start.updated = Number(temp.start.updated);
                temp.start.difference = Number(temp.start.difference);
              }
              ////console.log(temp);
              temp.day = d.key;
              return temp;
            });
            return list;
          })
        )
    );
  }

  getShiftCheck(month, year, barName) {
    return this.staffAppBase
      .list("davesMomsReports/" + barName + "/" + year + "/" + month)
      .snapshotChanges()
      .pipe(
        take(1),
        map((daysRef) => {
          var days = [];
          daysRef.forEach((dayRef) => {
            var day = dayRef.payload.val();
            var data: any = Object.assign(
              { dayNumber: dayRef.key, month: month, year: year },
              day as ShiftReport
            );
            days.push(data);
          });
          return days;
        })
      )
      .toPromise();
  }
}
