import { Injectable, Inject } from "@angular/core";
import {
  AngularFireDatabase,
  SnapshotAction,
} from "@angular/fire/compat/database";
import { BehaviorSubject, firstValueFrom, Observable, Subject } from "rxjs";

import { query } from "@angular/animations";

import { debounceTime, filter, map, take, takeUntil } from "rxjs/operators";
import { off } from "process";

import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { Giftable } from "../Classes/giftable";
import { OneOffExpense } from "../Classes/one-off-expense";
import { RecurringExpense } from "../Classes/recurring-expense";
import { Survey } from "../Components/Instellingen/reviews/Survey";
import { ScheduledTask } from "../Classes/scheduled-task";
import { InvoiceSave } from "../Classes/invoice-save";
import { InvoiceCompany } from "../Classes/invoice-company";
import { da } from "date-fns/locale";
import { GenericMessage } from "../Classes/generic-message";
import { reverse } from "lodash";
import { GenericMessageGroup } from "../Classes/generic-message-group";
import { LoadingService } from "./loading.service";
import {
  DatabaseQuery,
  QueryFn,
} from "@angular/fire/compat/database/interfaces";
import { CalendarAPIConfig } from "../Classes/calendar-apiconfig";
import { Table } from "../Classes/table";
import { HOStock } from "../Components/Bestellingen/inkoop-kosten/inkoop-kosten.component";
import { Shirts } from "../Classes/shirts";
import { Tip } from "../Classes/tip";
import { DatePipe } from "@angular/common";
import { ShiftReport } from "../Components/Controle/shift-check/shift-check.component";
import { NDHOStock } from "../Components/Instellingen/ndostock-hq/ndostock-hq.component";
import { TipType } from "../Classes/tip-type";
import { Auth } from "../auth/auth";
import { InvoiceData } from "../Components/Instellingen/invoice-splitter/invoice-splitter.component";
import { CapitalizePipe } from "../Pipes/capitalize.pipe";
import { Bar } from "../Classes/bar";
import { OutingBudget } from "../Classes/outing-budget";

@Injectable({
  providedIn: "root",
})
export class StaffAppDatabase {
  bar: string = "";

  constructor(
    private staffAppBase: AngularFireDatabase,
    private storage: AngularFireStorage,
    private functions: AngularFireFunctions,
    private loading: LoadingService
  ) {}

  saveMedewerker(person) {
    if (!person.uid) {
      throw new Error("no uid set");
    }
    if (!person.medewerkernumber) {
      throw new Error("no medewerkernumber set");
    }

    if (isNaN(person.medewerkernumber)) {
      throw new Error("medewerkernumber is not a number");
    }
    return this.staffAppBase
      .object("staff/" + person.uid + "/medewerkernumber")
      .set(person.medewerkernumber);
  }

  async testCheckContractsFunction() {
    return await this.functions
      .httpsCallable("runCheckContracts")({})
      .toPromise();
  }

  async getTipTypes() {
    if (!this.bar) {
      throw new Error("no bar set");
    }

    return await firstValueFrom(
      this.staffAppBase
        .list("bars/" + this.bar + "/tipTypes")
        .valueChanges()
        .pipe(
          take(1),
          map((a) => {
            return a.map((b) => {
              const tip = new TipType();
              Object.assign(tip, b);
              return tip;
            });
          })
        )
    );
  }

  async saveTipType(tipType: TipType) {
    if (!this.bar) {
      throw new Error("no bar set");
    }
    if (!tipType.id) {
      throw new Error("no id set");
    }
    return await this.staffAppBase
      .list("bars/" + this.bar + "/tipTypes")
      .set(tipType.id, tipType);
  }
  async getTips(startDate: number, endDate: number): Promise<Tip[]> {
    if (!this.bar) {
      throw new Error("no bar set");
    }
    return await firstValueFrom(
      this.staffAppBase
        .list("bars/" + this.bar + "/tips", (query) =>
          query.orderByChild("dateTime").startAt(startDate).endAt(endDate)
        )
        .valueChanges()
        .pipe(
          take(1),
          map((a) => {
            return a
              .sort((a: Tip, b: Tip) => a.dateTime - b.dateTime)
              .map((a: any) => {
                const tip = new Tip();
                Object.assign(tip, a);
                return tip;
              });
          })
        )
    );
  }

  createId() {
    //random 16 string with only letters
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  }

  getTables(date: Date): Promise<Table[]> {
    return this.staffAppBase
      .list(
        "bars/" +
          this.bar +
          "/records/till/" +
          date.getFullYear() +
          "-" +
          date.getMonth() +
          "-" +
          date.getDate() +
          "/tables"
      )
      .valueChanges()
      .pipe(
        take(1),
        map((a) => {
          //console.log(a);
          return a as Table[];
        })
      )
      .toPromise();
  }

  getLiveShotsFunction() {
    return this.staffAppBase
      .object("functions/getshots")
      .valueChanges()
      .pipe(map((a) => a as any));
  }

  saveGetLiveShots(getLive: boolean) {
    this.staffAppBase.object("functions/getshots").set(getLive);
  }

  getLiveShots(bar: string, day: Date) {
    //console.log(day);
    return this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/records/liveshots/" +
          day.getFullYear() +
          "-" +
          day.getMonth() +
          "-" +
          day.getDate() +
          "/shots"
      )
      .valueChanges()
      .pipe(
        take(1),
        map((a) => {
          return a.reduce((pre: number, curr: number) => {
            return pre + curr;
          }, 0);
        })
      );
  }

  getCompetitionBeers(bar: string, day: Date) {
    return this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/records/till/" +
          day.getFullYear() +
          "-" +
          day.getMonth() +
          "-" +
          day.getDate() +
          "/beers"
      )
      .snapshotChanges()
      .pipe(
        take(1),
        map((a) => {
          var keymap = {};
          a.forEach((snap) => {
            //console.log(snap.key, snap.payload.val());
            //return key and value
            keymap[snap.key] = snap.payload.val();
          });
          return keymap;
        })
      );
  }
  getShots(bar: string, day: Date) {
    return this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/records/till/" +
          day.getFullYear() +
          "-" +
          day.getMonth() +
          "-" +
          day.getDate() +
          "/shots"
      )
      .snapshotChanges()
      .pipe(
        take(1),
        map((a) => {
          var keymap = {};
          a.forEach((snap) => {
            //console.log(snap.key, snap.payload.val());
            //return key and value
            keymap[snap.key] = snap.payload.val();
          });
          return keymap;
        })
      )
      .toPromise();
  }

  getShotCompetitionData(bar: string, day: Date, compName) {
    return this.staffAppBase
      .object("bars/" + bar + "/records/competitions/" + compName)
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async saveShotCompetitionData(bar: string, data: any, compName) {
    return await this.staffAppBase
      .list("bars/" + bar + "/records/competitions/")
      .set(compName, data);
  }

  async updateGenericMessageActive(group: GenericMessageGroup) {
    return await this.staffAppBase
      .object("messages/" + group.key + "/active")
      .set(group.active);
  }

  async getGenericMessage(bar: string, date: Date, page: string) {
    return await firstValueFrom(
      this.staffAppBase
        .object(
          "/messages/" +
            bar +
            "-" +
            date.getFullYear() +
            "-" +
            date.getMonth() +
            "-" +
            date.getDate() +
            "-" +
            page
        )
        .valueChanges()
        .pipe(map((a) => a as GenericMessageGroup))
    );
  }

  async sendGenericMessage(genericMessage: GenericMessage) {
    var existing = await this.getGenericMessage(
      genericMessage.bar,
      new Date(genericMessage.threadDate),
      genericMessage.page
    );
    //console.log(existing);
    const date = new Date(genericMessage.threadDate);
    if (existing) {
      existing.messages.push(genericMessage);
      existing.lastMessage = Date.now();
      existing.lastSender = genericMessage.user.displayName;
    } else {
      existing = new GenericMessageGroup(
        genericMessage.bar +
          "-" +
          date.getFullYear() +
          "-" +
          date.getMonth() +
          "-" +
          date.getDate() +
          "-" +
          genericMessage.page,
        genericMessage.bar,
        Date.now(),
        [genericMessage],
        new Date(genericMessage.threadDate),
        "telling",
        genericMessage.user.displayName
      );
    }
    existing.active = "true";
    return await this.staffAppBase
      .list("/messages/")
      .set(
        genericMessage.bar +
          "-" +
          date.getFullYear() +
          "-" +
          date.getMonth() +
          "-" +
          date.getDate() +
          "-" +
          genericMessage.page,
        existing
      );
  }

  async setWalletBlocked(bar, target, manager, isBlocked, reason: string) {
    var now = Date.now();
    var info = {
      blocked: isBlocked,
      reason: reason,
      manager: manager.uid,
      time: now,
      target: target.uid,
    };
    return await this.staffAppBase
      .object("bars/" + bar + "/records/walletBlock/" + target.uid + "/" + now)
      .set(info);
  }

  async destroyEncryptedVoucher(voucher, staff, time) {
    try {
      return await this.functions
        .httpsCallable("closeDyanmicVoucher")({
          key: voucher.key,
          bar: this.bar,
          staff: staff,
          time: time,
        })
        .toPromise();
    } catch (e) {
      console.error(e);
      return { status: "error", data: e };
    }
  }

  async getEncryptedVoucher(
    voucher,
    isDynamic = false,
    dynamicVoucherTime = Date.now(),
    staff = null
  ) {
    //firebase funciton call to encryptVoucher with key and bar;
    const res = await this.functions
      .httpsCallable("encryptVoucher")({
        key: voucher.key,
        bar: this.bar,
        isDynamic: isDynamic,
        staff: staff,
        dynamicVoucherTime: dynamicVoucherTime,
      })
      .toPromise();
    return res;
  }

  async saveVoucher(voucher: any) {
    if (!voucher.key) {
      voucher.key = await this.staffAppBase
        .list("vouchers/" + this.bar)
        .push({})
        .then((a) => a.key)
        .catch((e) => {
          throw new Error(e);
        });
    }
    return await this.staffAppBase
      .list("vouchers/" + this.bar)
      .set(voucher.key, voucher);
  }

  async getVouchers() {
    return await firstValueFrom(
      this.staffAppBase.list("vouchers/" + this.bar).valueChanges()
    );
  }

  async getVouchersPipe() {
    var date = new Date();
    if (date.getHours() < 6) {
      date.setDate(date.getDate() - 1);
    }

    date.setHours(6);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return this.staffAppBase
      .list("vouchers/" + this.bar, (qr) =>
        qr.orderByChild("date").startAfter(date.getTime())
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async getTillInfo() {
    return await firstValueFrom(
      this.staffAppBase
        .object("bars/" + this.bar + "/tillInformation")
        .valueChanges()
    );
  }

  async saveInvoiceCompany(company: InvoiceCompany) {
    var key: string = "";
    if (!company.id) {
      key = await this.staffAppBase
        .list("invoiceMaker/companies")
        .push({})
        .then((a) => a.key)
        .catch((e) => {
          throw new Error(e);
        });
      company.id = key;
    }
    return await this.staffAppBase
      .list("invoiceMaker/companies/")
      .set(company.id, company);
  }

  async getInvoiceCompanies() {
    return await firstValueFrom(
      this.staffAppBase.list("invoiceMaker/companies").valueChanges()
    );
  }

  async getInvoices(uid): Promise<InvoiceSave[]> {
    return await firstValueFrom(
      this.staffAppBase
        .list("invoiceMaker/invoices/" + uid)
        .valueChanges()
        .pipe(
          take(1),
          map((a: any) => {
            return a.map((b) => {
              const invoice = new InvoiceSave();
              Object.assign(invoice, b);
              return invoice;
            });
          })
        )
    );
  }

  async saveInvoice(uid: string, invoice: InvoiceSave) {
    if (!invoice.key) {
      invoice.key = await this.staffAppBase
        .list("invoiceMaker/invoices/" + uid)
        .push({})
        .then((a) => a.key)
        .catch((e) => {
          throw new Error(e);
        });
    }

    return await this.staffAppBase
      .list("invoiceMaker/invoices/" + uid)
      .set(invoice.key, invoice);
  }

  async deleteInvoice(uid: string, invoiceId: string) {
    if (!invoiceId || !uid) {
      throw new Error("no invoiceId or uid");
    }
    return await this.staffAppBase
      .list("invoiceMaker/invoices/" + uid + "/" + invoiceId)
      .remove()
      .then()
      .catch((e) => e);
  }

  async deleteInvoiceCompany(companyId: string) {
    return await this.staffAppBase
      .list("invoiceMaker/companies/" + companyId)
      .remove();
  }

  async deleteScheduledTask(task: ScheduledTask) {
    return await this.staffAppBase
      .object("scheduledtasks/" + task.key)
      .remove();
  }
  async getScheduledTasks(): Promise<ScheduledTask[]> {
    return await this.staffAppBase
      .list("scheduledtasks")
      .valueChanges()
      .pipe(
        take(1),
        map((a) => {
          return a.map((b: any) => {
            const task = new ScheduledTask();
            Object.assign(task, b);
            // //console.log(task);
            return task;
          });
        })
      )
      .toPromise();
  }

  async setScheduledTask(task: ScheduledTask) {
    return await this.staffAppBase
      .object("scheduledtasks/" + task.key)
      .update(task);
  }

  async newScheduledTask(task: ScheduledTask) {
    const key = await this.staffAppBase.list("scheduledtasks").push(task).key;
    return await this.staffAppBase
      .object("scheduledtasks/" + key)
      .update({ key: key });
  }

  async setPaid(date: Date, bar: string, key: string, paid: number) {
    //console.log(date, key, paid, bar);
    return await this.functions
      .httpsCallable("SetPaid")({
        bar: bar,
        date: date.getTime(),
        user: key,
        paid: paid,
      })
      .toPromise();
  }

  async getStaffOrders(uid, type) {
    return await this.staffAppBase
      .list("stafforders/" + uid, (ref) =>
        ref.orderByChild("type").equalTo(type).limitToLast(15)
      )
      .snapshotChanges()
      .pipe(
        take(1),
        map((a: any) =>
          a.map((b) => {
            const d = { key: b.payload.key, value: b.payload.val() };
            return d;
          })
        )
      )
      .toPromise();
  }

  // Get Bars
  barsSubject = new BehaviorSubject<String[]>(null);
  barsObserver = this.barsSubject.asObservable();

  managerSubject = new BehaviorSubject<String>(null);
  managerObserver = this.managerSubject.asObservable();
  // Get Hours
  hoursSubject = new BehaviorSubject<any>(null);
  hoursObserver = this.hoursSubject.asObservable();
  months = [];

  // -----------------------Staff Info

  staffInfoSubject = new BehaviorSubject<any>(null);
  staffInfoObserver = this.staffInfoSubject.asObservable();

  // ------------------------ND Order
  ndItemsSubject = new BehaviorSubject<any>(null);
  ndObserver = this.ndItemsSubject.asObservable();

  ndOrdersSubject = new BehaviorSubject<any>(null);
  ndOrdersObserver = this.ndOrdersSubject.asObservable();

  // ------------------ Get Stock Counted

  countedStockSubject = new BehaviorSubject<any>(null);
  countedStockObserver = this.countedStockSubject.asObservable();

  countedNDStockSubject = new BehaviorSubject<any>(null);
  countedNDStockObserver = this.countedNDStockSubject.asObservable();

  // --------------- Till

  tillCountedSubject = new BehaviorSubject<any[]>(null);
  tillCountedObserver = this.tillCountedSubject.asObservable();

  // ---------------- Hours
  staffWorkedSubject = new BehaviorSubject<any[]>(null);
  staffWorkedObserver = this.staffWorkedSubject.asObservable();

  // ------------------ Messages
  messagesSubject = new BehaviorSubject<any[]>(null);
  messagesObserver = this.messagesSubject.asObservable();

  // ------------------------ Tasks
  private tasksSubject = new BehaviorSubject<any[]>(null);
  tasksObserver = this.tasksSubject.asObservable();

  // ------------------ drink orders

  ordersSubject = new BehaviorSubject<any[]>(null);
  ordersObserver = this.ordersSubject.asObservable();

  ordersSubjectItems = new BehaviorSubject<any[]>(null);
  ordersObserverItems = this.ordersSubjectItems.asObservable();

  async getDocuments(uid: string, path: string) {
    return await firstValueFrom(
      this.storage.ref("documents/" + uid + "/" + path).list()
    );
  }

  async getCustomerDrinks(uid: string) {
    return await firstValueFrom(
      this.staffAppBase
        .list("customers/" + uid)
        .valueChanges()
        .pipe(take(1))
    );
  }

  async getDownloadURL(path) {
    return await firstValueFrom(this.storage.ref(path).getDownloadURL());
  }

  async getBarsPromise() {
    return await this.staffAppBase
      .list("barnames")
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async saveUserLevelBar(user) {
    var res;
    res = await this.staffAppBase
      .object("staff/" + user.uid + "/access")
      .set(user.access)
      .then((a) => null)
      .catch((e) => e);
    if (res) {
      return res;
    }

    res = await this.staffAppBase
      .object("staff/" + user.uid + "/bar")
      .set(user.bar)
      .then((a) => null)
      .catch((e) => e);
    return res;
  }

  async updateStockOrder(id: string, index: number) {
    if (!this.bar) {
      throw new Error("missing bar: " + this.bar);
    }
    if (!id) {
      throw new Error("missing id");
    }
    if (isNaN(index)) {
      throw new Error("missing index or NaN: " + index);
    }
    return await this.staffAppBase
      .object("bars/" + this.bar + "/stock/crates/" + id + "/id")
      .set(index);
  }

  async updateStockOrderCat(id: string, index: number, cat: string) {
    if (!this.bar) {
      throw new Error("missing bar: " + this.bar);
    }
    if (!id) {
      throw new Error("missing id");
    }
    if (isNaN(index)) {
      throw new Error("missing index or NaN: " + index);
    }
    return await this.staffAppBase
      .object("bars/" + this.bar + "/stock/" + cat + "/" + id + "/id")
      .set(index);
  }

  getBarsAsync() {
    return this.staffAppBase
      .list("barnames")
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }
  getBars() {
    const sub = this.staffAppBase
      .list("barnames")
      .valueChanges()
      .subscribe((bars) => {
        const list = [];
        bars.forEach((a) => {
          list.push(a);
        });
        this.barsSubject.next(list);
        sub.unsubscribe();
      });
  }
  getManager(barName) {
    ////console.log('getting maanager for' + barName);
    const sub = this.staffAppBase
      .object("bars/" + barName + "/manager")
      .valueChanges()
      .subscribe((key: String) => {
        ////console.log(key);
        this.managerSubject.next(key);
        sub.unsubscribe();
      });
  }

  async getManagerAsync(barName) {
    return await this.staffAppBase.object("bars/" + barName + "/manager").valueChanges().pipe(take(1)).toPromise();
  }

  addManager(barName, key) {
    this.staffAppBase
      .object("bars/" + barName + "/manager")
      .set(key)
      .then((a) => {
        a;
      })
      .catch((e) => {
        alert(e);
      });
  }
  getHours(
    barname: string,
    year,
    monthStart,
    dayStart,
    yearEnd,
    monthEnd,
    dayEnd,
    cleaning
  ) {
    this.months = [];
    this.getHoursMonth(
      barname,
      year,
      monthStart,
      dayStart,
      yearEnd,
      monthEnd,
      dayEnd,
      cleaning
    );
  }

  getStaffNames(barname) {
    return this.staffAppBase
      .list("staff/", (ref) => ref.orderByChild("bar").equalTo(barname))
      .valueChanges();
  }

  async getStaffNamesAsync(barname) {
    return await firstValueFrom(
      this.staffAppBase
        .list("staff/", (ref) => ref.orderByChild("bar").equalTo(barname))
        .valueChanges()
    );
  }

  // getStaffDays

  getStaffDays(uid, date: Date, bar) {
    return new Promise((res, rej) => {
      const sub = this.staffAppBase
        .list(
          "bars/" +
            bar +
            "/plan/" +
            date.getFullYear() +
            "/" +
            date.getMonth() +
            "/" +
            uid
        )
        .snapshotChanges()
        .subscribe((val) => {
          sub.unsubscribe();

          let temp = val.map((a: any) => {
            const data = a.payload.val();
            const key = a.key;
            const day = new Date(date);
            day.setDate(Number(key));
            return { data: a.payload.val(), date: day, key: a.key };
          });

          return res(temp);
        });
    });
  }

  setStaffDays(uid, date, bar, request) {
    ////console.log(uid, date,bar, request)
    return new Promise((res, rej) => {
      this.staffAppBase
        .object(
          "bars/" +
            bar +
            "/plan/" +
            date.getFullYear() +
            "/" +
            date.getMonth() +
            "/" +
            uid +
            "/" +
            date.getDate() +
            "/request"
        )
        .set(request)
        .then((a) => {
          return res(request);
        })
        .catch((e) => {
          console.error(e);
          return rej();
        });
    });
  }

  requestDay(bar, uid, date: Date, reason) {
    this.staffAppBase
      .object(
        "bars/" +
          bar +
          "/plan/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          uid +
          "/" +
          date.getDate() +
          "/reason"
      )
      .set(reason);
  }
  getRequestDay(bar, uid, date: Date) {
    return new Promise((val, rej) => {
      const sub = this.staffAppBase
        .object(
          "bars/" +
            bar +
            "/plan/" +
            date.getFullYear() +
            "/" +
            date.getMonth() +
            "/" +
            uid +
            "/" +
            date.getDate() +
            "/reason"
        )
        .valueChanges()
        .subscribe((val2) => {
          ////console.log(val2  )
          sub.unsubscribe();
          return val(val2);
        });
    });
  }

  setTarget(bar, uid, date, target) {
    this.staffAppBase
      .object(
        "bars/" +
          bar +
          "/plan/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          uid +
          "/" +
          date.getDate() +
          "/target"
      )
      .set(target);
  }

  getHoursForMonth(barname, uid, year, month) {
    return new Promise((res, rej) => {
      const sub = this.staffAppBase
        .list("bars/" + barname + "/hours/" + year + "/" + month)
        .snapshotChanges()
        .subscribe((val) => {
          sub.unsubscribe();

          let temp = val
            .map((a: any) => {
              const data = a.payload.val();

              const key = a.key;
              const day = new Date(year, month, Number(key));
              return { data: a.payload.val(), date: day };
            })
            .filter((a) => a);

          return res(temp);
        });
    });
  }

  async getHoursForStaff(
    barname: string,
    startDay: Date,
    endDay: Date,
    uid: string
  ) {
    const hours = [];
    const currentDay = new Date(startDay);
    const lastMonth = new Date(endDay);
    lastMonth.setMonth(lastMonth.getMonth() + 1);
    while (
      !(
        currentDay.getFullYear() == lastMonth.getFullYear() &&
        currentDay.getMonth() == lastMonth.getMonth()
      )
    ) {
      const month = currentDay.getMonth();
      const year = currentDay.getFullYear();
      const list = await this.staffAppBase
        .list("bars/" + barname + "/hours/" + year + "/" + month)
        .valueChanges()
        .pipe(
          take(1),
          map((a) => a.filter((b) => Object.keys(b).includes(uid))),
          map((list) => {
            const d = [];
            list.forEach((a) => {
              d.push(a[uid]);
            });
            return d;
          })
        )
        .toPromise();
      list.forEach((a) => {
        hours.push(a);
      });
      currentDay.setMonth(currentDay.getMonth() + 1);
    }

    return hours;
  }

  getLostAndFound(barname) {
    return this.staffAppBase
      .list("bars/" + barname + "/lost/reportedItems")
      .snapshotChanges()
      .pipe(
        take(1),
        map((a: any) => {
          return a.map((b) => b.payload.val());
        })
      )
      .toPromise();
  }

  async removeLostAndFound(barname, item) {
    await this.staffAppBase
      .list("archive/bars/" + barname + "/lost/reportedItems")
      .set(item.key, item);
    await this.staffAppBase
      .object("bars/" + barname + "/lost/tokens/" + item.token)
      .remove();
    await this.staffAppBase
      .object("bars/" + barname + "/lost/reportedItems/" + item.key)
      .remove();
  }

  async getCompanyInfo(barname) {
    return this.staffAppBase
      .object("bars/" + barname + "/companyInfo")
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async setCompanyInfo(barname, info) {
    return await this.staffAppBase
      .object("bars/" + barname + "/companyInfo")
      .set(info);
  }

  async saveDrinkOrderFinal(name, invoice, type) {
    if (invoice.information.datetime && invoice.information.key) {
      const date = new Date(invoice.information.datetime);
      return await this.staffAppBase
        .list(
          "bars/" +
            name +
            "/records/kostenoverview/" +
            date.getFullYear() +
            "/" +
            date.getMonth() +
            "/" +
            type
        )
        .set(invoice.information.key, invoice);
    }
  }

  getLostAndFoundPhotos(key, index) {
    return new Promise((res, rej) => {
      try {
        const sub = this.storage
          .ref("reportedItems/" + key + "_" + index)
          .getDownloadURL()
          .subscribe(
            (val) => {
              if (sub) {
                sub.unsubscribe();
              }
              return res(val);
            },
            (error) => {
              return res("./assets/logo.png");
            }
          );
      } catch (e) {
        return res("./assets/logo.png");
      }
    });
  }

  async getStockAttempts(barname: string, date: Date) {
    return this.staffAppBase
      .list(
        "bars/" +
          barname +
          "/records/stockAttempts/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          date.getDate()
      )
      .snapshotChanges()
      .pipe(
        map((a: any) =>
          a.map((b) => {
            const d = { key: b.payload.key, value: b.payload.val() };
            return d;
          })
        ),
        take(1)
      )
      .toPromise();
  }

  async getCountTimes(barname: string, date: Date) {
    return this.staffAppBase
      .list(
        "bars/" +
          barname +
          "/records/countTimes/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          date.getDate()
      )
      .snapshotChanges()
      .pipe(
        map((a: any) =>
          a.map((b) => {
            const d = { key: b.payload.key, value: b.payload.val() };
            return d;
          })
        ),
        take(1)
      )
      .toPromise();
  }

  async getFinalCount(barname: string, date: Date) {
    return this.staffAppBase
      .list(
        "bars/" +
          barname +
          "/records/stock/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          date.getDate()
      )
      .snapshotChanges()
      .pipe(
        map((a: any) =>
          a.map((b) => {
            const d = { key: b.payload.key, value: b.payload.val() };
            return d;
          })
        ),
        take(1)
      )
      .toPromise();
  }

  async getHoursAsync(startDate: Date, endDate: Date) {
    var bar = this.bar;
    if (!bar) {
      console.error("no bar set");
      return [];
    }

    if (!startDate || !endDate) {
      console.error("no dates set");
      return [];
    }

    if (startDate > endDate) {
      console.error("start date is after end date");
      return [];
    }

    const start = new Date(startDate);
    const end = new Date(endDate);
    var hours = [];
    for (
      var date = new Date(start);
      date <= end;
      date.setDate(date.getDate() + 1)
    ) {
      var day = await this.staffAppBase
        .list(
          "bars/" +
            bar +
            "/hours/" +
            date.getFullYear() +
            "/" +
            date.getMonth() +
            "/" +
            date.getDate()
        )
        .valueChanges()
        .pipe(take(1))
        .toPromise();
      hours.push({ day, date: new Date(date) });
    }

    return hours;
  }

  // year = int  2020
  // month = int 1-12
  // days = int or string 1-31
  getHoursMonth(
    barname: string,
    year,
    monthStart,
    dayStart,
    yearEnd,
    monthEnd,
    dayEnd,
    cleaning: boolean
  ) {
    let ref;
    if (cleaning) {
      ref =
        "bars/" + barname + "/" + "cleaningHours/" + year + "/" + monthStart;
    } else {
      ref = "bars/" + barname + "/" + "hours/" + year + "/" + monthStart;
    }

    const list = this.staffAppBase
      .list(ref)
      .valueChanges()
      .subscribe((startMonth) => {
        this.months.push(startMonth);
        list.unsubscribe();

        monthStart++;
        if (monthStart > 12 && yearEnd !== year) {
          monthStart = 0;
          year++;
        }

        if (monthStart > monthEnd && year === yearEnd) {
          this.sortHours(dayStart, dayEnd);
        } else {
          this.getHoursMonth(
            barname,
            year,
            monthStart,
            dayStart,
            yearEnd,
            monthEnd,
            dayEnd,
            cleaning
          );
        }
      });
  }
  getWorkingDay(barname, date) {
    const splits = date.split("-");
    //console.log(barname, date);
    return this.staffAppBase
      .list(
        "bars/" +
          barname +
          "/hours/" +
          splits[0] +
          "/" +
          splits[1] +
          "/" +
          splits[2]
      )
      .valueChanges();
  }

  getWorkingDays(barname, uid, year, month) {
    return this.staffAppBase
      .list("bars/" + barname + "/schedule/plan/" + year + "/" + month)
      .valueChanges();
  }

  sortHours(startDay, endDay) {
    //console.log(startDay, endDay);
    const days = [];
    // ordered start to end
    for (let i = 0; i < this.months.length; i++) {
      const month: any[] = this.months[i];
      for (let j = 0; j < month.length; j++) {
        const dayOfMonth: any = month[j];
        const keys = Object.keys(dayOfMonth);
        keys.forEach((k) => {
          const staff = dayOfMonth[k];
          if (
            (i === 0 && staff.day >= startDay && i !== this.months.length) ||
            (i === 0 && staff.day >= startDay && staff.day <= endDay) ||
            (i < this.months.length && i !== 0) ||
            (i === this.months.length && staff.day <= endDay)
          ) {
            days.push(staff);
          }
        });
      }
    }
    //console.log(days);
    this.hoursSubject.next(days);
  }

  getStaffInfo(barName) {
    this.staffAppBase
      .list("staff", (ref) => ref.orderByChild("bar").equalTo(barName))
      .snapshotChanges()
      .subscribe((bars) => {
        const list = bars.map((d: any) => {
          let temp: any;
          temp = d.payload.val();
          temp.key = d.key;
          return temp;
        });
        this.staffInfoSubject.next(list);
      });
  }

  getStaffAtBar(barname) {
    return this.staffAppBase
      .list("staff", (ref) => ref.orderByChild("bar").equalTo(barname))
      .valueChanges();
  }

  getStaffAtBarAsync(barname) {
    return this.staffAppBase
      .list("staff", (ref) => ref.orderByChild("bar").equalTo(barname))
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  updateStaffInfo(barName, p) {
    this.staffAppBase.list("staff").set(p.uid, p);
  }

  createStaff(barName, p) {
    this.staffAppBase.list("staff").push(p);
  }

  deleteContract(p, co) {
    //console.log(p, co);
    return this.storage
      .ref("documents/" + p.uid + "/contract/" + co.key)
      .delete()
      .toPromise();
  }

  deletePayslip(p, pa) {
    //console.log(p, pa);
    return this.storage
      .ref("documents/" + p.uid + "/payslips/" + pa.key)
      .delete()
      .toPromise();
  }

  deleteLoonheffing(p, pa) {
    //console.log(p, pa);
    return this.storage
      .ref("documents/" + p.uid + "/loonheffing/" + pa.key)
      .delete()
      .toPromise();
  }

  async deleteStaff(user) {
    if (!user) {
      return;
    }
    await this.staffAppBase.object("staff/" + user.uid + "/access").set(1);
    return await this.staffAppBase
      .object("staff/" + user.uid + "/bar")
      .set("Old Staff")
      .then((a) => null)
      .catch((e) => e);
  }

  setShift(bar, date: Date, val: any, cleaning = false) {
    //console.log(bar, date, val, cleaning);
    return new Promise((res, rej) => {
      this.staffAppBase
        .list(
          "bars/" +
            bar +
            "/" +
            (cleaning ? "cleaningHours" : "hours") +
            "/" +
            date.getFullYear() +
            "/" +
            date.getMonth() +
            "/" +
            date.getDate()
        )
        .set(val.key, val)
        .then((a) => {
          return res("");
        })
        .catch((e) => {
          return rej(e);
        });
    });
  }

  deleteShift(bar, date: Date, val: any, cleaning = false) {
    //console.log(bar, date, val, cleaning);
    return new Promise((res, rej) => {
      this.staffAppBase
        .list(
          "bars/" +
            bar +
            "/" +
            (cleaning ? "cleaningHours" : "hours") +
            "/" +
            date.getFullYear() +
            "/" +
            date.getMonth() +
            "/" +
            date.getDate()
        )
        .remove(val.key)
        .then((a) => {
          return res("");
        })
        .catch((e) => {
          console.error(e);
          return rej(e);
        });
    });
  }

  getNDItemsAsync(barName) {
    return this.staffAppBase
      .list("bars/" + barName + "/ndstock")
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  getNDItems(barName) {
    const sub = this.staffAppBase
      .list("bars/" + barName + "/ndstock")
      .valueChanges()
      .subscribe((bars) => {
        const list = [];
        bars.forEach((a) => {
          list.push(a);
        });
        this.ndItemsSubject.next(list);
        sub.unsubscribe();
      });
  }

  addNDItem(barName, item) {
    this.staffAppBase
      .list("bars/" + barName + "/ndstock")
      .push(item)
      .then((ref) => {
        this.staffAppBase
          .list("bars/" + barName + "/ndstock/" + ref.key)
          .set("key", ref.key);
      });
  }

  async updateNDItem(barName, item) {
    await this.staffAppBase
      .list("bars/Daga Beheer/ndstock/")
      .set(item.key, item);
    return await this.staffAppBase
      .list("bars/" + barName + "/ndstock")
      .set(item.key, item);
  }

  async updateNDItemOrder(barName, item) {
    await this.staffAppBase
      .list("bars/Daga Beheer/ndstock/" + item.key + "/bars/" + barName)
      .set("position", item.bars[barName].position);
    await this.staffAppBase
      .list("bars/" + barName + "/ndstock/" + item.key + "/bars/" + barName)
      .set("position", item.bars[barName].position);
    return;
  }

  async updateNDItemQuantity(barName, item) {
    await this.staffAppBase
      .list("bars/Daga Beheer/ndstock/" + item.key + "/bars/" + barName)
      .set("defaultQuantity", Number(item.bars[barName].defaultQuantity));
    await this.staffAppBase
      .list("bars/" + barName + "/ndstock/" + item.key + "/bars/" + barName)
      .set("defaultQuantity", Number(item.bars[barName].defaultQuantity));
    return;
  }

  async getNROrderSync(barName, startDate: Date, endDate: Date) {
    return firstValueFrom(
      this.staffAppBase
        .list("bars/" + barName + "/ndorders", (ref) =>
          ref
            .orderByChild("dateTime")
            .startAfter(startDate.getTime() + "")
            .endBefore(endDate.getTime() + "")
        )
        .valueChanges()
        .pipe(take(1))
    );
  }

  getNDOrder(barName) {
    const sub = this.staffAppBase
      .list("bars/" + barName + "/ndorders", (ref) =>
        ref.orderByChild("dateTime").limitToLast(15)
      )
      .valueChanges()
      .subscribe((bars) => {
        const list = [];
        bars.forEach((a) => {
          list.push(a);
        });
        list.reverse();
        this.ndOrdersSubject.next(list);
        sub.unsubscribe();
      });
  }

  deleteNDOrderItem(barName, item) {
    if (item.key && barName) {
      this.staffAppBase
        .list("bars/" + barName + "/ndstock/" + item.key)
        .remove();
    }
  }

  async deleteNDOrder(barName, order) {
    if (barName && order.key) {
      await this.staffAppBase
        .list("archive/bars/" + barName + "/ndorders")
        .set(order.key, order);
      await this.staffAppBase
        .list("bars/" + barName + "/ndorders/" + order.key)
        .remove();
    }
  }

  saveNDOrder(barName, order) {
    if (barName && order.key) {
      this.staffAppBase
        .list("bars/" + barName + "/ndorders/")
        .set(order.key, order);
    }
  }

  getOrder(barName) {
    const sub = this.staffAppBase
      .list("bars/" + barName + "/orders", (ref) =>
        ref.orderByChild("dateTime")
      )
      .valueChanges()
      .subscribe((bars) => {
        const list = [];
        bars.forEach((a) => {
          list.push(a);
        });
        list.reverse();
        this.ndOrdersSubject.next(list);
        sub.unsubscribe();
      });
  }

  async deleteOrder(barName, order) {
    if (barName && order.key) {
      await this.staffAppBase
        .list("archive/bars/" + barName + "/orders")
        .set(order.key, order);
      await this.staffAppBase
        .list("bars/" + barName + "/orders/" + order.key)
        .remove();
    }
  }

  saveOrder(barName, order) {
    if (barName && order.key) {
      this.staffAppBase
        .list("bars/" + barName + "/orders/")
        .set(order.key, order);
    }
  }

  async updateOffset(key, offset, bar) {
    //console.log(key, offset, bar);
    return await this.staffAppBase
      .object("bars/" + bar + "/stock/crates/" + key + "/offset")
      .set(offset);
  }

  getStock(month, year, barName) {
    const sub = this.staffAppBase
      .list("bars/" + barName + "/records/stock/" + year + "/" + month)
      .snapshotChanges()
      .subscribe((values) => {
        ////console.log(values);
        const list = values.map((d: any) => {
          let temp: any;
          temp = d.payload.val();
          temp.day = d.key;
          return temp;
        });
        ////console.log(list);
        sub.unsubscribe();
        this.countedStockSubject.next(list);
      });
  }

  getNDStock(month, year, barName) {
    const sub = this.staffAppBase
      .list("bars/" + barName + "/records/ndstock/" + year + "/" + month)
      .snapshotChanges()
      .subscribe((values) => {
        ////console.log(values);
        const list = values.map((d: any) => {
          let temp: any;
          temp = d.payload.val();
          temp.day = d.key;
          return temp;
        });
        ////console.log(list);
        sub.unsubscribe();
        this.countedNDStockSubject.next(list);
      });
  }

  async resetOffsets(barName, stock: any[]) {
    let list: any = {};
    stock.forEach((s) => {
      s.offset = 0;
      list[s.serverId] = s;
    });
    for (var s of stock) {
      s.offset = 0;
      await this.staffAppBase
        .object(
          "bars/" +
            barName +
            "/stock/" +
            s.category +
            "/" +
            s.serverId +
            "/offset"
        )
        .set(0);
    }
  }

  saveStock(barName, stock: any[], cat: string) {
    let list: any = {};
    stock.forEach((s) => {
      list[s.serverId] = s;
    });
    this.staffAppBase
      .object("bars/" + barName + "/stock/" + cat + "/")
      .set(list);
  }

  async updatePrice(bar, id, newPrice, type) {
    //console.log("updating price: ", id, newPrice, type);
    return await this.staffAppBase
      .object("bars/" + bar + "/stock/" + type + "/" + id + "/price")
      .set(Number(newPrice));
  }

  getTill(year, barName) {
    ////console.log( year, barName);
    const sub = this.staffAppBase
      .list("bars/" + barName + "/records/till", (ref) =>
        ref
          .orderByKey()
          .startAt(year + "-")
          .endAt(year + "~")
      )
      .snapshotChanges()
      .subscribe((values) => {
        ////console.log(values);
        const list = values.map((d: any) => {
          let temp: any;
          temp = d.payload.val();
          ////console.log(temp);
          if (temp.end) {
            temp.end.realCash = Number(temp.end.realCash);
            temp.end.realPin = Number(temp.end.realPin);
          }

          if (temp.start) {
            temp.start.original = Number(temp.start.original);
            temp.start.updated = Number(temp.start.updated);
            temp.start.difference = Number(temp.start.difference);
          }
          ////console.log(temp);
          temp.day = d.key;
          return temp;
        });
        ////console.log(list);
        this.tillCountedSubject.next(list);
        sub.unsubscribe();
      });
  }

  sameMonth(key, month, year) {
    ////console.log(key, month, year);
    return true;
  }

  async getIncorrectionReason(barName, date: Date) {
    return new Promise((a, b) => {
      ////console.log('getting reason for: ','bars/' + barName + '/records/stock-reasons/' + date.getFullYear() + '/' + date.getMonth() + "/" + date.getDate() + "/end")
      const sub = this.staffAppBase
        .object(
          "bars/" +
            barName +
            "/records/stock-reasons/" +
            date.getFullYear() +
            "/" +
            date.getMonth() +
            "/" +
            date.getDate() +
            "/end"
        )
        .valueChanges()
        .subscribe((v) => {
          sub.unsubscribe();
          ////console.log(v);
          return a(v);
        });
    });
  }
  getHoursForDate(barName, date: Date) {
    const sub = this.staffAppBase
      .list(
        "bars/" +
          barName +
          "/hours/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          date.getDate()
      )
      .valueChanges()
      .subscribe((values) => {
        if (values) {
          this.staffWorkedSubject.next(values);
        }
        sub.unsubscribe();
      });
  }

  async getHoursForDate2(bar, date: Date) {
    console.log(
      "getting hours for: ",
      "bars/" +
        bar +
        "/hours/" +
        date.getFullYear() +
        "/" +
        date.getMonth() +
        "/" +
        date.getDate()
    );
    return await this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/hours/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          date.getDate()
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async getCleaningHoursForDate2(bar, date: Date) {
    return await this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/cleaningHours/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          date.getDate()
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async getCountForDate2(bar, date: Date) {
    return await this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/records/stock/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          date.getDate() +
          "/end"
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async getTillForDate2(bar, date: Date) {
    return await this.staffAppBase
      .object(
        "bars/" +
          bar +
          "/records/till/" +
          date.getFullYear() +
          "-" +
          date.getMonth() +
          "-" +
          date.getDate()
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async updateBonnenVAT(bar, date: Date, final, name) {
    return await this.staffAppBase
      .object(
        "bars/" +
          bar +
          "/records/till/" +
          date.getFullYear() +
          "-" +
          date.getMonth() +
          "-" +
          date.getDate() +
          "/final/" +
          name
      )
      .set(final);
  }
  async updateTellingFinal(
    bar,
    date: Date,
    final: any,
    name: string,
    checked: boolean
  ) {
    const val = await this.staffAppBase
      .object(
        "bars/" +
          bar +
          "/records/till/" +
          date.getFullYear() +
          "-" +
          date.getMonth() +
          "-" +
          date.getDate() +
          "/final/checks/" +
          name
      )
      .set(checked)
      .then((a) => null)
      .catch((e) => e);
    if (val) {
      throw new Error(val);
    }
    return await this.staffAppBase
      .object(
        "bars/" +
          bar +
          "/records/till/" +
          date.getFullYear() +
          "-" +
          date.getMonth() +
          "-" +
          date.getDate() +
          "/final/" +
          name
      )
      .set(final);
  }

  async updateTellingDifferences(bar, date: Date, name, difference) {
    return await this.staffAppBase
      .object(
        "bars/" +
          bar +
          "/records/till/" +
          date.getFullYear() +
          "-" +
          date.getMonth() +
          "-" +
          date.getDate() +
          "/final/differences/" +
          name
      )
      .set(difference)
      .then((a) => null)
      .catch((e) => e);
  }

  async updateTellingInternet(bar, date: Date, internet: any) {
    return await this.staffAppBase
      .object(
        "bars/" +
          bar +
          "/records/till/" +
          date.getFullYear() +
          "-" +
          date.getMonth() +
          "-" +
          date.getDate() +
          "/internet"
      )
      .set(Number(internet));
  }

  async getFinalTelling(bar, date: Date) {
    return await this.staffAppBase
      .object(
        "bars/" +
          bar +
          "/records/till/" +
          date.getFullYear() +
          "-" +
          date.getMonth() +
          "-" +
          date.getDate()
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async getFinalTellingChecks(bar, date: Date) {
    return await this.staffAppBase
      .object(
        "bars/" +
          bar +
          "/records/till/" +
          date.getFullYear() +
          "-" +
          date.getMonth() +
          "-" +
          date.getDate() +
          "/final/checks"
      )
      .valueChanges()
      .pipe(
        take(1),
        map((a: any[]) => {
          //map to object with date and value
          return { date: new Date(date), value: a };
        })
      )
      .toPromise();
  }

  async getDiscounts(bar, date: Date) {
    return await this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/records/discounts/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          date.getDate()
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async getMessagesAsync(barName) {
    return firstValueFrom(
      this.staffAppBase
        .list("bars/" + barName + "/messages")
        .snapshotChanges()
        .pipe(
          map((d) => {
            const list = d.map((d: any) => {
              const item = d.payload.val();
              item.ref = d.key;
              return item;
            });
            return list;
          })
        )
    );
  }
  getMessages(barName) {
    const sub = this.staffAppBase
      .list("bars/" + barName + "/messages")
      .snapshotChanges()
      .subscribe((values) => {
        sub.unsubscribe();
        const list = values.map((d: any) => {
          const item = d.payload.val();
          item.ref = d.key;
          return item;
        });
        ////console.log(list);
        this.messagesSubject.next(list);
      });
  }

  updateMessage(barName, message) {
    this.staffAppBase
      .list("bars/" + barName + "/messages/")
      .set(message.ref, message);
  }

  addMessage(barName, message) {
    ////console.log(barName, message);
    this.staffAppBase.list("bars/" + barName + "/messages/").push(message);
  }

  deleteMessage(barName, message) {
    if (message.ref && message.ref !== " " && message.ref !== "") {
      this.staffAppBase
        .list("bars/" + barName + "/messages/" + message.ref)
        .remove();
    }
  }

  getTasks(barName) {
    const sub = this.staffAppBase
      .list("bars/" + barName + "/tasks")
      .snapshotChanges()
      .subscribe((values) => {
        const list = values.map((d: any) => {
          const item = d.payload.val();
          if (!item.status) {
            item.status = "requested";
          }
          item.ref = d.key;
          return item;
        });
        ////console.log(list);
        this.tasksSubject.next(list);
      });
  }

  updateTask(barName, task) {
    this.staffAppBase.list("bars/" + barName + "/tasks/").set(task.ref, task);
  }

  sendTaskMessage(barName, task, message) {
    this.staffAppBase
      .list("bars/" + barName + "/tasks/" + task.ref + "/messages")
      .push(message);
  }
  getOrders(barName) {
    const sub = this.staffAppBase
      .list("bars/" + barName + "/orders", (ref) => ref.limitToLast(15))
      .valueChanges()
      .subscribe((values) => {
        ////console.log(values);
        this.ordersSubject.next(values);
        sub.unsubscribe();
      });
  }

  async getOrdersAsync(
    barName: string,
    page: number = 0,
    pageSize: number = 10,
    firstDoc: any = null
  ): Promise<any> {
    //console.log("getting orders", barName, page, pageSize, firstDoc);
    let query = this.staffAppBase
      .list(`bars/${barName}/orders`, (ref) => {
        if (firstDoc) {
          //console.log(firstDoc.key);
          return ref.orderByKey().limitToLast(pageSize).endBefore(firstDoc.key);
        } else {
          return ref.orderByKey().limitToLast(pageSize);
        }
      })
      .valueChanges()
      .pipe(
        take(1),
        map((a) => {
          if (a.length > 0) {
            let endDoc = a[0]; // Assuming the last document in the batch
            return { endDoc, list: a.reverse() };
          } else {
            return { endDoc: null, list: [] };
          }
        })
      );

    return firstValueFrom(query);
  }

  getOrdersItems(barName) {
    const sub = this.staffAppBase
      .list("bars/" + barName + "/stock")
      .valueChanges()
      .subscribe((values) => {
        ////console.log(values);
        this.ordersSubjectItems.next(values);
        sub.unsubscribe();
      });
  }

  getAllStock(barName) {
    return this.staffAppBase
      .list("bars/" + barName + "/stock")
      .snapshotChanges();
  }

  getAllStockAsync(barName) {
    return this.staffAppBase
      .list("bars/" + barName + "/stock")
      .valueChanges()
      .pipe(
        take(1),
        map((a: any) => {
          return a;
        })
      )
      .toPromise();
  }
  getAllNDStockAsync(barName) {
    return this.staffAppBase
      .list("bars/" + barName + "/ndstock")
      .valueChanges()
      .pipe(
        take(1),
        map((a: any) => {
          return a;
        })
      )
      .toPromise();
  }

  getAllStockSnapshotAsync(barName) {
    return this.staffAppBase
      .list("bars/" + barName + "/stock")
      .snapshotChanges()
      .pipe(take(1))
      .toPromise();
  }
  getCrates(barName) {
    return this.staffAppBase
      .list("bars/" + barName + "/stock/crates")
      .valueChanges();
  }

  getBar(user) {
    return this.staffAppBase.object("staff/" + user + "/bar").valueChanges();
  }

  getAccess(uid) {
    return this.staffAppBase.object("staff/" + uid + "/access").valueChanges();
  }

  getUserName(ref): Observable<any> {
    return this.staffAppBase
      .object("staff/" + ref + "/displayName")
      .valueChanges();
  }

  getUsername(uid, full = false) {
    return this.staffAppBase
      .object("staff/" + uid + "/displayName")
      .valueChanges();
  }

  async getFullName(uid): Promise<String> {
    var staff: any = await this.staffAppBase
      .object("staff/" + uid)
      .valueChanges()
      .pipe(take(1))
      .toPromise();

    if (staff) {
      return staff.firstName + " " + staff.lastName;
    } else {
      return "";
    }
  }

  getStaff() {
    return this.staffAppBase.list("staff").valueChanges();
  }

  getStaffAsync() {
    return this.staffAppBase
      .list("staff")
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  saveStaff(user) {
    if (user.uid) {
      return this.staffAppBase.object("staff/" + user.uid).set(user);
    }
  }

  findStaff(email, user) {
    let sub = this.staffAppBase
      .list("staff", (ref) => ref.orderByChild("email").equalTo(email))
      .snapshotChanges()
      .subscribe((action) => {
        let list = action.map((d: any) => {
          let temp: any = {};
          temp.value = d.payload.val();
          temp.key = d.key;
          return temp;
        });
        if (list.length == 1) {
          user.uid = list[0].key;
          this.addStaff(user);
        }
        sub.unsubscribe();
      });
  }

  addStaff(user) {
    this.staffAppBase.list("staff/").set(user.uid, user);
  }

  changeAccessLevel(level, uid) {
    ////console.log(level,uid);
    this.staffAppBase
      .object("staff/" + uid + "/access")
      .set(Number.parseInt(level));
  }

  removeStaff(uid) {
    this.staffAppBase.object("staff/" + uid).remove();
  }

  addChat(ref, message) {
    if (message) {
      ////console.log(message);
      this.staffAppBase.list("admin/chats/" + ref).push(message);
    }
  }

  getChat(ref) {
    return this.staffAppBase
      .list("admin/chats/" + ref, (query) =>
        query.orderByChild("date").limitToLast(25)
      )
      .snapshotChanges();
  }

  notifyUser(theirUid, myUId) {
    this.staffAppBase
      .object("admin/notifications/" + theirUid + "/messages/" + myUId)
      .set(myUId);
  }

  getNotifications(myUid) {
    return this.staffAppBase
      .list("admin/notifications/" + myUid)
      .snapshotChanges();
  }

  deleteNotification(myUid, notifRef) {
    this.staffAppBase
      .list("admin/notifications/" + myUid + "/messages/" + notifRef)
      .remove();
  }

  async addBugReport(report) {
    return await this.staffAppBase
      .list("/bugReports/open")
      .set(String(report.date), report);
  }

  getBugReports() {
    return this.staffAppBase
      .list("admin/bugs", (query) =>
        query.orderByChild("status").equalTo("created")
      )
      .snapshotChanges();
  }

  addComment(key, comment) {
    this.staffAppBase.list("admin/bugs/" + key + "/comments").push(comment);
  }

  getAvatarConfig(uid) {
    return this.staffAppBase.list("staff/" + uid + "/avatar").valueChanges();
  }

  saveAvatarConfig(uid, config) {
    if (uid && config) {
      ////console.log(uid, config);
      this.staffAppBase.object("staff/" + uid + "/avatar/").set(config);
    }
  }

  saveVip(name, info) {
    return this.staffAppBase
      .object("bars/" + name + "/vipInformation")
      .set(info);
  }

  saveCalendarAPIConfig(name, info) {
    return this.staffAppBase.object("bars/" + name + "/calendarAPI").set(info);
  }

  saveTill(name, info) {
    return this.staffAppBase
      .object("bars/" + name + "/tillInformation")
      .set(info);
  }

  getTillInformation(name) {
    return this.staffAppBase
      .object("bars/" + name + "/tillInformation")
      .valueChanges();
  }

  getVipInformation(name) {
    return this.staffAppBase
      .object("bars/" + name + "/vipInformation")
      .valueChanges();
  }

  getCalendarAPIConfig(name) {
    return this.staffAppBase
      .object("bars/" + name + "/calendarAPI")
      .valueChanges()
      .pipe(
        take(1),
        map((a) => a as CalendarAPIConfig)
      )
      .toPromise();
  }

  getVipRooms(name) {
    return this.staffAppBase.list("bars/" + name + "/vipRooms").valueChanges();
  }

  setVipRooms(name, room) {
    if (room.key) {
      this.staffAppBase.list("bars/" + name + "/vipRooms/").set(room.key, room);
    } else {
      this.staffAppBase
        .list("bars/" + name + "/vipRooms/")
        .push(room)
        .then((ref) => {
          this.staffAppBase
            .list("bars/" + name + "/vipRooms/" + ref.key)
            .set("key", ref.key);
        });
    }
  }

  setDrinkForVipRoom(name, room, drinkId, quantity) {
    this.staffAppBase
      .list("bars/" + name + "/stock/crates/" + drinkId + "/vipRooms")
      .set(room.key, quantity);
  }

  deleteDrink(drink, cat, name) {
    if ((drink.serverId || drink.key) && cat) {
      ////console.log('removing: ', drink, 'from: ', cat);
      ////console.log('key',('bars/' + name + '/stock/' + cat + '/' + (drink.serverId ? drink.serverId : drink.key)));
      this.staffAppBase
        .list(
          "bars/" +
            name +
            "/stock/" +
            cat +
            "/" +
            (drink.serverId ? drink.serverId : drink.key)
        )
        .remove();
    }
  }

  async addDrink(drink, cat, name) {
    if (!name) {
      console.error("no name");
      return new Error("no barname");
    }
    //console.log(drink, cat, name);
    if (drink.serverId && cat) {
      //console.log("adding: ", drink, "to: ", cat, drink.serverId);

      return await this.staffAppBase
        .list("bars/" + name + "/stock/" + cat)
        .set(drink.serverId + "", drink);
    } else {
      return new Error("no id or category");
    }
  }

  async addDrinkVariable(name, cat, id, key, variable, type) {
    //console.log(name, cat, id, key, variable);
    if (id && cat) {
      ////console.log('adding: ', drink, 'to: ', cat);

      return await this.staffAppBase
        .list("bars/" + name + "/stock/" + cat + "/" + id)
        .set(key, type === "number" ? Number(variable) : variable)
        .then((a) => a)
        .catch((e) => e);
    } else {
      //console.log("cant add");
      return "no id or category";
    }
  }

  intercept(url) {
    return this.functions
      .httpsCallable("SoapIntercept")(url)
      .pipe(take(1))
      .toPromise();
  }

  httpsCall(data) {
    const message = this.functions.httpsCallable("HttpIntercept");
    return message({ data: data });
  }

  async getTillForDate(ms: any, name) {
    await this.functions
      .httpsCallable("GetTillToday")({ time: ms, bar: name })
      .toPromise()
      .then((a) => {
        //console.log(a);
        return a;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });
  }

  triggerNotification(bar: string, type: string) {
    if (bar && type) {
      this.functions
        .httpsCallable("triggerNotification")({
          bar: bar,
          type: type,
          user: "ADMINTEST",
        })
        .subscribe((values) => {
          ////console.log(values);
        });
    }
  }

  calculateStaffDrinks(barname, month, year) {
    this.functions
      .httpsCallable("clearManagerAllowances")({
        barname: barname,
        month: month,
        year: year,
      })
      .subscribe((values) => {
        ////console.log(values);
      });
  }

  async getDetailedTurnover(date: Date) {
    return await this.functions
      .httpsCallable("GetDetailedTurnover", { timeout: 300000 })({
        bar: this.bar,
        month: date.getTime(),
      })
      .toPromise();
  }

  triggerScheduleNotification(barname: string, dateTime: number, staff: any[]) {
    ////console.log(barname, dateTime, staff);
    if (barname && dateTime && staff) {
      this.functions
        .httpsCallable("TriggerSchedule")({
          bar: barname,
          start: dateTime,
          staff: JSON.stringify(staff),
        })
        .subscribe((values) => {
          ////console.log(values);
        });
    }
  }
  async testStaffDrinks(bar, start, end, fake = true, force = false) {
    return await this.functions
      .httpsCallable("TestStaffDrinks2")({
        bar: bar,
        start: start,
        end: end,
        fake: fake,
        force: force,
        test: true,
      })
      .pipe(take(1))
      .toPromise();
  }

  async testDiscounts(bar, start, end) {
    return await this.functions
      .httpsCallable("GetDiscounts")({ bar: bar, start: start, end: end })
      .pipe(take(1))
      .toPromise();
  }

  async testInOut(bar, date) {
    return await this.functions
      .httpsCallable("InOutCashItem")({ bar: bar, date: date.getTime() })
      .pipe(take(1))
      .toPromise();
  }

  async testInkoop(bar, start) {
    return await this.functions
      .httpsCallable("TestInkoop")({ bar: bar, start: start })
      .pipe(take(1))
      .toPromise();
  }

  //get default allowances
  getStaffDrinksDefault() {
    return this.staffAppBase.object("admin/drinkCalculation");
  }

  updateStaffDrinksDefault(def) {
    this.staffAppBase.object("admin/drinkCalculation").set(def);
  }

  //allowances per bar
  getStaffDrinksForBar(bar) {
    return this.staffAppBase.object("admin/bars/" + bar + "/drinkCalculation");
  }

  updateStaffDrinksForBar(bar, def) {
    this.staffAppBase
      .object("admin/bars/" + bar + "/drinkCalculation")
      .set(def);
  }

  getDrinkName(drinkKey, bar) {
    return this.staffAppBase.list("bars/" + bar + "/stock", (query) =>
      query.orderByChild(drinkKey)
    );
  }

  //drinks of staff
  getStaffDrinksForUser(uid) {
    return this.staffAppBase.list("stafforders/" + uid).snapshotChanges();
    //return this.staffAppBase.list('stafforders/' + uid, query => query.limitToLast(15).endAt(Date.now())).snapshotChanges();
  }

  getDrinksForBar(bar) {
    return this.staffAppBase
      .list("stafforders/", (query) => query.orderByChild("bar").equalTo(bar))
      .snapshotChanges();
  }

  giftDrink(transaction, uid) {
    this.staffAppBase
      .object("stafforders/" + uid + "/" + new Date().getTime())
      .set(transaction);
    this.staffAppBase
      .object("staff/" + uid + "/balance")
      .query.ref.transaction((count) =>
        count ? ++transaction.total : transaction.total
      );
  }

  getUser(id) {
    return this.staffAppBase.object("staff/" + id).valueChanges();
  }

  async rewardStaff(gift: Giftable) {
    var res = await this.staffAppBase
      .object("stafforders/" + gift.to + "/" + new Date().getTime())
      .set(gift)
      .then((a) => null)
      .catch((e) => e);
    if (res) {
      throw new Error(res);
    }
    res = await this.staffAppBase
      .object("staff/" + gift.to + "/balance")
      .query.ref.transaction((count) => {
        if (!count) {
          count = gift.total;
        } else {
          count += gift.total;
        }
        return count;
      })
      .then((a) => null)
      .catch((e) => e);
    if (res) {
      throw new Error(res);
    }
  }
  giftStaff(gift: Giftable) {
    this.staffAppBase
      .object("staff/" + gift.from + "/currentAllowance")
      .query.ref.transaction((count) => {
        if (count && count >= gift.total) {
          count -= gift.total;
          return count;
        }
      })
      .then((values) => {
        ////console.log(values);
        if (values.committed) {
          this.staffAppBase
            .object("stafforders/" + gift.to + "/" + new Date().getTime())
            .set(gift);
          this.staffAppBase
            .object("staff/" + gift.to + "/balance")
            .query.ref.transaction((count) => {
              if (!count) {
                count = gift.total;
              } else {
                count += gift.total;
              }
              return count;
            });
        } else {
          alert("not gifted!");
        }
      })
      .catch((errpr) => {
        alert("error on gift");
      });
  }

  //Reviews
  getReportTypes() {
    return this.staffAppBase.list("types/reports").snapshotChanges();
  }

  getReviewsForStaff(uid) {
    return this.staffAppBase
      .list("reports/" + uid, (query) => query.limitToLast(15))
      .valueChanges();
  }

  createReport(report) {}

  createNewReportType(report) {
    if (report.key && report.key !== "" && report.key.length > 5) {
      this.staffAppBase.object("types/reports/" + report.key).set(report);
    } else {
      this.staffAppBase.list("types/reports").push(report);
    }
  }

  deleteReport(key) {
    this.staffAppBase.list("types/reports/" + key).remove();
  }

  getStaffName(uid) {
    ////console.log('getting staff name for:' + uid)
    return this.staffAppBase.list("staff/" + uid + "/firstName").valueChanges();
  }

  async sendEmail(file: InvoiceData) {
    var base64;
    var data;
    if (file.pdfData) {
      base64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(file.pdfData);
      });
      base64 = (base64 as string).replace(/^data:application\/pdf;base64,/, "");

      data = {
        to: file.email,
        subject:
          "Invoice: " + new Date().toDateString() + " from " + file.sender,
        text: "Invoice assigned to " + file.assignedBar,
        pdfBase64: base64,
        filename: file.pdfData.name.split(".")[0],
        type: file.type,
        filetype: "pdf",
      };
    } else {
      var cap = new CapitalizePipe();
      data = {
        to: file.email,
        subject: cap.transform(file.type) + ": " + new Date().toDateString(),
        text: "Email Text",
        type: file.type,
      };
    }

    var callable = this.functions.httpsCallable("sendEmail");

    var [result, error] = await firstValueFrom(callable(data))
      .then((a) => [a, null])
      .catch((e) => [null, e]);

    return [result, error];
  }

  newStaff(staff) {
    ////console.log('makng new staff',staff);
    return new Promise((res, rej) => {
      staff.access = 1;
      const message = this.functions.httpsCallable("addStaff");
      message({ staff }).subscribe((values) => {
        ////console.log(values);
        if (values) {
          staff.uid = values;
          return res(this.saveStaff(staff));
        }
      });
    });
  }

  //Schedule
  requestedStatus(status, uid, reqKey, req) {
    if (status === "Approved") {
      req.status = status;
      this.staffAppBase
        .list("staff/" + uid + "/approvedDays/")
        .set(reqKey, req);
      this.staffAppBase
        .list("staff/" + uid + "/requestedDays/" + reqKey)
        .remove();
    } else {
      this.staffAppBase
        .list("staff/" + uid + "/requestedDays/" + reqKey)
        .set("status", status);
    }
  }

  removeRequested(uid, requestedKey) {
    this.staffAppBase
      .list("staff/" + uid + "/requestedDays/" + requestedKey)
      .remove();
  }

  removeAccepted(uid, acceptedKey) {
    this.staffAppBase
      .list("staff/" + uid + "/approvedDays/" + acceptedKey)
      .remove();
  }

  saveSchedule(bar, day, uid) {
    if (!day.times) {
      day.times = [];
    }

    var checks = {};
    day.times.forEach((a) => {
      //remove duplicates
      checks[a.key] = a;
    });
    return new Promise((res, rej) => {
      ////console.log('saving' ,day, 'to ' + bar);
      const date = new Date(day.date);
      this.staffAppBase
        .list(
          "bars/" +
            bar +
            "/schedule/plan/" +
            date.getFullYear() +
            "/" +
            date.getMonth()
        )
        .set(date.getTime() + "", {
          date: date.getTime(),
          staff: day.staff,
          times: Object.values(checks),
        })
        .then(async (a) => {
          return res(true);
        })
        .catch((e) => {
          return rej(false);
        });
    });
  }

  async getSchedule(bar, date: Date) {
    return await firstValueFrom(
      this.staffAppBase
        .list(
          "bars/" +
            bar +
            "/schedule/plan/" +
            date.getFullYear() +
            "/" +
            date.getMonth()
        )
        .valueChanges()
    );
  }

  async getPlan(bar, date: Date) {
    ////console.log('bars/' + bar + '/plan/'  + date.getFullYear()+ "/" +date.getMonth())
    return await firstValueFrom(
      this.staffAppBase
        .list(
          "bars/" + bar + "/plan/" + date.getFullYear() + "/" + date.getMonth()
        )
        .snapshotChanges()
    );
  }

  removeShiftSchedule(bar, day) {
    const date = new Date(day.date);
    ////console.log('clearning from ' + bar, date, 'bars/' + bar + '/schedule/plan/'  + date.getFullYear()+ "/" +date.getMonth() + "/" + date.getTime() )
    this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/schedule/plan/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          date.getTime()
      )
      .remove();
  }

  setStaffTarget(s, target) {
    return new Promise((res, rej) => {
      if (Number.isInteger(target)) {
        this.staffAppBase
          .object("staff/" + s.uid + "/target")
          .set(target)
          .then((a) => {
            return res(true);
          })
          .catch((e) => {
            return rej(e.toString());
          });
      } else {
        return rej("Not a number");
      }
    });
  }

  setStaffVariable(vari, s, target) {
    return new Promise((res, rej) => {
      this.staffAppBase
        .object("staff/" + s.uid + "/" + vari)
        .set(target)
        .then((a) => {
          return res(true);
        })
        .catch((e) => {
          return rej(e.toString());
        });
    });
  }

  setStaffRole(s, target) {
    return new Promise((res, rej) => {
      if (Number.isInteger(target)) {
        this.staffAppBase
          .object("staff/" + s.uid + "/role")
          .set(target)
          .then((a) => {
            return res(true);
          })
          .catch((e) => {
            return rej(e.toString());
          });
      } else {
        return rej("Not a number");
      }
    });
  }

  async getHourReviews(bar) {
    return this.staffAppBase
      .list("bars/" + bar + "/records/hourReviews", (ref) => ref.limitToLast(5))
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async getStockForBar(bar) {
    return this.staffAppBase
      .list("bars/" + bar + "/stock")
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  setHourReview(bar, review) {
    return new Promise((res, rej) => {
      if (bar && review && review.timeOfReview) {
        return this.staffAppBase
          .list("bars/" + bar + "/records/hourReviews")
          .set(review.timeOfReview + "", review)
          .then((a) => {
            return res(null);
          })
          .catch((e) => {
            return rej(e);
          });
      } else {
        return rej("missing data");
      }
    });
  }

  //-----------------_Expenses

  async getFinishedMonth(bar, date: Date) {
    return this.staffAppBase
      .object(
        "bars/" +
          bar +
          "/records/kostenoverview/" +
          date.getFullYear() +
          "/" +
          date.getMonth()
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }
  async getOneOffExpenses(bar, date: Date) {
    return this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/records/costs/" +
          date.getFullYear() +
          "/" +
          date.getMonth()
      )
      .valueChanges()
      .pipe(
        take(1),
        map((a: any) => a.map((b) => new OneOffExpense(b)))
      )
      .toPromise();
  }

  async getRecurringExpenses(bar) {
    return this.staffAppBase
      .list("bars/" + bar + "/expenses")
      .valueChanges()
      .pipe(
        take(1),
        map((a: any) => a.map((b) => new RecurringExpense(b)))
      )
      .toPromise();
  }

  async getOrderExpenses(bar, date: Date) {
    return this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/records/orders/" +
          date.getFullYear() +
          "/" +
          date.getMonth()
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async getInOut(bar, date: Date) {
    return this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/records/cashinout/" +
          date.getFullYear() +
          "/" +
          date.getMonth()
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async setRecurringExpense(bar, expense) {
    var key = expense.key;
    if (!key) {
      key = await this.staffAppBase
        .list("bars/" + bar + "/expenses")
        .push({})
        .then((a) => a.key)
        .catch((e) => {
          //console.log(e);
          return null;
        });
      if (!key) {
        return false;
      }
    }
    expense.key = key;
    return await this.staffAppBase
      .list("bars/" + bar + "/expenses")
      .set(key, expense);
  }

  async setOneOffExpense(bar, date, expense) {
    date = new Date(expense.date);
    var key = expense.key;
    if (!key) {
      key = await this.staffAppBase
        .list(
          "bars/" +
            bar +
            "/records/costs/" +
            date.getFullYear() +
            "/" +
            date.getMonth()
        )
        .push({})
        .then((a) => a.key)
        .catch((e) => {
          //console.log(e);
          return null;
        });
      if (!key) {
        return false;
      }
    }

    expense.key = key;
    return await this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/records/costs/" +
          date.getFullYear() +
          "/" +
          date.getMonth()
      )
      .set(key, expense);
  }

  async deleteRecurringExpense(bar, expense) {
    if (expense.key) {
      return await this.staffAppBase
        .list("bars/" + bar + "/expenses")
        .remove(expense.key);
    }
  }

  async deleteOneOffExpense(bar, date, expense) {
    if (expense.key) {
      return await this.staffAppBase
        .list(
          "bars/" +
            bar +
            "/records/costs/" +
            date.getFullYear() +
            "/" +
            date.getMonth()
        )
        .remove(expense.key);
    }
  }

  /* ----------- Surveys ------------*/

  async getSurveys() {
    return await this.staffAppBase
      .list("reviews/")
      .snapshotChanges()
      .pipe(
        take(1),
        map((a: any) =>
          a.map((b) => {
            const d = { key: b.payload.key, value: b.payload.val() };
            return d.value;
          })
        )
      )
      .toPromise();
  }

  async getSurveyAnswers(key) {
    if (!key) {
      throw new Error("no key");
    }
    return await this.staffAppBase
      .list("reviewAnswers/" + key)
      .snapshotChanges()
      .pipe(
        take(1),
        map((a: any) =>
          a.map((b) => {
            const d = { key: b.payload.key, value: b.payload.val() };
            return d;
          })
        )
      )
      .toPromise();
  }

  async saveSurvey(survey: Survey) {
    survey.answers = [];
    if (!survey.key) {
      const key = await this.staffAppBase
        .list("reviews")
        .push({})
        .then((a) => a.key)
        .catch(null);
      if (!key) {
        throw new Error("Error assigning key");
      }
      survey.key = key;
    }

    return await this.staffAppBase
      .list("reviews")
      .set(survey.key, survey.getObject());
  }

  async deleteSurvey(survey: Survey) {
    if (!survey.key) {
      throw new Error("Cant delete a survey that doesnt have a key");
    }
    return await this.staffAppBase.object("reviews/" + survey.key).remove();
  }

  async notifySurvey(survey: Survey) {
    return await this.functions
      .httpsCallable("triggerSurveyNotification")({
        bar: survey.bar,
        title: "New Survey to Complete",
        message: "Please complete " + survey.title + "!",
        users: survey.users,
      })
      .pipe(take(3))
      .toPromise();
  }

  async getComputers(bar: string) {
    return await this.staffAppBase
      .list("computers/" + bar + "/info")
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  getUsage(pcName: string, bar: string) {
    return this.staffAppBase
      .list("computers/" + bar + "/usage/" + pcName, (ref) =>
        ref.orderByKey().limitToLast(10)
      )
      .snapshotChanges()
      .pipe(
        debounceTime(500),
        map((actions) =>
          actions.map((a) => ({ key: a.payload.key, value: a.payload.val() }))
        )
      );
  }

  //Working
  //follow working bars/barname/working/{{yyyy-MM-dd}}
  //get worked bars/barname/workedRecords/{{yyyy-MM-dd}}/{{uid}}

  async getWorking(bar: string, date: Date) {
    return await this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/working/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          date.getDate()
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async getWorked(bar: string, date: Date, uid: string) {
    return await this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/workedRecords/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          date.getDate() +
          "/" +
          uid
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async getWorkedAll(bar: string, date: Date) {
    return await this.staffAppBase
      .list(
        "bars/" +
          bar +
          "/workedRecords/" +
          date.getFullYear() +
          "/" +
          date.getMonth() +
          "/" +
          date.getDate()
      )
      .snapshotChanges()
      .pipe(
        take(1),
        map((a) =>
          a.map((b) => {
            var data = b.payload.val() as any;
            return { uid: b.payload.key, ...data };
          })
        )
      )
      .toPromise();
  }

  async getTutorials() {
    return await this.staffAppBase
      .list("bars/" + this.bar + "/tutorials")
      .valueChanges()
      .pipe(
        take(1),
        map((a) => {
          return a.map((b) => {
            return b as Tutorial;
          });
        })
      )
      .toPromise();
  }

  getTypesTutorials() {
    return this.staffAppBase.list("types/tutorials").valueChanges();
  }

  async getTutorialVideo(tutorial) {
    var videos = await this.storage
      .ref("bars/" + this.bar + "/tutorials/")
      .listAll()
      .pipe(
        take(1),
        map((a) =>
          a.items.filter((b) => {
            return b.name.includes(tutorial.ref);
          })
        )
      )
      .toPromise();
    if (videos.length > 0) {
      return await videos[0].getDownloadURL();
    }

    return "n/a";
  }

  async uploadTutorialVideo(tutorial: Tutorial, video: File) {
    try {
      await new Promise((res, rej) => {
        this.storage
          .ref("bars/" + this.bar + "/tutorials/" + tutorial.ref)
          .put(video)
          .percentageChanges()
          .subscribe((a) => {
            this.loading.nextEmit("on", a);
            if (a >= 100) {
              this.loading.nextEmit(null);
              res(true);
            }
          });
      });
      return null;
    } catch (e) {
      //console.log(e);
      return { error: e, message: "Error uploading video" };
    }
  }

  async deleteTutorial(tutorial) {
    //first delete video and inform
    //then delete tutorial
    try {
      var videos = await this.storage
        .ref("bars/" + this.bar + "/tutorials/")
        .listAll()
        .pipe(
          take(1),
          map((a) =>
            a.items.filter((b) => {
              return b.name.includes(tutorial.ref);
            })
          )
        )
        .toPromise();
      if (videos.length > 0) {
        await videos[0].delete();
      }

      await this.staffAppBase
        .list("bars/" + this.bar + "/tutorials/")
        .remove(tutorial.ref);
      return null;
    } catch (e) {
      //console.log(e);
      return { error: e, message: "Error deleting video" };
    }
  }

  async saveTutorial(tutorial: Tutorial) {
    try {
      await this.staffAppBase
        .list("bars/" + this.bar + "/tutorials/")
        .set(tutorial.ref, tutorial);
      return null;
    } catch (e) {
      console.error(e);
      return { error: e, message: "Error saving tutorial" };
    }
  }

  async saveNDHOStock(stock: NDHOStock[]) {
    var errors = [];
    try {
      for (var s of stock) {
        errors.push(await this.saveNDHOStockItem(s));
      }
    } catch (e) {
      console.error(e);
      return { error: e, message: "Error saving HO stock" };
    }
  }

  async saveNDHOStockOrder(stock: NDHOStock[]) {
    var errors = [];
    try {
      for (var s of stock) {
        var e = await this.saveNDHOStockItemOrder(s);
        if (e.error) {
          errors.push(e);
        }
      }
      return { error: errors, message: errors.length + " errors" };
    } catch (e) {
      console.error(e);
      return { error: e, message: "Error saving HO stock" };
    }
  }
  async saveHOStock(stock: HOStock[]) {
    var errors = [];
    try {
      for (var s of stock) {
        errors.push(await this.saveHOStockItem(s));
      }
    } catch (e) {
      console.error(e);
      return { error: e, message: "Error saving HO stock" };
    }
  }

  async saveHOStockOrder(stock: HOStock[]) {
    var errors = [];
    try {
      for (var s of stock) {
        var e = await this.saveHOStockItemOrder(s);
        if (e.error) {
          errors.push(e);
        }
      }
      return { error: errors, message: errors.length + " errors" };
    } catch (e) {
      console.error(e);
      return { error: e, message: "Error saving HO stock" };
    }
  }

  async deleteNDHOStock(stock: NDHOStock) {
    try {
      await this.staffAppBase
        .list("bars/Daga Beheer/ndstock")
        .remove(stock.UID);
      return null;
    } catch (e) {
      console.error(e);
      return { error: e, message: "Error deleting HO stock" };
    }
  }
  async deleteHOStock(stock: HOStock) {
    try {
      await this.staffAppBase.list("bars/Daga Beheer/stock").remove(stock.UID);
      return null;
    } catch (e) {
      console.error(e);
      return { error: e, message: "Error deleting HO stock" };
    }
  }

  async getHOStock(): Promise<HOStock[]> {
    return await this.staffAppBase
      .list("bars/Daga Beheer/stock")
      .valueChanges()
      .pipe(
        take(1),
        map((a: any) => {
          return Object.values(a).map((c) => {
            //convert b to HOStock while keeping variables of both
            var b: HOStock = c as HOStock;
            var stock = new HOStock(b.id, b.name, b.type, b.ppu, b.singlePrice);

            return Object.assign(stock, b);
          });
        })
      )
      .toPromise();
  }

  async getNDHOStock(): Promise<NDHOStock[]> {
    return await this.staffAppBase
      .list("bars/Daga Beheer/ndstock")
      .valueChanges()
      .pipe(
        take(1),
        map((a: any) => {
          return Object.values(a).map((c) => {
            //convert b to HOStock while keeping variables of both
            var b: NDHOStock = c as NDHOStock;
            var stock = new NDHOStock(
              b.id,
              b.name,
              b.type,
              b.ppu,
              b.singlePrice
            );
            return Object.assign(stock, b);
          });
        })
      )
      .toPromise();
  }

  async saveHOStockItem(stock: HOStock) {
    try {
      //console.log("saving stock", stock);
      var error = await this.staffAppBase
        .list("bars/Daga Beheer/stock")
        .set(stock.UID, stock)
        .then((a) => null)
        .catch((e) => e);

      if (error) {
        return error;
      }

      for (var [bar, id] of Object.entries(stock.bars)) {
        //console.log(

        var og = await this.staffAppBase
          .object("bars/" + bar + "/stock/" + id.type + "/" + id.id)
          .valueChanges()
          .pipe(take(1))
          .toPromise();
        //console.log(og);
        if (!og) {
          //remove this from dagabeheer reference
          stock.bars[bar] = null;
          await this.staffAppBase
            .object("bars/Daga Beheer/stock/" + stock.UID + "/bars/" + bar)
            .remove();
          continue;
        }

        error = await this.staffAppBase
          .object(
            "bars/" + bar + "/stock/" + id.type + "/" + id.id + "/pricePerUnit"
          )
          .set(Number(stock.ppu))
          .then((a) => null)
          .catch((e) => e);
        if (error) {
          return error;
        }
        error = await this.staffAppBase
          .object(
            "bars/" + bar + "/stock/" + id.type + "/" + id.id + "/singlePrice"
          )
          .set(Number(stock.singlePrice))
          .then((a) => null)
          .catch((e) => e);
        if (error) {
          return error;
        }
        error = await this.staffAppBase
          .object(
            "bars/" + bar + "/stock/" + id.type + "/" + id.id + "/resalePerUnit"
          )
          .set(Number(stock.resalePerUnit))
          .then((a) => null)
          .catch((e) => e);
        if (error) {
          return error;
        }

        error = await this.staffAppBase
          .object("bars/" + bar + "/stock/" + id.type + "/" + id.id + "/units")
          .set(Number(stock.units))
          .then((a) => null)
          .catch((e) => e);
        if (error) {
          return error;
        }

        error = await this.staffAppBase
          .object(
            "bars/" + bar + "/stock/" + id.type + "/" + id.id + "/percentage"
          )
          .set(Number(stock.percentage))
          .then((a) => null)
          .catch((e) => e);
        if (error) {
          return error;
        }

        if (error) {
          return error;
        }
      }
      return null;
    } catch (e) {
      console.error(e);
      return { error: e, message: "Error saving HO stock" };
    }
  }

  async saveHOStockItemOrder(stock: HOStock) {
    try {
      //console.log("saving stock", stock);
      var error = await this.staffAppBase
        .list("bars/Daga Beheer/stock")
        .set(stock.UID, stock)
        .then((a) => null)
        .catch((e) => e);
      return {
        error: error,
        message: error ? "Error saving HO stock" : "Saved HO stock",
      };
    } catch (e) {
      console.error(e);
      return { error: e, message: "Error saving HO stock" };
    }
  }

  async saveNDHOStockItem(stock: NDHOStock) {
    try {
      //console.log("saving stock", stock);
      var error = await this.staffAppBase
        .list("bars/Daga Beheer/ndstock")
        .set(stock.UID, stock)
        .then((a) => null)
        .catch((e) => e);

      if (error) {
        return error;
      }

      for (var [bar, id] of Object.entries(stock.bars)) {
        //console.log("saving to:", "bars/" + bar + "/ndstock/" + id.id);
        var og = await this.staffAppBase
          .object("bars/" + bar + "/ndstock/" + id.id)
          .valueChanges()
          .pipe(take(1))
          .toPromise();
        //console.log(og);
        if (!og) {
          //remove this from dagabeheer reference
          stock.bars[bar] = null;
          await this.staffAppBase
            .object("bars/Daga Beheer/ndstock/" + stock.UID + "/bars/" + bar)
            .remove();
          continue;
        }

        error = await this.staffAppBase
          .object("bars/" + bar + "/ndstock/" + id.id + "/pricePerUnit")
          .set(Number(stock.ppu))
          .then((a) => null)
          .catch((e) => e);
        if (error) {
          return error;
        }

        error = await this.staffAppBase
          .object("bars/" + bar + "/ndstock/" + id.id + "/price")
          .set(Number(stock.ppu))
          .then((a) => null)
          .catch((e) => e);
        if (error) {
          return error;
        }
        error = await this.staffAppBase
          .object("bars/" + bar + "/ndstock/" + id.id + "/singlePrice")
          .set(Number(stock.singlePrice))
          .then((a) => null)
          .catch((e) => e);
        if (error) {
          return error;
        }
        error = await this.staffAppBase
          .object("bars/" + bar + "/ndstock/" + id.id + "/resalePerUnit")
          .set(Number(stock.resalePerUnit))
          .then((a) => null)
          .catch((e) => e);
        if (error) {
          return error;
        }

        error = await this.staffAppBase
          .object("bars/" + bar + "/ndstock/" + id.id + "/units")
          .set(Number(stock.units))
          .then((a) => null)
          .catch((e) => e);
        if (error) {
          return error;
        }

        error = await this.staffAppBase
          .object("bars/" + bar + "/ndstock/" + "/percentage")
          .set(Number(stock.percentage))
          .then((a) => null)
          .catch((e) => e);
        if (error) {
          return error;
        }

        if (error) {
          return error;
        }
      }
      return null;
    } catch (e) {
      console.error(e);
      return { error: e, message: "Error saving NDHO stock" };
    }
  }

  async saveNDHOStockItemOrder(stock: NDHOStock) {
    try {
      if (!stock || !stock.UID) {
        alert("no stock found to save: please tell duncan");
        return {
          error: "no stock found to save: please tell duncan",
          message: "no stock found to save: please tell duncan",
        };
      }
      //console.log("saving stock", stock);
      var error = await this.staffAppBase
        .list("bars/Daga Beheer/ndstock")
        .set(stock.UID, stock)
        .then((a) => null)
        .catch((e) => e);

      return {
        error: error,
        message: error ? "Error saving HO stock" : "Saved HO stock",
      };
    } catch (e) {
      console.error(e);
      return { error: e, message: "Error saving NDHO stock" };
    }
  }

  getDifferences(obj1: any, obj2: any) {
    var diff = [];
    try {
      for (var key in obj1) {
        if (obj1[key] !== obj2[key]) {
          diff.push({ key });
        }
      }
      return diff;
    } catch (e) {
      diff.push("error");
      return diff;
    }
  }
  async changedRecordOld(
    bar: string,
    uid: string,
    record: any,
    recordName: string,
    recordKey: string,
    action: string = "editing"
  ) {
    return this.changedRecord(
      bar,
      uid,
      record,
      recordName,
      recordKey,
      action,
      "theendadmin",
      "na",
      {}
    );
  }

  async changedRecord(
    bar: string,
    uid: string,
    record: any,
    recordName: string,
    recordKey: string,
    action: string = "editing",
    location: string,
    description: string,
    edits: any
  ) {
    var userName: string = (await this.getFullName(uid)) as string;
    var now = Date.now();
    var changeRecord = new ChangeRecord(
      uid,
      now,
      record,
      userName,
      bar,
      recordName,
      recordKey,
      action,
      location,
      description,
      edits
    );

    try {
      //save shorthand data

      var data = {
        date: now,
        dateString: changeRecord.dateString,
        userName: userName,
        uid: uid,
        bar: bar,
        recordName: recordName,
        recordKey: recordKey,
      };
      await this.staffAppBase
        .list("changes/short/" + recordName)
        .set(now + "", data);
    } catch (e) {}

    return await this.staffAppBase
      .list("changes/full/" + recordName)
      .set(now + "", changeRecord);
  }

  async getChangedRecords(
    bar: string,
    recordName: string,
    startDate: Date,
    endDate: Date
  ): Promise<ChangeRecord[]> {
    //console.log("changes/" + recordName);
    return await this.staffAppBase
      .list("changes/full/" + recordName, (ref) => {
        if (startDate && endDate && startDate < endDate) {
          return ref
            .orderByChild("date")
            .startAt(startDate.getTime())
            .endAt(endDate.getTime());
        } else {
          return ref.limitToLast(15);
        }
      })
      .valueChanges()
      .pipe(
        take(1),
        map((a: any) => {
          return Object.values(a).map((b) => {
            return b as ChangeRecord;
          });
        })
      )
      .toPromise();
  }

  async getShortRecords(
    bar: string,
    recordName: string,
    recordKey: string
  ): Promise<ChangeRecord[]> {
    //console.log("getting changed records: " + "changes/" + recordName);
    return await this.staffAppBase
      .list("changes/short/" + recordName, (ref) => {
        if (recordKey) {
          return ref
            .orderByChild("recordKey")
            .equalTo(recordKey)
            .limitToLast(15);
        } else {
          return ref.limitToLast(15);
        }
      })
      .valueChanges()
      .pipe(
        take(1),
        map((a: any) => {
          return Object.values(a).map((b) => {
            return b as ChangeRecord;
          });
        })
      )
      .toPromise();
  }

  async getRecord(record: ChangeRecord, bar: string) {
    return await this.staffAppBase
      .object("changes/full/" + record.recordName + "/" + record.date)
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  //shirts

  async addShirt(uid: string, shirt: Shirts) {
    if (!shirt.id) {
      throw new Error("No shirt id");
    }

    if (!uid) {
      throw new Error("No user id");
    }

    return await this.staffAppBase
      .list("inventory/shirts/" + uid)
      .set(shirt.id, shirt);
  }

  async removeShirt(uid: string, shirt: Shirts) {
    if (!shirt.id) {
      throw new Error("No shirt id");
    }

    if (!uid) {
      throw new Error("No user id");
    }
    return await this.staffAppBase
      .list("inventory/shirts/" + uid)
      .remove(shirt.id);
  }

  async getShirts(uid: string): Promise<Shirts[]> {
    if (!uid) {
      throw new Error("No user id");
    }
    return await this.staffAppBase
      .list("inventory/shirts/" + uid)
      .valueChanges()
      .pipe(
        take(1),
        map((a) => {
          return a as Shirts[];
        })
      )
      .toPromise();
  }

  async getPayments(startDate: Date, endDate: Date) {
    return await this.staffAppBase
      .list("bars/" + this.bar + "/ccvPayments", (ref) =>
        ref
          .orderByChild("date")
          .startAt(startDate.getTime())
          .endAt(endDate.getTime())
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  private stopPaymentsLive$ = new Subject<void>();
  getPaymentsLive(startDate: Date, endDate: Date) {
    return this.staffAppBase
      .list("bars/" + this.bar + "/ccvPayments", (ref) =>
        ref
          .orderByChild("date")
          .startAt(startDate.getTime())
          .endAt(endDate.getTime())
      )
      .valueChanges()
      .pipe(takeUntil(this.stopPaymentsLive$));
  }

  stopPaymentsLive() {
    this.stopPaymentsLive$.next(); // Emits to stop the stream
    this.stopPaymentsLive$.complete(); // Optional: completes the subject
  }

  async getShiftReport(bar: string, date: Date, endDate: Date) {
    var dates = [];
    if (
      date.getMonth() == endDate.getMonth() &&
      date.getFullYear() == endDate.getFullYear()
    ) {
      //console.log("getting 1 month");
      return await this.staffAppBase
        .list(
          "davesMomsReports/" +
            bar +
            "/" +
            date.getFullYear() +
            "/" +
            date.getMonth()
        )
        .valueChanges()
        .pipe(
          take(1),
          map((a) => a as ShiftReport[])
        )
        .toPromise();
    }

    if (date > endDate) {
      alert("start date is after end date");
      return dates;
    }

    while (this.notSameMonthAndFirstLess(date, endDate)) {
      var d: ShiftReport[] = await this.staffAppBase
        .list(
          "davesMomsReports/" +
            bar +
            "/" +
            date.getFullYear() +
            "/" +
            date.getMonth()
        )
        .valueChanges()
        .pipe(
          take(1),
          map((a) => a as ShiftReport[])
        )
        .toPromise();
      if (d) {
        dates = dates.concat(d);
      }

      date.setMonth(date.getMonth() + 1);
    }

    return dates;
  }

  notSameMonthAndFirstLess(date: Date, endDate: Date) {
    if (date.getFullYear() != endDate.getFullYear()) {
      return date.getFullYear() < endDate.getFullYear();
    }

    if (date.getMonth() != endDate.getMonth()) {
      return date.getMonth() < endDate.getMonth();
    }

    if (date.getMonth() > endDate.getMonth()) {
      return false;
    }

    return true;
  }

  async addBar(bar: Bar) {
    var errors = [];

    if (!bar.name) {
      errors.push("No bar id");
    }

    if (errors.length > 0) {
      throw new Error(errors.join(", "));
    }
    const error = await this.staffAppBase
      .list("barnames")
      .push(bar.name)
      .then((a) => null)
      .catch((e) => e);

    if (error) {
      errors.push(error);
    }

    const error2 = await this.staffAppBase
      .list("bars")
      .set(bar.name, bar)
      .then((a) => null)
      .catch((e) => e);

    if (error2) {
      errors.push(error2);
    }

    if (errors.length > 0) {
      throw new Error(errors.join(", "));
    }

    return null;
  }

  /* Outings and Outing Budgets */

  async getOutingBudgets(bar: string, year: number) {
    //bars/{{bar}}/records/outingbudget/{{year}}
    return this.staffAppBase
      .list("bars/" + bar + "/records/outingbudget/" + year)
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async getAllOutingBudgets(bar: string): Promise<OutingBudget[]> {
    return this.staffAppBase
      .list("bars/" + bar + "/records/outingbudget")
      .valueChanges()
      .pipe(
        take(1),
        map((a: any) => {
          return a.map((b) => {
            var budget = new OutingBudget();
            Object.assign(budget, b);
            return budget;
          });
        })
      )
      .toPromise();
  }

  async createOutingBudget(bar: string, year: number, budget: OutingBudget) {
    //check if budget exists
    var exists = await this.staffAppBase
      .object("bars/" + bar + "/records/outingbudget/" + year)
      .valueChanges()
      .pipe(take(1))
      .toPromise();
    if (exists) {
      throw new Error("Budget already exists");
    }

    return this.staffAppBase
      .object("bars/" + bar + "/records/outingbudget/" + year)
      .set(budget)
      .then((a) => null)
      .catch((e) => e);
  }

  async updateOutingBudget(budget: OutingBudget) {
    return this.staffAppBase
      .object("bars/" + budget.bar + "/records/outingbudget/" + budget.year)
      .set(budget)
      .then((a) => null)
      .catch((e) => e);
  }
}

export class ChangeRecord {
  uid: string;
  date: number;
  record: any;
  userName: string;
  dateString: string;
  bar: string;
  recordName: string;
  recordKey: string;
  action: string;
  location: string;
  description: string;
  data: any;
  edits: any;
  constructor(
    uid: string,
    date: number,
    record: any,
    userName: string,
    bar: string,
    recordName: string,
    recordKey: string,
    action: string,
    location: string = "",
    description: string = "",
    edits: any = {}
  ) {
    this.uid = uid;
    this.date = date;
    var d = new Date(date);
    this.bar = bar;
    this.recordKey = recordKey;
    this.recordName = recordName;
    this.action = action;
    //format yyyy-MM-dd HH:mm
    this.dateString = new Intl.DateTimeFormat("nl-NL", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(d);
    this.record = record;
    this.userName = userName;
    this.location = location;
    this.description = description;
    this.data = {};
    this.edits = edits;
  }
}

export class Tutorial {
  message: string;
  ref: string;
  title: string;
  type: string;
  portrait: boolean;
}
