import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IsMobileService {
  constructor() {}

  public isMobile(): boolean {
    return window.innerWidth < 768;
  }
}
