import { Injectable } from "@angular/core";
import { BarInfo } from "../Helpers/functions/BarInfo";
import {
  AngularFireDatabase,
  SnapshotAction,
} from "@angular/fire/compat/database";
import { firstValueFrom, map } from "rxjs";
import { CleaningTask } from "../Classes/cleaning-task";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { ListRange } from "@angular/cdk/collections";
import { ListResult } from "@angular/fire/compat/storage/interfaces";
import { User } from "firebase/auth";
import { start } from "repl";

@Injectable({
  providedIn: "root",
})
export class CleaningService {
  staff: User[] = [];
  constructor(
    private staffAppBase: AngularFireDatabase,
    public bi: BarInfo,
    private storage: AngularFireStorage
  ) {}

  async getCleaningTasks(): Promise<CleaningTask[]> {
    if (!this.bi.bar) {
      throw new Error("No bar found");
    }

    const ref = this.staffAppBase.list(`bars/${this.bi.bar}/cleaningTasks`);

    return await firstValueFrom(
      ref
        .valueChanges()
        .pipe(
          map((tasks) =>
            tasks.map((task) => Object.assign(new CleaningTask(), task))
          )
        )
    );
  }

  async getCleaningTaskRecords(
    cleaningTasks: CleaningTask[]
  ): Promise<ListResult[]> {
    var promises = [];
    for (let task of cleaningTasks) {
      promises.push(
        this.storage
          .ref(`cleaningTasks/${task.ref}`)
          .listAll()
          .pipe(
            map(async (a) => {
              var obj = {
                items: a.items.map((item) => {
                  return {
                    name: item.name,
                    date: new Date(Number(item.name)),
                    shiftDate: 0,
                    staff: [],
                  };
                }),
                key: task.ref,
              };

              for (var item of obj.items) {
                // calculate the shift date (if its before 6am, its the previous day)
                var shiftDate = new Date(item.date);
                if (shiftDate.getHours() < 6) {
                  shiftDate.setDate(shiftDate.getDate() - 1);
                }
                item.shiftDate = shiftDate.getTime();
              }
              obj.items = obj.items.sort((a, b) => {
                if (a.date > b.date) {
                  return -1;
                } else if (a.date < b.date) {
                  return 1;
                } else {
                  return 0;
                }
              });
              return obj;
            })
          )
          .toPromise()
      );
    }

    return await Promise.all(promises);
  }

  async getCleaningTaskDownloadURL(key, name) {
    return await firstValueFrom(
      this.storage.ref(`cleaningTasks/${key}/${name}`).getDownloadURL()
    );
  }

  async getStaffWorking(
    bar: string,
    shiftDate: Date,
    dateTime: Date,
    raw = false
  ): Promise<string[]> {
    // get the staff working on a specific date
    try {
      const ref: any[] = await firstValueFrom(
        this.staffAppBase
          .list(
            `bars/${bar}/hours/${shiftDate.getFullYear()}/${shiftDate.getMonth()}/${shiftDate.getDate()}`
          )
          .valueChanges()
      );

      if (raw) {
        return ref;
      }

      // so the ref is a list of working time objects (startTime=2000,endTime=330, name=staffName)
      // we need to check if the time is between the start and end time
      // if it is, we add the name to the list
      // if not, we skip it
      // if the time is between 0000 and 0600, we need to check the previous day
      var worked = [];
      for (var workRecord of ref) {
        var hourOfCleaning = shiftDate.getHours() * 100;
        if (hourOfCleaning < 600) {
          hourOfCleaning += 2400;
        }

        var startTime = workRecord.startTime;
        var endTime = workRecord.endTime;

        if (startTime < 600) {
          startTime += 2400;
        }

        if (endTime < 600) {
          endTime += 2400;
        }

        if (hourOfCleaning >= startTime && hourOfCleaning <= endTime) {
          worked.push({ id: workRecord.key, name: workRecord.name });
        }
        //is hour of cleaning between the start and end time
      }

      return worked;
    } catch (e) {
      console.error(e);
    }

    return [];
  }

  async getStaff(): Promise<any[]> {
    if (this.staff.length > 0) {
      return this.staff;
    }

    const ref = this.staffAppBase.list("staff", (ref) =>
      ref.orderByChild("bar").equalTo(this.bi.bar)
    );

    return await firstValueFrom(ref.valueChanges());
  }

  async deleteTask(cleaningTask: CleaningTask, bar: string) {
    if (!cleaningTask.ref) {
      throw new Error("No ref found");
    }

    if (!bar) {
      throw new Error("No bar found");
    }
    const ref = this.staffAppBase.list(`bars/${bar}/cleaningTasks`);
    return await ref.remove(cleaningTask.ref);
  }

  async saveTask(cleaningTask: CleaningTask, bar: string) {
    if (!bar) {
      throw new Error("No bar found");
    }

    if (!cleaningTask.ref) {
      throw new Error("No ref found");
    }
    const ref = this.staffAppBase.list(`bars/${bar}/cleaningTasks`);
    if (cleaningTask.ref) {
      return await ref.update(cleaningTask.ref, cleaningTask);
    } else {
      return await ref.push(cleaningTask);
    }
  }

  async getNewRef() {
    return this.staffAppBase.createPushId();
  }
}
