import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NgxPrintDirective } from "ngx-print";
declare var require: any;
import { read, utils, writeFile } from "xlsx";

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { el } from "date-fns/locale";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-pdfmaker",
  templateUrl: "./pdfmaker.component.html",
  styleUrls: ["./pdfmaker.component.css"],
})
export class PDFMakerComponent implements OnInit {
  ngOnInit(): void {}

  data;
  @Input("data") set dataSet(data: any) {
    console.log(data);
    this.data = data;
  }
  @Input("showEmbellage") showEmbellage = true;
  @Input() type = "";

  title = "htmltopdf";

  constructor(
    private renderer: Renderer2,
    private print: NgxPrintDirective,
    public modal: NgbActiveModal
  ) {}

  @ViewChild("pdfTable") pdfTable: ElementRef;

  async downloadAsPDF() {}

  exportExcel() {
    let element = document.getElementById("invoice-table");
    let element2 = document.getElementById("total-table");

    const invoice = utils.table_to_sheet(element);
    const total = utils.table_to_sheet(element2);

    let a = utils.sheet_to_json(invoice, { header: 1 });
    let b = utils.sheet_to_json(total, { header: 1 });

    let worksheet = utils.json_to_sheet(a, { skipHeader: true });
    const cols = utils.decode_range(worksheet["!ref"]).e.r + 3;
    console.log(cols);
    console.log(utils.decode_range(worksheet["!ref"]));
    if (this.showEmbellage) {
      const emballage = utils.table_to_sheet(
        document.getElementById("emballage-table")
      );
      let c = utils.sheet_to_json(emballage, { header: 1 });
      utils.sheet_add_json(worksheet, c, {
        origin: "A" + cols,
        skipHeader: true,
      });
    }

    utils.sheet_add_json(worksheet, b, {
      origin: "I" + cols,
      skipHeader: true,
    });

    const wb = utils.book_new();
    utils.book_append_sheet(wb, worksheet, "worksheet");
    writeFile(
      wb,
      this.type +
        "-" +
        this.data.client.name +
        "-" +
        this.data.information.date +
        ".xls"
    );
  }
}
