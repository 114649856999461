import { Injectable, NgZone } from "@angular/core";

import { AngularFireAuth } from "@angular/fire/compat/auth";
import { environment } from "../../environments/environment";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { AngularFireStorage } from "@angular/fire/compat/storage";

import {
  AngularFireFunctions,
  AngularFireFunctionsModule,
} from "@angular/fire/compat/functions";
import { getAuth } from "firebase/auth";
import { EVENT_MANAGER_PLUGINS } from "@angular/platform-browser";
import { ɵAngularFireSchedulers } from "@angular/fire";

@Injectable()
export class FirebaseFactoryService {}
export function AngularProject1(platformId: Object, zone: NgZone) {
  return new AngularFireDatabase(
    environment.customerFirebase,
    "firebase-project1",
    null,
    platformId,
    zone,
    new ɵAngularFireSchedulers(zone),
    false,
    CustomerAppAuth(platformId, zone),
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );
}

export function AngularProject2(platformId: Object, zone: NgZone) {
  return new AngularFireDatabase(
    environment.staffFirebase,
    "firebase-project2",
    null,
    platformId,
    zone,
    new ɵAngularFireSchedulers(zone),
    false,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );
}

export function AngularAuthProject2(platformId: Object, zone: NgZone) {
  return new AngularFireAuth(
    environment.staffFirebase,
    "firebase-project2-auth",
    platformId,
    zone,
    new ɵAngularFireSchedulers(zone),
    null,
    false,
    null,
    null,
    null,
    null,
    null
  );
}

export function CustomerAppAuth(platformId: Object, zone: NgZone) {
  return new AngularFireAuth(
    environment.customerFirebase,
    "firebase-project1-auth",
    platformId,
    zone,
    new ɵAngularFireSchedulers(zone),
    false,
    null,
    null,
    null,
    true,
    null,
    null
  );
}

export function AngularStorage2(platformId: Object, zone: NgZone) {
  return new AngularFireStorage(
    environment.staffFirebase,
    "firebase-project2-storage",
    null,
    platformId,
    zone,
    new ɵAngularFireSchedulers(zone),
    null,
    null,
    null,
    null
  );
}

export function AngularStorage(platformId: Object, zone: NgZone) {
  return new AngularFireStorage(
    environment.customerFirebase,
    "firebase-project-storage",
    null,
    platformId,
    zone,
    new ɵAngularFireSchedulers(zone),
    null,
    null,
    null,
    null
  );
}

export function StaffAppFunctions(platformId: Object, zone: NgZone) {
  return new AngularFireFunctions(
    environment.staffFirebase,
    "firebase-project2-functions",
    zone,
    new ɵAngularFireSchedulers(zone),
    "europe-west3",
    null,
    false,
    null
  );
}

export function CustomerAppFunctions(platformId: Object, zone: NgZone) {
  return new AngularFireFunctions(
    environment.customerFirebase,
    "firebase-project1-functions",
    zone,
    new ɵAngularFireSchedulers(zone),
    "europe-west3",
    null,
    false,
    null
  );
}

export function StaffAppStore(platformId: Object, zone: NgZone) {
  return new AngularFireDatabase(
    environment.staffFirebase,
    "firebase-project2-store",
    null,
    platformId,
    zone,
    new ɵAngularFireSchedulers(zone),
    false,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );
}
