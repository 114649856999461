<div class="modal-title">
  <div class="action-side"></div>
  <h3 style="margin: 5px 0px 2px 0px">
    {{ device.name | capitalize }} - {{ device.serialNumber | capitalize }}
  </h3>
  <button
    class="action-side close-button red"
    (click)="activeModal.close('dismiss')"
  >
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="actions actions-header">
  <button
    class="reactive-button-title"
    (click)="tab = 0"
    [ngClass]="tab == 0 ? 'green' : ''"
  >
    General
  </button>
</div>

<ng-container *ngIf="tab == 0">
  <div class="edit-row">
    <div class="edit-desc">Server Id:</div>
    <div class="edit" *ngIf="!newDevice">
      {{ device.serialNumber }}
    </div>
    <input
      class="edit"
      *ngIf="newDevice"
      [(ngModel)]="device.serialNumber"
      name="serialNumber"
    />
  </div>
  <div class="edit-row">
    <div class="edit-desc">Name:</div>
    <input class="edit" [(ngModel)]="device.name" name="name" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Type</div>

    <select [(ngModel)]="device.deviceType">
      <option *ngFor="let level of types" [ngValue]="level">
        {{ level }}
      </option>
    </select>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Bar</div>

    <select [(ngModel)]="device.bar">
      <option *ngFor="let level of bars" [ngValue]="level">
        {{ level }}
      </option>
    </select>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Description:</div>
    <input class="edit" [(ngModel)]="device.description" name="name" />
  </div>

  <div class="edit-row" *ngIf="device.deviceType == 'Button'">
    <div class="edit-desc">Clicks:</div>
    <span class="edit" name="name"> {{ device.clicks }} </span>
  </div>
</ng-container>

<div class="actions actions-footer">
  <button
    class="reactive-button-title green"
    ngbTooltip=" Save will update, change category, correctly modify the order, only saves to the Database not the till!"
    (click)="saveTask(device)"
  >
    Save
  </button>

  <button
    class="reactive-button-title red"
    ngbTooltip=" Delete will only remove a drink from the Database not the till, i.e. if a drink is no longer available or has incorrect serverID you delete it, and can reimport it from the till drinks"
    (click)="deleteTask(device)"
  >
    Delete
  </button>
  <button
    class="reactive-button-title orange"
    (click)="activeModal.close('dismiss')"
  >
    Cancel
  </button>
</div>
