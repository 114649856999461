<link
  href="https://fonts.googleapis.com/icon?family=Material+Icons&display=block"
  rel="stylesheet"
/>
<div class="fab" (click)="makeBugReport()">
  <i class="fas fa-bug"></i>
</div>
<app-alerts style="position: absolute; bottom: 0; z-index: 50"></app-alerts>
<div
  class="header"
  *ngIf="router.url != '/login' && router.url != '/dynamicvoucher'"
>
  <button class="chevron-button" *ngIf="isMobile" (click)="hidden = !hidden">
    <i class="fas fa-bars"></i>
  </button>
  <button
    class="chevron-button"
    *ngIf="!isMobile"
    (click)="hiddenDesktop = !hiddenDesktop"
  >
    <i class="fas fa-bars"></i>
  </button>
  <img class="header-logo" src=".\assets\tea_logo.png" />

  <div class="router-splits">{{ bar }}</div>
  <div class="router-splits" *ngFor="let split of router.url.split('/')">
    <div *ngIf="split">/{{ split | capitalize }}</div>
  </div>

  <div style="flex-grow: 1"></div>

  <div class="header-access">
    <i
      [class]="
        'fas fa-' +
        (auth.accessUser.access >= accessRequired ? 'unlock' : 'lock')
      "
    ></i>
    {{ auth.accessUser.access }}/{{ accessRequired }}
  </div>

  <div routerLinkActive="true" routerLink="account" class="header-account">
    <i class="fad fa-user"></i>
  </div>
</div>

<div class="page" style="position: relative">
  <div
    class="action-bar"
    [style.width]="hiddenDesktop ? '40px' : '250px'"
    style="flex-shrink: 0; z-index: 2"
    *ngIf="
      router.url != '/dynamicvoucher' && auth.accessUser.access > 0 && !hidden
    "
  >
    <button
      [style.width]="hiddenDesktop ? '40px' : '250px'"
      class="action-button"
      (click)="hideView(true)"
      routerLinkActive="true"
      routerLink=""
      [class.selected]="router.url == '/'"
    >
      <i class="fas fa-map-signs"></i>
      <span *ngIf="!hiddenDesktop || isMobile"> Locatie</span>
    </button>
    <button
      [style.width]="hiddenDesktop ? '40px' : '250px'"
      *ngIf="auth.accessUser.access >= 2"
      class="action-button"
      (click)="hideView(true)"
      routerLinkActive="true"
      routerLink="controle"
      [class.selected]="router.url.includes('controle')"
    >
      <i class="fas fa-tasks-alt"></i>
      <span *ngIf="!hiddenDesktop || isMobile"> Controle</span>
    </button>
    <button
      [style.width]="hiddenDesktop ? '40px' : '250px'"
      *ngIf="auth.accessUser.access >= 3"
      class="action-button"
      (click)="hideView(true)"
      routerLinkActive="true"
      routerLink="inkomsten"
      [class.selected]="router.url.includes('inkomsten')"
    >
      <i class="fas fa-money-check-alt"></i>
      <span *ngIf="!hiddenDesktop || isMobile"> Inkomsten</span>
    </button>
    <button
      [style.width]="hiddenDesktop ? '40px' : '250px'"
      *ngIf="auth.accessUser.access >= 2"
      class="action-button"
      (click)="hideView(true)"
      routerLinkActive="true"
      routerLink="personeel"
      [class.selected]="router.url.includes('personeel')"
    >
      <i class="fad fa-users"></i>
      <span *ngIf="!hiddenDesktop || isMobile"> Personeel</span>
    </button>
    <button
      [style.width]="hiddenDesktop ? '40px' : '250px'"
      *ngIf="auth.accessUser.access >= 3"
      class="action-button"
      (click)="hideView(true)"
      routerLinkActive="true"
      routerLink="bestellingen"
      [class.selected]="router.url.includes('bestellingen')"
    >
      <i class="fas fa-truck-couch"></i>
      <span *ngIf="!hiddenDesktop || isMobile"> Bestellingen</span>
    </button>

    <button
      [style.width]="hiddenDesktop ? '40px' : '250px'"
      *ngIf="auth.accessUser.access >= 2"
      class="action-button"
      (click)="hideView(true)"
      routerLinkActive="true"
      routerLink="onderhoud"
      [class.selected]="router.url.includes('onderhoud')"
    >
      <i class="fas fa-wrench"></i>
      <span *ngIf="!hiddenDesktop || isMobile"> Onderhoud</span>
    </button>
    <button
      [style.width]="hiddenDesktop ? '40px' : '250px'"
      *ngIf="auth.accessUser.access >= 2"
      class="action-button"
      (click)="hideView(true)"
      routerLinkActive="true"
      routerLink="instellingen"
      [class.selected]="router.url.includes('instellingen')"
    >
      <i class="fas fa-cog"></i>
      <span *ngIf="!hiddenDesktop || isMobile"> Instellingen</span>
    </button>
    <button
      [style.width]="hiddenDesktop ? '40px' : '250px'"
      *ngIf="auth.accessUser.access >= 1"
      class="action-button"
      (click)="hideView(true)"
      routerLinkActive="true"
      routerLink="tools"
      [class.selected]="router.url.includes('tools')"
    >
      <i class="far fa-tools"></i>
      <span *ngIf="!hiddenDesktop || isMobile"> Tools</span>
    </button>
    <button
      [style.width]="hiddenDesktop ? '40px' : '250px'"
      class="action-button"
      (click)="hideView(true)"
      routerLinkActive="true"
      routerLink="customerapp"
      [class.selected]="router.url.includes('customerapp')"
    >
      <i class="fas fa-microphone"></i>
      <span *ngIf="!hiddenDesktop || isMobile"> Customer App</span>
    </button>
    <button
      [style.width]="hiddenDesktop ? '40px' : '250px'"
      class="action-button"
      (click)="hideView(true)"
      *ngIf="auth.accessUser.access >= 6"
      routerLinkActive="true"
      routerLink="testing"
      [class.selected]="router.url.includes('testing')"
    >
      <i class="fas fa-vial"></i>
      <span *ngIf="!hiddenDesktop || isMobile">Testing</span>
    </button>

    <!--
     <button
      class="action-button"
      (click)="hideView(true)"
      *ngIf="auth.accessUser.access >= 3"
      routerLinkActive="true"
      routerLink="dynamicvoucher"
      [class.selected]="router.url.includes('dynamicvoucher')"
    >
      Dynamic Voucher
    </button>-->
    <button
      [style.width]="hiddenDesktop ? '40px' : '250px'"
      class="action-button"
      (click)="hideView(true)"
      routerLinkActive="true"
      routerLink="account"
      [class.selected]="router.url.includes('account')"
    >
      <i class="fas fa-user"></i>
      <span *ngIf="!hiddenDesktop || isMobile"> Account</span>
    </button>
  </div>
  <div class="page-content">
    <router-outlet></router-outlet>
  </div>

  <app-map
    style="transition: all 0.5s ease-out"
    [style.width]="showMap ? fullWidth : '0'"
    (countryChosen)="
      !(!auth.accessUser || auth.accessUser.access <= 3) &&
      !(barInfo.bar === $event)
        ? barInfo.setBar($event)
        : null
    "
  ></app-map>
</div>
