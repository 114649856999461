import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CleaningTask } from "src/app/Classes/cleaning-task";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { CleaningService } from "src/app/Services/cleaning.service";
import { LoadingService } from "src/app/Services/loading.service";

@Component({
  selector: "app-cleaning-modal",
  templateUrl: "./cleaning-modal.component.html",
  styleUrls: ["./cleaning-modal.component.css"],
})
export class CleaningModalComponent implements OnInit {
  @Input("task") task: CleaningTask;
  tab: number = 0;
  frequencies = [0, 1, 2, 3, 4, 5, 6];
  priority = [-1, 0, 1, 2];
  days = [
    { id: 1, name: "Monday", checked: false },
    { id: 2, name: "Tuesday", checked: false },
    { id: 3, name: "Wednesday", checked: false },
    { id: 4, name: "Thursday", checked: false },
    { id: 5, name: "Friday", checked: false },
    { id: 6, name: "Saturday", checked: false },
    { id: 0, name: "Sunday", checked: false },
  ];
  constructor(
    public activeModal: NgbActiveModal,
    public cleaning: CleaningService,
    public alert: AlertsService,
    public loading: LoadingService,
    public bar: BarInfo
  ) {}

  ngOnInit(): void {
    this.days.forEach((day) => {
      if (this.task.days.includes(String(day.id))) {
        day.checked = true;
      }
    });
  }

  saveTask() {
    console.log(this.task);
    this.loading.show();
    this.task.days = this.days
      .filter((day) => day.checked)
      .map((day) => String(day.id))
      .join("");
    this.cleaning
      .saveTask(this.task, this.bar.bar)
      .then(() => {
        this.loading.hide();
        this.activeModal.close("save");
      })
      .catch((err) => {
        this.loading.hide();
        this.alert.nextEmit(AlertsService.error("Error saving task", err));
      });
  }

  deleteTask() {
    if (confirm("Are you sure you want to delete this task?")) {
      this.loading.show();
      this.cleaning
        .deleteTask(this.task, this.bar.bar)
        .then(() => {
          this.loading.hide();
          this.activeModal.close("save");
        })
        .catch((err) => {
          this.loading.hide();
          this.alert.nextEmit(AlertsService.error("Error deleting task", err));
        });
    }
  }
}
