<div class="calendar-container">
  <div class="calendar-header">
    <button (click)="previousMonth()">&#8592;</button>
    <h2>{{ currentMonth }} {{ currentYear }}</h2>
    <button (click)="nextMonth()">&#8594;</button>
  </div>
  <div class="calendar-grid">
    <div class="day-name" *ngFor="let day of weekDays">{{ day }}</div>
    <div
      class="day"
      *ngFor="let day of daysInMonth; let i = index"
      (click)="selectDate(day.day)"
      [class.weekend]="day?.isWeekend"
      [class.empty]="day === null"
    >
      <ng-container *ngIf="day != null">
        <div class="day-row">
          <label *ngIf="isMobile.isMobile()"
            >{{ day.dayName }} {{ day.day }}</label
          >
          <h3
            *ngIf="!isMobile.isMobile()"
            style="margin: 0; font-size: xx-large"
          >
            {{ day.day }}
          </h3>
          <i *ngIf="day.contains" class="fas fa-shirt" style="color: black"></i>
          <i *ngIf="day.contains" class="fas fa-user-clock"></i>
          <i
            *ngIf="day.contains"
            class="fas fa-wine-bottle"
            style="color: rgb(211, 12, 72)"
          ></i>
          <i
            *ngIf="day.contains"
            class="fa-solid fa-cash-register"
            style="color: green"
          ></i>
        </div>

        <div class="day-row">
          <i *ngIf="day.contains" class="fas fa-sun" style="color: gold"></i>
          <i
            [style.color]="day.shift2?.result?.startStatus | statusColor"
            [class]="day.shift2?.result?.startStatus | statusIcon"
          ></i>
          <i
            [style.color]="day.shift?.result?.startStatus | statusColor"
            [class]="day.shift?.result?.startStatus | statusIcon"
          ></i>
          <i
            [style.color]="day.stock?.result?.startStatus | statusColor"
            [class]="day.stock?.result?.startStatus | statusIcon"
          ></i>
          <i
            [style.color]="day.till?.result?.startStatus | statusColor"
            [class]="day.till?.result?.startStatus | statusIcon"
          ></i>
        </div>

        <div class="day-row">
          <i
            *ngIf="day.contains"
            class="fas fa-moon"
            style="color: rgb(117, 116, 116)"
          ></i>
          <i class="empty-i"></i>
          <i class="empty-i"></i>
          <i
            [style.color]="day.stock?.result?.endStatus | statusColor"
            [class]="day.stock?.result?.endStatus | statusIcon"
          ></i>
          <i
            [style.color]="day.till?.result?.endStatus | statusColor"
            [class]="day.till?.result?.endStatus | statusIcon"
          ></i>
        </div>
      </ng-container>
    </div>
  </div>
</div>
