import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { OGCMapTile, OSM } from "ol/source";
import TileLayer from "ol/layer/Tile";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import { Feature } from "ol";
import Style from "ol/style/Style";
import Text from "ol/style/Text";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import VectorLayer from "ol/layer/Vector"; // for the vector layer
import VectorSource from "ol/source/Vector"; // for the vector source
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { FeatureLike } from "ol/Feature";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.css"],
})
export class MapComponent implements OnInit {
  bar: any;
  private clickedFeature: Feature | null = null;
  //groningen, amsterdam, tilburg, zwolle, arnhem, duivendrecht, newcastle
  bars: any[] = [
    { name: "Groningen", location: [6.5663, 53.2194] },
    { name: "Amsterdam", location: [5.295168, 52.470216] },
    { name: "Tilburg", location: [5.0913, 51.5555] },
    { name: "Zwolle", location: [6.0944, 52.5167] },
    { name: "Arnhem", location: [5.8987, 51.9851] },
    { name: "Daga Beheer", location: [4.9306, 52.3328] },
  ];

  lastBar: string;

  public map: Map;
  @Output("countryChosen") county = new EventEmitter();

  constructor(public bi: BarInfo, public auth: Auth) {}

  ngOnInit(): void {
    console.log("Map component loaded", this.bars);
    var vectorLayers = [];
    this.lastBar = this.bi.bar;
    for (var i = 0; i < this.bars.length; i++) {
      var b = this.bars[i];
      var bFeature = new Feature({
        geometry: new Point(fromLonLat(b.location)),
        name: b.name,
      });
      var bStyle;
      if (b.name == this.bi.bar) {
        bStyle = new Style({
          text: new Text({
            text: b.name,
            font: "bold 16px Arial",
            fill: new Fill({ color: "red" }), // Change to red text for example
            stroke: new Stroke({ color: "white", width: 3 }),
            offsetY: -20,
          }),
        });
      } else {
        bStyle = new Style({
          text: new Text({
            text: b.name,
            font: "bold 14px Arial",
            fill: new Fill({ color: "black" }),
            stroke: new Stroke({ color: "white", width: 3 }),
            offsetY: -20, // Adjust label's position
          }),
        });
      }

      bFeature.setStyle(bStyle);
      var bLayer = new VectorLayer({
        source: new VectorSource({
          features: [bFeature], // Add the Groningen feature
        }),
      });
      if (b.name == this.bi.bar) {
        this.clickedFeature = bFeature;
      }
      vectorLayers.push(bLayer);
    }

    //watch this.bi.bar for changes

    this.bi.barObserver.subscribe((bar) => {
      // bar changed: update the style of the feature
      console.log("Bar changed", bar);
      if (bar == this.lastBar) return;
      this.lastBar = bar;
      var feature = vectorLayers.find((layer) => {
        return layer.getSource().getFeatures()[0].get("name") == bar;
      });
      if (feature) {
        console.log("Feature found", feature);
        this.clicked(feature.getSource().getFeatures()[0]);
      }
    });

    this.map = new Map({
      layers: [
        new TileLayer({
          source: new OGCMapTile({
            url: "https://maps.gnosis.earth/ogcapi/collections/blueMarble/map/tiles/WebMercatorQuad",
          }),
        }),
        ...vectorLayers,
      ],
      controls: [],
      target: "map",
      view: new View({
        center: fromLonLat([5.676012, 52.277716]),
        zoom: 7.8,
      }),
      interactions: [],
    });

    this.map.on("click", (evt) => {
      console.log(evt);
      var feature = this.map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          return feature;
        }
      );
      if (feature) {
        console.log(feature.get("name"));
        this.clicked(feature as Feature);
      }
    });

    this.map.on("pointermove", (evt) => {
      var feature = this.map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          return feature;
        }
      );
      if (feature && feature.get("name")) {
        this.hovering(feature as Feature);
      } else {
        this.hovering(null);
      }
    });
  }

  lastFeature: Feature;
  hovering(feature) {
    if (this.lastFeature) {
      if (this.lastFeature.get("name") != this.bi.bar) {
        this.lastFeature.setStyle(this.getNormalStyle(this.lastFeature));
      } else {
        this.lastFeature.setStyle(this.getSelectedStyle(this.lastFeature));
      }
    }
    if (!feature) {
      return;
    }

    feature.setStyle(this.getHoverStyle(feature));
    this.lastFeature = feature;
  }

  getHoverStyle(feature: Feature): Style {
    return new Style({
      text: new Text({
        text: feature.get("name"),
        font: "bold 16px Arial",
        fill: new Fill({ color: "blue" }), // Change to red text for example
        stroke: new Stroke({ color: "white", width: 3 }),
        offsetY: -20,
      }),
    });
  }

  getNormalStyle(feature: Feature): Style {
    return new Style({
      text: new Text({
        text: feature.get("name"),
        font: "bold 14px Arial",
        fill: new Fill({ color: "black" }),
        stroke: new Stroke({ color: "white", width: 3 }),
        offsetY: -20, // Adjust label's position
      }),
    });
  }

  getSelectedStyle(feature: Feature): Style {
    return new Style({
      text: new Text({
        text: feature.get("name"),
        font: "bold 16px Arial",
        fill: new Fill({ color: "red" }), // Change to red text for example
        stroke: new Stroke({ color: "white", width: 3 }),
        offsetY: -20,
      }),
    });
  }

  clicked(feature: Feature): void {
    // Change style of the clicked feature
    const selectedStyle = this.getSelectedStyle(feature);

    // If a previous feature was clicked, reset its style
    if (this.clickedFeature) {
      const previousStyle = new Style({
        text: new Text({
          text: this.clickedFeature.get("name"),
          font: "bold 14px Arial",
          fill: new Fill({ color: "black" }), // Original style
          stroke: new Stroke({ color: "white", width: 3 }),
          offsetY: -20,
        }),
      });
      this.clickedFeature.setStyle(previousStyle);
    }

    if (!feature) return;

    // Set the new style for the clicked feature
    feature.setStyle(selectedStyle);

    // Update the clickedFeature variable
    this.clickedFeature = feature;

    if (this.auth.accessUser.access > 3) this.bi.setBar(feature.get("name"));
  }
}
