<div class="flex-rows">
  <div class="flex-columns">
    <h1>Options</h1>

    <app-my-calendar></app-my-calendar>
  </div>

  <div class="flex-columns">
    <h1>Counted</h1>
    <h3>
      {{ selectedDate | date : " LLL yyyy - EEE dd" }}
    </h3>
    <button *ngIf="selectedDate" (click)="getTill()">
      Refresh Till Totals
    </button>
    <div *ngIf="startValues">
      <h3>Start</h3>
      <table class="table">
        <tr class="table-row">
          <th class="table-column">Aim</th>
          <th class="table-column">Actual</th>
          <th class="table-column">+/-</th>
        </tr>
        <tr class="table-row">
          <th class="table-column">{{ startValues.original.toFixed(2) }}</th>
          <th class="table-column">{{ startValues.updated.toFixed(2) }}</th>
          <th class="table-column">{{ startValues.difference.toFixed(2) }}</th>
        </tr>
      </table>
    </div>
    <div class="divider"></div>

    <div>
      <h3>End</h3>

      <table class="table">
        <tr class="table-row">
          <th class="table-column" style="width: 40%">Pin Till</th>
          <th class="table-column">Pin Tablet</th>
        </tr>
        <tr class="table-row">
          <th class="table-column">{{ expected.pin.toFixed(2) }}</th>
          <th class="table-column">
            {{ endValues ? endValues.realPin.toFixed(2) : "na" }}
          </th>
        </tr>
      </table>

      <table class="table">
        <tr class="table-row">
          <th class="table-column" style="width: 40%">Cash Till</th>
          <th class="table-column">Cash Tablet</th>
        </tr>
        <tr class="table-row">
          <th class="table-column">{{ expected.cash.toFixed(2) }}</th>
          <th class="table-column">
            {{ endValues ? endValues.realCash.toFixed(2) : "na" }}
          </th>
        </tr>
      </table>

      <table class="table">
        <tr class="table-row">
          <th class="table-column" style="width: 40%">Internet</th>
          <th class="table-column">Staff Drinks</th>
          <th class="table-column">Other</th>
        </tr>
        <tr class="table-row">
          <th class="table-column">{{ expected.internet.toFixed(2) }}</th>
          <th class="table-column">{{ expected.staffDrinks.toFixed(2) }}</th>
          <th class="table-column">{{ expected.other.toFixed(2) }}</th>
        </tr>
      </table>
    </div>
  </div>

  <div class="flex-columns">
    <h1>Incorrect Info</h1>
    <h3>Worked</h3>
    <div *ngIf="worked && worked.length != 0">
      <table class="table">
        <tr class="table-row">
          <th class="table-column" style="width: 40%">Name</th>
          <th class="table-column">Start</th>
          <th class="table-column">End</th>
          <th class="table-column">Total</th>
        </tr>
        <tr class="table-row" *ngFor="let s of worked">
          <th class="table-column">{{ s.name }}</th>
          <th class="table-column">{{ s.startTime }}</th>
          <th class="table-column">{{ s.endTime }}</th>
          <th class="table-column">{{ s.total.toFixed(2) }}</th>
        </tr>
      </table>
    </div>
  </div>
</div>
