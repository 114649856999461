import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Device } from "src/app/Classes/device";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { SensorService } from "src/app/Services/sensor.service";

@Component({
  selector: "app-sensor-modal",
  templateUrl: "./sensor-modal.component.html",
  styleUrls: ["./sensor-modal.component.scss"],
})
export class SensorModalComponent {
  @Input("device") device: Device;
  @Input("newDevice") newDevice: boolean = false;
  tab: number = 0;
  types = ["Temperature", "Decibels", "Door", "Button", "Other"];
  bars: String[] = [];
  constructor(
    public activeModal: NgbActiveModal,
    public alert: AlertsService,
    public sensors: SensorService,
    public loading: LoadingService,
    public bar: BarInfo
  ) {
    this.bars = this.bar.filteredBars;
  }

  ngOnInit(): void {}

  saveTask(device: Device) {
    console.log(device);
    this.loading.show();
    this.sensors
      .saveDevice(device)
      .then(() => {
        this.loading.hide();
        this.alert.nextEmit(AlertsService.good("Device saved"));
        this.activeModal.close();
      })
      .catch((error) => {
        this.loading.hide();
        this.alert.nextEmit(AlertsService.error(error));
      });
  }

  deleteTask(device: Device) {
    this.loading.show();
    this.sensors
      .deleteDevice(device)
      .then(() => {
        this.loading.hide();
        this.alert.nextEmit(AlertsService.good("Device deleted"));
        this.activeModal.close();
      })
      .catch((error) => {
        this.loading.hide();
        this.alert.nextEmit(AlertsService.error(error));
      });
  }
}
