<div class="page-chooser">
  <button
    class="page-chooser-button"
    [class.active]="page == 1"
    (click)="this.page = 1; viewRecords = null; staffSelected = null"
  >
    List
  </button>
  <button
    class="page-chooser-button"
    [class.active]="page == 2"
    (click)="this.page = 2; viewRecords = null; staffSelected = null"
  >
    Weekly
  </button>
  <button
    class="page-chooser-button"
    [class.active]="page == 3"
    (click)="this.page = 3; viewRecords = null; staffSelected = null"
  >
    Records
  </button>

  <button
    class="page-chooser-button"
    [class.active]="page == 4"
    (click)="
      this.page = 4;
      viewRecords = null;
      staffSelected = null;
      calculateStaffCleaning()
    "
  >
    Staff
  </button>

  <button
    class="page-chooser-button"
    [class.active]="page == 4"
    (click)="this.page = 1; this.createTask()"
  >
    Create
  </button>
</div>

<div class="pages">
  <div
    class="page-container"
    [style.width]="page == 1 ? '100%' : '300px !important'"
    *ngIf="page != 4"
  >
    <div class="my-table-header" *ngIf="page == 1">
      <div class="stock-item-large" (click)="setSortBy('title')">
        <div>Name</div>
        <i
          [style.display]="sortBy.key == 'title' ? 'flex' : 'none'"
          [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
        ></i>
      </div>
      <div
        *ngIf="page == 1"
        class="stock-item-imp"
        (click)="setSortBy('level')"
      >
        <div>Priority</div>
        <i
          [style.display]="sortBy.key == 'level' ? 'flex' : 'none'"
          [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
        ></i>
      </div>
      <div
        *ngIf="page == 1"
        class="stock-item-imp"
        (click)="setSortBy('frequency')"
      >
        <div>Frequency</div>
        <i
          [style.display]="sortBy.key == 'frequency' ? 'flex' : 'none'"
          [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
        ></i>
      </div>
      <div
        *ngIf="page == 1"
        class="stock-item-sml"
        (click)="setSortBy('lastComplete')"
      >
        <div>Last Completed</div>
        <i
          [style.display]="sortBy.key == 'lastComplete' ? 'flex' : 'none'"
          [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
        ></i>
      </div>
      <div *ngIf="page == 1" class="stock-item-sml">
        <div>Days</div>
      </div>
    </div>

    <div class="my-table" *ngIf="page != 4" style="height: calc(100% - 50px)">
      <div
        *ngFor="let s of cleaningTasks"
        class="my-table-item"
        (click)="openItem(s)"
        [class.green]="viewRecords && viewRecords.title == s.title"
      >
        <div
          class="my-table-item-large"
          (mouseenter)="hoverItem(s)"
          (mouseleave)="hoverItem(null)"
          [ngbTooltip]="page == 1 ? s.message : s.title"
        >
          <app-id-circle
            [selected]="!selected || s.title == selected.title"
            [id]="s.title"
          ></app-id-circle>
          <span>&nbsp; {{ s.title }}</span>
        </div>
        <div *ngIf="page == 1" class="my-table-item-imp">
          {{ s.level | priority }}
        </div>
        <div *ngIf="page == 1" class="my-table-item-imp">
          {{ s.frequency | frequency }}
        </div>
        <div *ngIf="page == 1" class="my-table-item-sml">
          {{ s.lastComplete | date : "yyyy-MM-dd" }}
        </div>
        <div
          *ngIf="page == 1"
          class="my-table-item-sml"
          [ngbTooltip]="s.days | days : true"
        >
          {{ s.days | days }}
        </div>
      </div>
    </div>
  </div>

  <div class="page-container" *ngIf="page == 2">
    <div class="my-table-header">
      <div class="my-table-item-sml">
        <div>Freq</div>
      </div>
      <div class="my-table-item-large" *ngFor="let day of weekly">
        <div>{{ day.name }}</div>
      </div>
    </div>

    <div class="my-table">
      <div *ngFor="let day of frequencies" class="my-table-item">
        <div class="my-table-item-sml">{{ day.id | frequency }}</div>
        <div class="my-table-item-large multiline">
          <app-id-circle
            *ngFor="let t of day.monday"
            [id]="t.title"
            (mouseenter)="hoverItem(t)"
            (mouseleave)="hoverItem(null)"
            [selected]="!selected || t.title == selected.title"
          ></app-id-circle>
        </div>
        <div class="my-table-item-large multiline">
          <app-id-circle
            (mouseenter)="hoverItem(t)"
            (mouseleave)="hoverItem(null)"
            *ngFor="let t of day.tuesday"
            [selected]="!selected || t.title == selected.title"
            [id]="t.title"
          ></app-id-circle>
        </div>
        <div class="my-table-item-large multiline">
          <app-id-circle
            *ngFor="let t of day.wednesday"
            [selected]="!selected || t.title == selected.title"
            [id]="t.title"
            (mouseenter)="hoverItem(t)"
            (mouseleave)="hoverItem(null)"
          ></app-id-circle>
        </div>
        <div class="my-table-item-large multiline">
          <app-id-circle
            *ngFor="let t of day.thursday"
            [selected]="!selected || t.title == selected.title"
            [id]="t.title"
            (mouseenter)="hoverItem(t)"
            (mouseleave)="hoverItem(null)"
          ></app-id-circle>
        </div>
        <div class="my-table-item-large multiline">
          <app-id-circle
            *ngFor="let t of day.friday"
            [selected]="!selected || t.title == selected.title"
            [id]="t.title"
            (mouseenter)="hoverItem(t)"
            (mouseleave)="hoverItem(null)"
          ></app-id-circle>
        </div>
        <div class="my-table-item-large multiline">
          <app-id-circle
            *ngFor="let t of day.saturday"
            [selected]="!selected || t.title == selected.title"
            [id]="t.title"
            (mouseenter)="hoverItem(t)"
            (mouseleave)="hoverItem(null)"
          ></app-id-circle>
        </div>
        <div class="my-table-item-large multiline">
          <app-id-circle
            *ngFor="let t of day.sunday"
            [selected]="!selected || t.title == selected.title"
            [id]="t.title"
            (mouseenter)="hoverItem(t)"
            (mouseleave)="hoverItem(null)"
          ></app-id-circle>
        </div>
      </div>
    </div>
  </div>

  <div class="page-container" *ngIf="page == 3">
    <p style="margin-top: 15px" *ngIf="!viewRecords">
      Click a task to view its records
    </p>

    <div class="my-table-header" *ngIf="viewRecords">
      <div class="my-table-item-sml">
        <div>Date</div>
      </div>
      <div class="my-table-item-large">
        <div>Staff</div>
      </div>

      <div class="my-table-item-large">
        <div>Photo</div>
      </div>
    </div>

    <div class="my-table" *ngIf="viewRecords">
      <div *ngFor="let day of viewRecords.records" class="my-table-item">
        <div class="my-table-item-sml">
          {{ day.shiftDate | date : "yyyy-MM-dd" }}
        </div>

        <div class="my-table-item-large multiline">
          <p *ngFor="let staff of day.staff">
            {{ staff.name | capitalize }} &nbsp;
          </p>
        </div>

        <div class="my-table-item-large">
          <a (click)="openPhoto(day.name, viewRecords.ref)">Link</a>
        </div>
      </div>
    </div>
  </div>

  <div
    class="page-container"
    *ngIf="page == 4"
    style="flex-direction: row; align-items: flex-start"
  >
    <div
      class="my-table"
      style="height: calc(100% - 50px); width: auto !important"
    >
      <div
        *ngFor="let s of staff"
        class="my-table-item"
        [class.green]="staffSelected && staffSelected.uid == s.uid"
        (click)="staffSelected = s"
      >
        <div class="my-table-item-large">
          <app-id-circle [id]="s.uid"></app-id-circle>
          <span>&nbsp; {{ s | name }}</span>
        </div>
      </div>
    </div>
    <div class="my-inner-table-container">
      <div class="my-table-header">
        <div class="my-table-item-large" style="width: 120px">
          <div>Date</div>
        </div>
        <div
          class="my-table-item-large"
          style="
            writing-mode: vertical-lr;
            height: 120px;
            padding: 2px;
            overflow: hidden;
            width: 30px;
            transform: scale(-1, -1);
          "
          *ngFor="let task of cleaningTasks"
          [class.green]="staffSelected && staffSelected.task == task.ref"
          (click)="staffSelected = { task: task.ref, uid: null }"
          [ngbTooltip]="task.title"
        >
          {{ task.title | slice : 0 : 15 }}
        </div>
      </div>
      <div
        class="my-table"
        style="max-height: 650px; flex-grow: 1; overflow: auto"
      >
        <div *ngFor="let day of staffCleaning" class="my-table-item">
          <div class="my-table-item-large" style="width: 120px">
            <span>&nbsp; {{ day.date | date }}</span>
          </div>

          <div
            *ngFor="let task of cleaningTasks"
            class="my-table-item-large"
            style="
              width: 30px;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
            "
          >
            <ng-container *ngFor="let staff of staff">
              <app-id-circle
                [id]="staff.uid"
                [selected]="
                  !staffSelected ||
                  staff.uid == staffSelected.uid ||
                  staffSelected.task == task.ref
                "
                *ngIf="
                  day.staff &&
                  day.staff[staff.uid] &&
                  day.staff[staff.uid][task.ref]
                "
              ></app-id-circle>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
