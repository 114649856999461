<div class="bar-buttons">
  <button
    class="action-button selected"
    *ngFor="let page of pages"
    [routerLink]="page.routerLink"
    routerLinkActive="true"
  >
    {{ page.name }}
  </button>
</div>
