<div class="modal-title">
  <div class="action-side"></div>
  <h3 style="margin: 5px 0px 2px 0px">{{ task.title | capitalize }}</h3>
  <button
    class="action-side close-button red"
    (click)="activeModal.close('dismiss')"
  >
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="actions actions-header">
  <button
    class="reactive-button-title"
    (click)="tab = 0"
    [ngClass]="tab == 0 ? 'green' : ''"
  >
    General
  </button>
  <button
    class="reactive-button-title"
    (click)="tab = 1"
    [ngClass]="tab == 1 ? 'green' : ''"
  >
    Schedule
  </button>
</div>

<ng-container *ngIf="tab == 0">
  <div class="edit-row">
    <div class="edit-desc">Server Id:</div>
    <div class="edit">
      {{ task.ref }}
    </div>
  </div>
  <div class="edit-row">
    <div class="edit-desc">Task Name:</div>
    <input class="edit" [(ngModel)]="task.title" name="name" />
  </div>

  <div class="edit-row">
    <div class="edit-desc">Message:</div>
    <textarea
      class="edit"
      rows="4"
      style="
        width: 100%;
        box-sizing: border-box;
        padding: 5px;
        word-wrap: break-word;
        white-space: pre-wrap;
      "
      [(ngModel)]="task.message"
      name="name"
    ></textarea>
  </div>
</ng-container>

<ng-container *ngIf="tab == 1">
  <div class="edit-row">
    <div class="edit-desc">Priority</div>

    <select [(ngModel)]="task.level">
      <option *ngFor="let level of priority" [ngValue]="level">
        {{ level | priority }}
      </option>
    </select>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Days</div>
    <div
      *ngFor="let day of days"
      class="edit"
      style="
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
      "
    >
      <div style="flex-grow: 2"></div>
      <label for="" style="width: 220px">{{ day.name }}</label>
      <input type="checkbox" [(ngModel)]="day.checked" name="days" />
      <div style="flex-grow: 2"></div>
    </div>
  </div>

  <div class="edit-row">
    <div class="edit-desc">Frequency</div>

    <select [(ngModel)]="task.frequency">
      <option *ngFor="let level of frequencies" [ngValue]="level">
        {{ level | frequency }}
      </option>
    </select>
  </div>
</ng-container>

<div class="actions actions-footer">
  <button
    class="reactive-button-title green"
    ngbTooltip=" Save will update, change category, correctly modify the order, only saves to the Database not the till!"
    (click)="saveTask()"
  >
    Save
  </button>

  <button
    class="reactive-button-title red"
    ngbTooltip=" Delete will only remove a drink from the Database not the till, i.e. if a drink is no longer available or has incorrect serverID you delete it, and can reimport it from the till drinks"
    (click)="deleteTask()"
  >
    Delete
  </button>
  <button
    class="reactive-button-title orange"
    (click)="activeModal.close('dismiss')"
  >
    Cancel
  </button>
</div>
