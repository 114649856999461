import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChange,
} from "@angular/core";
import { IsMobileService } from "src/app/Services/is-mobile.service";

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent {
  @Input("date") currentDate: Date = new Date();

  @Input("data") set _data(value: any) {
    this.fillCalendar(value);
  }
  currentMonth!: string;
  currentYear!: number;
  daysInMonth: (any | null)[] = [];
  weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  @Output("dateChange") dateChange = new EventEmitter<Date>();
  @Output("dateSelected") dateSelected = new EventEmitter<Date>();

  constructor(public isMobile: IsMobileService) {}

  ngOnInit(): void {
    this.updateCalendar(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth()
    );
  }

  fillCalendar(data: any) {
    console.log("fillCalendar", data);
    for (var day of data) {
      var index = this.daysInMonth.findIndex((d) => d?.day === day.day);
      if (day.shift || day.shift2 || day.till || day.stock) {
        day.contains = true;
      }
      Object.assign(this.daysInMonth[index], day);
    }
    console.log("daysInMonth", this.daysInMonth);
  }
  updateCalendar(year: number, month: number): void {
    this.currentYear = year;
    this.currentMonth = this.currentDate.toLocaleString("default", {
      month: "long",
    });

    const firstDay = new Date(year, month, 1).getDay();
    const totalDays = new Date(year, month + 1, 0).getDate();

    this.daysInMonth = Array(firstDay).fill(null); // Empty slots for previous month's days
    for (let i = 1; i <= totalDays; i++) {
      //
      this.daysInMonth.push({
        day: i,
        isWeekend: this.isWeekend(i),
        dayName: this.dayName(i),
      });
    }

    this.dateChange.emit(this.currentDate);
  }

  dayName(day: number): string {
    return this.weekDays[
      new Date(this.currentYear, this.currentDate.getMonth(), day).getDay()
    ];
  }

  isWeekend(day: number): boolean {
    const weekDay = new Date(
      this.currentYear,
      this.currentDate.getMonth(),
      day
    ).getDay();
    return weekDay === 0 || weekDay === 6 || weekDay === 5;
  }

  previousMonth(): void {
    const previous = new Date(
      this.currentYear,
      this.currentDate.getMonth() - 1
    );
    this.currentDate = previous;
    this.updateCalendar(previous.getFullYear(), previous.getMonth());
  }

  nextMonth(): void {
    const next = new Date(this.currentYear, this.currentDate.getMonth() + 1);
    this.currentDate = next;
    this.updateCalendar(next.getFullYear(), next.getMonth());
  }

  selectDate(date: number): void {
    if (!date) return;

    var newDate = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      date
    );
    console.log("selectDate", newDate);
    this.dateSelected.emit(newDate);
  }
}
