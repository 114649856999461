import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { prodErrorMap } from "firebase/auth";
import { isNumber } from "lodash";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { LoadingService } from "src/app/Services/loading.service";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";
import { HOStock } from "../inkoop-kosten/inkoop-kosten.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PDFMakerComponent } from "src/app/Packages/pdfmaker/pdfmaker.component";

@Component({
  selector: "app-drink-orders",
  templateUrl: "./drink-orders.component.html",
  styleUrls: ["./drink-orders.component.css"],
})
export class DrinkOrdersComponent implements OnInit {
  orders = [];
  name;
  page: number = 0;
  pageLength: number = 1;
  order;
  orderItems = [];
  list = [];
  showInvoice = false;
  invoice: any = {};
  hqStock: HOStock[] = [];
  companyInfo = {
    address: "",
    number: "",
    zip: "",
    city: "",
    country: "Netherlands",
    email: "",
    iban: "",
    btw: "",
    kvk: "",
    name: "",
  };
  headOffice = {
    address: "",
    number: "",
    zip: "",
    city: "",
    country: "Netherlands",
    email: "",
    iban: "",
    btw: "",
    kvk: "",
    name: "",
  };
  constructor(
    private bI: BarInfo,
    private db: StaffAppDatabase,
    private load: LoadingService,
    private http: HttpClient,
    public modal: NgbModal
  ) {
    this.db.ordersObserver.subscribe((orders) => {
      if (orders) {
        this.orders = orders.sort(
          (a: any, b: any) => -Number(a.dateTime) + Number(b.dateTime)
        );
        this.pageLength = Math.ceil(this.orders.length / 10);
      }
    });

    this.db.ordersObserverItems.subscribe((items) => {
      if (items) {
        let temp = [];
        items.forEach((i: any[]) => {
          let a = Object.values(i);
          temp = temp.concat(a);
        });
        this.orderItems = temp;
      }
    });
    this.bI.barObserver.subscribe((name) => {
      this.name = name;
      this.getData();
      this.db.getOrdersItems(name);
      this.getCompanyInfo();
    });
  }

  ngOnInit(): void {}

  lastItem: any = null;
  async getData() {
    this.hqStock = await this.db.getHOStock();
    const val: any = await this.db.getOrdersAsync(
      this.name,
      this.page,
      10,
      this.lastItem
    );
    this.orders.push(...val.list);
    this.lastItem = val.endDoc;
    console.log(this.orders);
    this.pageLength = Math.ceil(this.orders.length / 10);
  }
  async getCompanyInfo() {
    const comp = await this.db
      .getCompanyInfo(this.name)
      .then((a) => a)
      .catch((e) => {});
    const head = await this.db
      .getCompanyInfo("Daga Beheer")
      .then((a) => a)
      .catch((e) => {});

    this.companyInfo = Object.assign(
      {
        address: "",
        number: "",
        zip: "",
        city: "",
        country: "Netherlands",
        email: "",
        iban: "",
        btw: "",
        kvk: "",
        name: "",
      },
      comp
    );
    this.headOffice = Object.assign(
      {
        address: "",
        number: "",
        zip: "",
        city: "",
        country: "Netherlands",
        email: "",
        iban: "",
        btw: "",
        kvk: "",
        name: "",
      },
      head
    );
  }

  loadMore() {
    this.page++;
    this.getData();
  }

  openOrder(order) {
    this.order = order;

    this.list = Object.values(order.map).sort((a: any, b: any) => {
      if (a.crateSize <= 1 && b.crateSize > 1) {
        return 1;
      } else if (a.crateSize > 1 && b.crateSize <= 1) {
        return -1;
      }

      return a.name.localeCompare(b.name);
    });
    this.list.forEach((a) => this.calculateStock(a));
    console.log(this.list);
  }

  calculateStock(s) {
    if (s.name.includes("Cola")) {
      console.log(s);
    }
    var diff = 0;
    if (s.crateSize > 1) {
      diff = Math.ceil(
        (s.defaultQuantity - (s.tillQuantity - s.offset)) /
          Math.max(s.crateSize, 1)
      );
      diff = Math.max(diff, 0);
      if (s.modifier >= 0) {
        diff = s.modifier;
      }
      if (isNaN(diff)) {
        s.toOrder = 0;
      } else {
        s.toOrder = diff;
      }
    } else {
      diff = s.stockQuantity;
      diff = Math.max(diff, 0);
      if (s.modifier >= 0) {
        diff = s.modifier;
      }
      if (isNaN(diff)) {
        s.toOrder = 0;
      } else {
        s.toOrder = diff;
      }
    }

    return diff;
  }

  saveOrder() {
    console.log(this.order);

    console.log(this.order);
    this.db.saveOrder(this.name, this.order);

    this.db.getOrder(this.name);
  }

  markOrder() {
    for (let k of this.list) {
      k.orderedQuantity = this.calculateStock(k);
    }
  }

  cancel() {
    this.list = null;
    this.order = null;
  }

  async deleteOrder(order) {
    if (!confirm("Weet je zeker dat je deze bestelling wilt verwijderen?")) {
      return;
    }
    await this.db.deleteOrder(this.name, order);
    this.db.getOrders(this.name);
  }

  getValues() {
    return Object.values(this.order.map);
  }

  async updateHQQuantities(order) {
    var errors = [];
    console.log(order);
    this.load.show();
    //todo parallelise
    var list: any[] = Object.values(order.map);
    var promises = [];

    for (var item of list) {
      var p = new Promise(async (resolve, reject) => {
        var hqStock = this.hqStock.find(
          (a) => a.bars[this.name] && a.bars[this.name].id === item.serverId
        );
        if (hqStock) {
          hqStock.quantity -= item.orderedQuantity * item.crateSize;
          await this.db.saveHOStockItemOrder(hqStock);
          resolve(null);
        } else {
          errors.push(item.name);
          resolve(null);
        }
      });
      promises.push(p);
    }

    await Promise.all(promises)
      .then((a) => {
        if (errors.length > 0) {
          alert("Couldnt find HQ Stock for: " + errors.join("\n"));
        }
      })
      .catch((e) => {
        console.error(e);
      });
    this.load.hide();
  }

  async makeInvoice(order) {
    const date = new Date(Number(order.dateTime));
    const due = new Date(date);
    due.setDate(due.getDate() + 14);
    this.showInvoice = true;
    const embellage: any = {};
    try {
      Object.values(order.map).forEach((a: any) => {
        // console.log(a, a.embellage, embellage);
        if (isNumber(a.embellage) && a.embellage > 0) {
          console.log(a.embellage);
          if (embellage[a.embellage + ""]) {
            embellage[a.embellage + ""] += a.orderedQuantity;
          } else {
            embellage[a.embellage + ""] = a.orderedQuantity;
          }
        }
      });
    } catch (e) {
      console.error(e);
    }

    let data = {
      image:
        // The logo on top of your invoice
        "assets/dba.jpg",

      // sender and client info (address, iban, etc..)
      sender: this.headOffice,
      client: this.companyInfo,
      information: {
        number: order.key,
        key: order.key,
        date: date.toLocaleDateString(),
        "due-date": due.toLocaleDateString(),
        datetime: date.getTime(),
      },

      // The products you would like to see on your invoice
      products: Object.values(order.map)
        .filter((a: any) => a.orderedQuantity > 0)
        .map((a: any) => {
          console.log(
            " adding: " +
              a.name +
              " " +
              " ordered:" +
              a.orderedQuantity +
              " resalePerUnit: " +
              a.resalePerUnit
          );
          return {
            quantity: a.orderedQuantity,
            name: a.name,
            category:
              a.invoiceCategory ||
              a.category + "-" + a.vat + (a.embellage ? "-" + a.embellage : ""),
            description: a.description || "desc",
            "tax-rate": a.vat ? a.vat : 0,
            price: a.resalePerUnit || a.pricePerUnit,
            total: a.orderedQuantity * (a.resalePerUnit || a.pricePerUnit),
            embellage: a.embellage || 0,
          };
        }),

      //info including subtotal, map of vat amount - total, map of embellage amount - total, total including vat en embellage
      total: {
        subtotal: 0,
        vats: {},
        embellages: {},
        total: 0,
        returnedEmbellage: {},
        embellageReturnedTotal: 0,
        embellageTotal: 0,
        categories: [],
      },
    };
    const tempCat = {};

    let vat: any[] = [];

    data.products.forEach((prod: any) => {
      data.total.subtotal += prod.total;
      data.total.embellageTotal += prod.embellage * prod.quantity;
      if (!data.total.vats[prod["tax-rate"] + ""]) {
        data.total.vats[prod["tax-rate"] + ""] =
          (prod.total * prod["tax-rate"]) / 100;
      } else {
        data.total.vats[prod["tax-rate"] + ""] +=
          (prod.total * prod["tax-rate"]) / 100;
      }

      if (!tempCat[prod.category]) {
        tempCat[prod.category] = {
          name: prod.category,
          total: prod.price * prod.quantity,
        };
      } else {
        tempCat[prod.category].total += prod.price * prod.quantity;
      }

      if (prod.embellage != 0) {
        if (!data.total.embellages[prod.embellage + ""]) {
          data.total.embellages[(prod.embellage * 100).toFixed(0) + ""] =
            prod.quantity * prod.embellage;
        } else {
          data.total.embellages[(prod.embellage * 100).toFixed(0) + ""] +=
            prod.quantity * prod.embellage;
        }
      }

      data.total.total += prod.total + (prod.total * prod["tax-rate"]) / 100;
    });

    if (order.emballage) {
      console.log(order.emballage);
      data.total.returnedEmbellage = order.emballage;
      data.total.embellageReturnedTotal = Object.values(
        order.emballage
      ).reduce<number>((prev: number, curr: any, ind) => {
        prev += curr.quantity * curr.price;
        return prev;
      }, 0);
    }

    console.log("tempcat", tempCat);
    data.total.categories = Object.entries(tempCat).map(([key, val]) => val);

    console.log(Object.values(order.map));

    console.log(data);
    this.invoice = data;
    await this.db.saveDrinkOrderFinal(this.name, this.invoice, "drinkorder");

    const modalRef = this.modal.open(PDFMakerComponent, {
      size: "xl",
      scrollable: true,
      backdrop: "static",
    });
    modalRef.componentInstance.type = "drinksorder";
    modalRef.componentInstance.data = this.invoice;
  }
}
