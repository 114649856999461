<div class="bar-buttons">
  <button routerLink="bonus" routerLinkActive="true" class="action-button">
    Bonus
  </button>

  <button routerLink="inkoop" routerLinkActive="true" class="action-button">
    Inkoop
  </button>

  <button routerLink="bookings" routerLinkActive="true" class="action-button">
    Booking API
  </button>

  <button routerLink="map" routerLinkActive="true" class="action-button">
    Map
  </button>

  <button
    routerLink="detailedturnover"
    routerLinkActive="true"
    class="action-button"
  >
    Detailed Turnover
  </button>

  <button routerLink="working" routerLinkActive="true" class="action-button">
    Working
  </button>

  <button routerLink="creditSim" routerLinkActive="true" class="action-button">
    Credit Simulator
  </button>

  <button routerLink="giftcards" routerLinkActive="true" class="action-button">
    Gift Cards
  </button>

  <!--<button routerLink="CCTV" routerLinkActive="true" class="action-button">
  CCTV
</button>-->

  <button
    routerLink="tipschooser"
    routerLinkActive="true"
    class="action-button"
  >
    Tips
  </button>

  <button routerLink="email" routerLinkActive="true" class="action-button">
    Email
  </button>

  <button routerLink="theendtv" routerLinkActive="true" class="action-button">
    TheEndTV
  </button>

  <button routerLink="drinkprice" routerLinkActive="true" class="action-button">
    Drink Price
  </button>

  <button routerLink="sidebar" routerLinkActive="true" class="action-button">
    Sidebar Testing
  </button>
  <button (click)="testCheckContracts()" class="action-button">
    Test Check Contracts
  </button>
</div>
