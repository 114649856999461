<!-- So list of orders, by date and status-->
<!-- Info of each order-->
<!-- Actions, mark is archived, mark as ordered, if not ordered can edit-->

<ng-template #content let-modal>
  <div class="modal-title">
    <div class="action-side"></div>
    <h3 style="margin: 5px 0px 2px 0px">
      {{ order.dateTime | date : "yyyy/MM/dd - H:mm" }} - {{ order.status }}
    </h3>
    <button
      class="action-side close-button red"
      (click)="modal.close('dismiss')"
    >
      <i class="fas fa-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="order">
      <p>Comments: {{ order.comments }}</p>
      <table>
        <thead>
          <tr>
            <th class="tg-0lax-1">name</th>
            <th class="tg-0lax">default</th>
            <th class="tg-0lax">stock</th>
            <th class="tg-0lax">modified</th>
            <th class="tg-0lax">to order</th>
            <th class="tg-0lax">arrived</th>
          </tr>
        </thead>
      </table>

      <table style="max-height: 800px; overflow: auto">
        <tr *ngFor="let item of order.items">
          <th class="tg-0lax-1">{{ item.name }}</th>
          <th class="tg-0lax">{{ item.defaultQuantity }}</th>
          <th class="tg-0lax">{{ item.stockQuantity }}</th>
          <th class="tg-0lax">
            <input
              *ngIf="order.status === 'Requested'"
              style="max-width: 70px"
              type="number"
              (change)="
                item.ordered =
                  item.modifier >= 0
                    ? item.modifier
                    : item.defaultQuantity - item.stockQuantity
              "
              [(ngModel)]="item.modifier"
            />
            <div *ngIf="order.status !== 'Requested'">{{ item.modifier }}</div>
          </th>
          <th class="tg-0lax">{{ item.ordered }}</th>
          <th class="tg-0lax">{{ item.arrived }}</th>
        </tr>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <div class="button-row" *ngIf="order">
      <button mat-raised-button (click)="cancel()">Cancel</button>
      <button
        mat-raised-button
        *ngIf="order.status === 'Stock Counted'"
        (click)="orderOrder()"
      >
        Set Ordered
      </button>
      <button
        mat-raised-button
        *ngIf="order.status === 'Requested'"
        (click)="saveOrder()"
      >
        Save Modifications
      </button>
      <button
        mat-raised-button
        *ngIf="order.status === 'Requested'"
        (click)="order.status = 'Ordered'; saveOrder()"
      >
        Mark for Loading Van
      </button>
      <button
        mat-raised-button
        *ngIf="order.status === 'Counted In' || order.status === 'Ordered'"
        (click)="saveAndMarkCompleted()"
      >
        Save & Mark Completed
      </button>
      <button
        mat-raised-button
        *ngIf="order.status === 'Archived'"
        (click)="makeInvoice(order)"
      >
        Make Invoice
      </button>
      <button mat-raised-button (click)="deleteOrder(order)">
        Delete Order
      </button>
    </div>
  </div>
</ng-template>

<div class="my-table-header">
  <div class="stock-item-large" (click)="setSortBy('name')">
    <div>Name</div>
    <i
      [style.display]="sortBy.key == 'name' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>
  <div class="stock-item-imp" (click)="setSortBy('status')">
    <div>Status</div>
    <i
      [style.display]="sortBy.key == 'serialNumber' ? 'flex' : 'none'"
      [class]="'fas fa-chevron-' + (sortBy.reverse ? 'down' : 'up')"
    ></i>
  </div>
  <div class="stock-item-imp">
    <div>Items</div>
  </div>
</div>

<div class="my-table">
  <button (click)="showMore()" class="my-table-item">Show More</button>
  <div
    *ngFor="let order of orders"
    class="my-table-item"
    (click)="openOrder(order, content)"
  >
    <div class="my-table-item-large">
      {{ order.dateTime | date : "yyyy/MM/dd - H:mm" }}
    </div>
    <div class="my-table-item-imp">
      {{ order.status }}
    </div>
    <div class="my-table-item-imp">{{ order.items.length }}</div>
  </div>
</div>
