export class CleaningTask {
  bar: string = "";
  complete: boolean = false;
  days: string = "";
  end: number = -1;
  endShift: boolean = false;
  frequency: number = 0;
  lastAttempted: number;
  lastComplete: number;
  lastReviewed: number;
  level: number = 0;
  message: string = "";
  ref: string = "";
  start: number = Date.now();
  title: string = "";
  records: any[] = [];

  constructor() {}
}
