import { Component, NgZone, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { end } from "@popperjs/core";
import { Auth } from "src/app/auth/auth";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsComponent } from "src/app/Packages/alerts/alerts.component";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { CountingServiceService } from "src/app/Services/counting-service.service";
import { IsMobileService } from "src/app/Services/is-mobile.service";
import { LoadingService } from "src/app/Services/loading.service";
import { CountingModalComponent } from "../counting-modal/counting-modal.component";

@Component({
  selector: "app-counting-better",
  templateUrl: "./counting-better.component.html",
  styleUrls: ["./counting-better.component.scss"],
})
export class CountingBetterComponent implements OnInit {
  data: any[] = [];
  date = new Date();
  constructor(
    public isMobile: IsMobileService,
    public auth: Auth,
    public bi: BarInfo,
    public loading: LoadingService,
    public alert: AlertsService,
    public modal: NgbModal,
    public counting: CountingServiceService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.getData(this.date);
  }

  async getData(date: Date) {
    this.loading.show();
    this.data = [];
    this.date = new Date(date);
    var stockData = await this.counting
      .getStock(date.getMonth(), date.getFullYear(), this.bi.bar)
      .then((data) => {
        if (!data) return null;
        for (var day of data) {
          var result = {
            icon: "fas fa-box",
            title: "Stock",
            startStatus: 0,
            endStatus: 0,
          };

          if (day.start) {
            //is it correct then 2, is if off then 1
            result.startStatus++;
            if (
              day.start.reduce((curr, next) => {
                if (next.difference != 0) return false;
                return curr;
              }, true)
            )
              result.startStatus++;
          }

          if (day.end) {
            result.endStatus++;
            if (
              day.end.reduce((curr, next) => {
                if (next.difference != 0) return false;
                return curr;
              }, true)
            )
              result.endStatus++;
          }

          day.result = result;
        }
        return data;
      })
      .catch((error) => {
        this.alert.nextEmit(AlertsService.error("Error", error));
        return [];
      });

    console.log(stockData);

    var tillData = await this.counting
      .getTill(date.getMonth(), date.getFullYear(), this.bi.bar)
      .then((data) => {
        if (!data) return null;

        for (var day of data) {
          var result = {
            icon: "fas fa-cash-register",
            title: "Till",
            startStatus: 0,
            endStatus: 0,
          };
          if (day.start) {
            //is it correct then 2, is if off then 1
            result.startStatus++;
            if (day.start.difference == 0) result.startStatus++;
          }
          if (day.end) {
            result.endStatus++;
            if (
              day.end.realCash == day.expectedCash &&
              day.end.realPin == day.expectedPin
            )
              result.endStatus++;
          }

          day.result = result;
        }

        return data;
      })
      .catch((error) => {
        this.alert.nextEmit(AlertsService.error("Error", error));
        return [];
      });

    console.log(tillData);

    var shifts = await this.counting
      .getShiftCheck(date.getMonth(), date.getFullYear(), this.bi.bar)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        this.alert.nextEmit(AlertsService.error("Error", error));
        return [];
      });

    console.log(shifts);
    var shifts2 = Object.assign(
      [],
      shifts.map((a) => Object.assign({}, a))
    );
    shifts2.map((shift) => {
      var result = {
        icon: "fas fa-shirt",
        title: "Shift",
        startStatus: 0,
        endStatus: -1,
      };

      if (shift.shirts == false || shift.shirts == true) {
        result.startStatus++;
      }

      if (shift.shirts) result.startStatus++;

      shift.result = result;
    });

    shifts.map((shift) => {
      var result = {
        icon: "fas fa-user-clock",
        title: "Shift",
        startStatus: 0,
        endStatus: -1,
      };

      if (shift.arriveOnTime == false || shift.arriveOnTime == true) {
        result.startStatus++;
      }

      if (shift.arriveOnTime) result.startStatus++;
      shift.result = result;
    });

    var data = [];
    for (var i = 0; i < 31; i++) {
      //find the day in the stock/till/shifts data and add it to the data array (remove the day from the array)
      var day = i + 1;
      var stock = stockData.find((a) => a.dayNumber == String(day));
      var till = tillData.find((a) => a.dayNumber == String(day));
      var shift = shifts.find((a) => a.dayNumber == String(day));
      var shift2 = shifts2.find((a) => a.dayNumber == String(day));

      if (!stock && !till && !shift) continue;
      data.push({
        day: day,
        stock: stock,
        till: till,
        shift: shift,
        shift2: shift2,
      });

      if (stock) stockData.splice(stockData.indexOf(stock), 1);
      if (till) tillData.splice(tillData.indexOf(till), 1);
      if (shift) shifts.splice(shifts.indexOf(shift), 1);
      if (shift2) shifts2.splice(shifts2.indexOf(shift2), 1);
    }

    this.ngZone.run(() => {
      this.data = data;
    });

    this.loading.hide();
  }

  dateChange(date: Date): void {
    console.log(date);
    this.getData(date);
  }

  dateSelected(date: Date): void {
    console.log(date);
    const modal = this.modal.open(CountingModalComponent, {
      centered: true,
      size: "xl",
      backdrop: "static",
    });

    modal.componentInstance.date = date;
    modal.componentInstance.data = this.data.find(
      (a) => a.day == date.getDate()
    );
  }
}
