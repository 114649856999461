<div class="staff-info-title">
  <div class="action-side"></div>
  <h3 style="margin: 5px 0px 2px 0px">
    {{ date | date : "EEEE dd LLLL yyyy" }}
  </h3>
  <button
    class="action-side close-button red"
    style="width: 40px !important"
    (click)="dismiss()"
  >
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="actions actions-header">
  <button
    class="reactive-button-title"
    (click)="tab = 0"
    [ngClass]="tab == 0 ? 'green' : ''"
  >
    Stock
  </button>
  <button
    class="reactive-button-title"
    (click)="tab = 1"
    [ngClass]="tab == 1 ? 'green' : ''"
  >
    Till
  </button>
  <button
    class="reactive-button-title"
    (click)="tab = 2"
    [ngClass]="tab == 2 ? 'green' : ''"
  >
    Shirts & Time
  </button>

  <button
    class="reactive-button-title"
    (click)="tab = 3"
    [ngClass]="tab == 3 ? 'green' : ''"
  >
    Staff
  </button>
</div>

<div class="actions actions-header" *ngIf="tab == 0">
  <button
    class="reactive-button-title"
    (click)="tab2 = 0"
    [ngClass]="tab2 == 0 ? 'green' : ''"
  >
    Start
  </button>
  <button
    class="reactive-button-title"
    (click)="tab2 = 1"
    [ngClass]="tab2 == 1 ? 'green' : ''"
  >
    End
  </button>
</div>

<div class="stock-count-wrapper">
  <ng-container *ngIf="tab == 0 && tab2 == 0">
    <table *ngIf="data.stock.start" class="table table-scrolly">
      <tr class="table-row">
        <th class="table-column" style="width: 140px">Name</th>
        <th class="table-column">Till</th>
        <th class="table-column">Stock</th>
        <th class="table-column italic">Past</th>
        <th class="table-column italic">Today</th>
        <th class="table-column">Total</th>
        <th
          *ngIf="moreInfo2"
          class="table-column"
          style="width: 5px !important"
        >
          &nbsp;
        </th>
        <th *ngIf="moreInfo2" class="table-column italic">Fridge</th>
        <th *ngIf="moreInfo2" class="table-column italic">VIP</th>
      </tr>
      <tr
        class="table-row"
        *ngFor="let s of incorrect(data.stock.start, all2)"
        [ngClass]="{ blue: s.difference != 0 }"
      >
        <th class="table-column" style="width: 140px">{{ s.name }}</th>
        <th class="table-column">{{ s.till }}</th>
        <th class="table-column">{{ s.counted - s.currentOffset }}</th>
        <th class="table-column italic">{{ -s.currentOffset }}</th>
        <th class="table-column italic">{{ -(s.till - s.counted) }}</th>
        <th class="table-column">
          {{ -(s.till - (s.counted - s.currentOffset)) }}
        </th>
        <th
          *ngIf="moreInfo2"
          class="table-column"
          style="width: 5px !important"
        >
          &nbsp;
        </th>
        <th *ngIf="moreInfo2" class="table-column italic">
          {{ s.fridgeDifference }}
        </th>
        <th *ngIf="moreInfo2" class="table-column italic">
          {{ s.privateRoomDifferences }}
        </th>
      </tr>
    </table>
  </ng-container>
  <ng-container *ngIf="tab == 0 && tab2 == 1">
    <div class="edit-row" *ngIf="data.stock && data.stock.reason">
      <label>Incorrect Reason:</label>
      <div>
        {{ data.stock?.reason }}
      </div>
    </div>

    <table *ngIf="data.stock?.end" class="table table-scrolly">
      <tr class="table-row">
        <th class="table-column" style="width: 140px">Name</th>
        <th class="table-column">Till</th>
        <th class="table-column">Stock</th>
        <th class="table-column italic">Past</th>
        <th class="table-column italic">Today Start</th>
        <th class="table-column italic">Today End</th>
        <th class="table-column">Total</th>
        <th
          *ngIf="moreInfo1"
          class="table-column"
          style="width: 5px !important"
        >
          &nbsp;
        </th>
        <th *ngIf="moreInfo1" class="table-column italic">Fridge</th>
        <th *ngIf="moreInfo1" class="table-column italic">VIP</th>
      </tr>
      <tr
        class="table-row"
        *ngFor="let s of incorrect(data.stock?.end, all1)"
        [ngClass]="{ blue: s.difference != 0 }"
      >
        <th class="table-column" style="width: 140px">{{ s.name }}</th>
        <th class="table-column">{{ s.till }}</th>
        <th class="table-column">{{ s.counted - s.currentOffset }}</th>
        <th class="table-column italic">{{ -s.currentOffset }}</th>
        <th
          class="table-column italic"
          [ngClass]="{
            yellow: s.offsetSet === 'ignored',
            red: s.offsetSet === 'set'
          }"
        >
          {{ isFinite(s.startTotal) ? s.startTotal : "na" }}
        </th>
        <th class="table-column italic">{{ -(s.till - s.counted) }}</th>
        <th class="table-column">
          {{ -(s.till - (s.counted - s.currentOffset)) }}
        </th>
        <th
          *ngIf="moreInfo1"
          class="table-column"
          style="width: 5px !important"
        >
          &nbsp;
        </th>
        <th *ngIf="moreInfo1" class="table-column italic">
          {{ s.fridgeDifference }}
        </th>
        <th *ngIf="moreInfo1" class="table-column italic">
          {{ s.privateRoomDifferences }}
        </th>
      </tr>
    </table>
  </ng-container>

  <ng-container *ngIf="tab == 1">
    <h3>Start</h3>
    <table class="table" style="">
      <tr class="table-row">
        <th class="table-column" style="width: 120px">Cash Aim</th>
        <th class="table-column" style="width: 120px">Cash Actual</th>
        <th class="table-column" style="width: 120px">+/-</th>
      </tr>
      <tr class="table-row">
        <th class="table-column" style="width: 120px">
          {{ data.till?.start?.original.toFixed(2) }}
        </th>
        <th class="table-column" style="width: 120px">
          {{ data.till?.start?.updated.toFixed(2) }}
        </th>
        <th class="table-column" style="width: 120px">
          {{ data.till?.start?.difference.toFixed(2) }}
        </th>
      </tr>
    </table>

    <div class="divider"></div>

    <h3>End</h3>

    <table
      class="table"
      style="width: 100% !important; max-width: 100% !important"
    >
      <tr class="table-row">
        <th class="table-column" style="width: 120px">Pin Till</th>
        <th class="table-column" style="width: 120px">Pin Tablet</th>
      </tr>
      <tr class="table-row">
        <th class="table-column" style="width: 120px">
          {{ data.till?.expected?.pin?.toFixed(2) }}
        </th>
        <th class="table-column" style="width: 120px">
          {{ data.till?.end?.realPin?.toFixed(2) }}
        </th>
      </tr>
    </table>

    <table
      class="table"
      style="width: 100% !important; max-width: 100% !important"
    >
      <tr class="table-row">
        <th class="table-column" style="width: 120px">Cash Till</th>
        <th class="table-column" style="width: 120px">Cash Tablet</th>
      </tr>
      <tr class="table-row">
        <th class="table-column" style="width: 120px">
          {{ data.till?.expected?.cash?.toFixed(2) }}
        </th>
        <th class="table-column" style="width: 120px">
          {{ data.till?.end?.realCash?.toFixed(2) }}
        </th>
      </tr>
    </table>

    <table
      class="table"
      style="width: 100% !important; max-width: 100% !important"
    >
      <tr class="table-row">
        <th class="table-column" style="width: 120px">Internet</th>
        <th class="table-column" style="width: 120px">Staff Drinks</th>
        <th class="table-column" style="width: 120px">Other</th>
      </tr>
      <tr class="table-row">
        <th class="table-column" style="width: 120px">
          {{ data.till?.expected?.internet?.toFixed(2) }}
        </th>
        <th class="table-column" style="width: 120px">
          {{ data.till?.expected?.staffDrinks?.toFixed(2) }}
        </th>
        <th class="table-column" style="width: 120px">
          {{ data.till?.expected?.other?.toFixed(2) }}
        </th>
      </tr>
    </table>
  </ng-container>

  <ng-container *ngIf="tab == 2">
    <div class="edit-row" *ngIf="data.shift">
      <label>Arrived On Time</label>
      <div [class]="data.shift?.arriveOnTime ? 'green' : 'red'" class="circle">
        <i class="fas fa-user-clock"></i>
      </div>
    </div>

    <div class="edit-row" *ngIf="data.shift">
      <label>Had Shirts</label>
      <div [class]="data.shift?.shirts ? 'green' : 'red'" class="circle">
        <i class="fas fa-shirt"></i>
      </div>
    </div>

    <div class="edit-row" *ngIf="data.shift">
      <label>Comments:</label>
      <div>
        {{ data.shift?.comment }}
      </div>
    </div>

    <div *ngIf="!data.shift">No Information Was Provided</div>
  </ng-container>

  <ng-container *ngIf="tab == 3">
    <app-who-is-working
      [worked]="worked"
      [showPrice]="false"
    ></app-who-is-working>
  </ng-container>
</div>
