<div class="flex-rows">
  <div class="flex-columns">
    <h1 class="flex-title">Orders</h1>
    <div class="list">
      <div *ngFor="let order of orders">
        <div
          class="button-row"
          style="margin-top: 10px"
          (click)="openOrder(order)"
        >
          {{ order.dateTime | date : "yyyy/MM/dd" }} {{ order.status }}
        </div>
      </div>
    </div>

    <button (click)="loadMore()">Load More</button>
  </div>
  <div class="flex-columns">
    <h1 class="flex-title">Actions</h1>
    <div class="button-row" *ngIf="order">
      <button mat-raised-button (click)="cancel()">Cancel</button>
      <button
        mat-raised-button
        *ngIf="order.status !== 'Counted In' && order.status !== 'Ordered'"
        (click)="saveOrder()"
      >
        Save Modifiers
      </button>
      <button
        mat-raised-button
        *ngIf="order.status === 'Requested'"
        (click)="order.status = 'Ordered'; saveOrder()"
      >
        Mark for Loading Van
      </button>
      <button
        mat-raised-button
        *ngIf="order.status === 'Counted In' || order.status === 'Ordered'"
        (click)="
          order.status = 'Archived';
          saveOrder();
          updateHQQuantities(order);
          makeInvoice(order)
        "
      >
        Save and Mark Completed
      </button>
      <button
        mat-raised-button
        *ngIf="order.status === 'Archived'"
        (click)="makeInvoice(order)"
      >
        Make Invoice
      </button>

      <button mat-raised-button (click)="deleteOrder(order)">
        Delete Order
      </button>
    </div>
    <div
      *ngIf="order"
      style="
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: auto;
      "
    >
      <h3>{{ order.status }}</h3>
      <h3>{{ order.dateTime | date : "yyyy/MM/dd - H:mm" }}</h3>

      <table>
        <thead>
          <tr>
            <th class="tg-0lax" style="width: 200px">name</th>
            <th class="tg-0lax">default</th>
            <th class="tg-0lax">current</th>
            <th class="tg-0lax">empties</th>
            <th class="tg-0lax">modified</th>

            <th class="tg-0lax">To Order!!</th>
            <th class="tg-0lax">ordered</th>
            <th class="tg-0lax">arrived</th>
          </tr>
        </thead>
      </table>

      <div
        style="
          flex-grow: 1;
          overflow: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        "
      >
        <table style="flex-grow: 1">
          <tr *ngFor="let item of list">
            <th class="tg-0lax" style="width: 200px">{{ item.name }}</th>
            <th
              class="tg-0lax"
              [style.background-color]="
                item.crateSize <= 1
                  ? item.defaultQuantity ===
                    item.tillQuantity + item.absoluteStockQuantity
                    ? 'green'
                    : 'red'
                  : 'transparent'
              "
            >
              {{ item.defaultQuantity }}
            </th>
            <th class="tg-0lax">{{ item.tillQuantity - item.offset }}</th>

            <th *ngIf="item.crateSize > 1" class="tg-0lax"></th>
            <th *ngIf="item.crateSize < 2" class="tg-0lax">
              {{ item.stockQuantity }}
            </th>

            <th class="tg-0lax" style="max-width: 50px">
              <input
                *ngIf="order.status === 'Requested'"
                type="number"
                (change)="calculateStock(item)"
                [(ngModel)]="item.modifier"
                class="input"
              />
              <div *ngIf="order.status !== 'Requested'">
                {{ item.modifier < 0 ? "" : item.modifier }}
              </div>
            </th>

            <th class="tg-0lax">{{ item.toOrder }}</th>
            <th class="tg-0lax">
              {{ item.orderedQuantity >= 0 ? item.orderedQuantity : "" }}
            </th>
            <th class="tg-0lax">
              {{ item.arrivedQuantity >= 0 ? item.arrivedQuantity : "" }}
            </th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
