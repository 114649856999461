<div class="bar-buttons">
  <button
    class="action-button selected"
    *ngIf="auth.accessUser && auth.accessUser.access >= 2"
    routerLink="/personeel/cleaning"
    routerLinkActive="true"
  >
    Cleaning Hours
  </button>

  <button
    class="action-button selected"
    *ngIf="auth.accessUser && auth.accessUser.access >= 2"
    routerLink="/personeel/hours"
    routerLinkActive="true"
  >
    Hours
  </button>

  <button
    class="action-button selected"
    *ngIf="auth.accessUser && auth.accessUser.access >= 3"
    routerLink="/personeel/staffinfo"
    routerLinkActive="true"
  >
    Staff Info
  </button>

  <button
    class="action-button selected"
    *ngIf="auth.accessUser && auth.accessUser.access >= 2"
    routerLink="/personeel/schedule2"
    routerLinkActive="true"
  >
    Schedule
  </button>

  <button
    class="action-button selected"
    *ngIf="auth.accessUser && auth.accessUser.access >= 4"
    routerLink="/personeel/weeklygift"
    routerLinkActive="true"
  >
    Forgot Weekly Gift
  </button>

  <button
    class="action-button selected"
    *ngIf="auth.accessUser && auth.accessUser.access > 4"
    routerLink="/personeel/staffdrinks"
    routerLinkActive="true"
  >
    Staff Drink Calculations
  </button>

  <button
    class="action-button selected"
    *ngIf="auth.accessUser && auth.accessUser.access > 3"
    routerLink="/personeel/stafforders"
    routerLinkActive="true"
  >
    Staff Drinks
  </button>

  <button
    class="action-button selected"
    *ngIf="auth.accessUser && auth.accessUser.access > 3"
    routerLink="/personeel/payslipuploader"
    routerLinkActive="true"
  >
    Payslip Uploader
  </button>

  <button
    class="action-button selected"
    *ngIf="auth.accessUser && auth.accessUser.access > 3"
    routerLink="/personeel/documents"
    routerLinkActive="true"
  >
    Document(s)
  </button>

  <button
    class="action-button selected"
    *ngIf="auth.accessUser && auth.accessUser.access >= 3"
    routerLink="/personeel/outingbudget"
    routerLinkActive="true"
  >
    Outing Budget
  </button>

  <button
    class="action-button selected"
    *ngIf="auth.accessUser && auth.accessUser.access > 10"
    routerLink="/personeel/staffReviews"
    routerLinkActive="true"
  >
    Reviews
  </button>
</div>
