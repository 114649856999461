import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "statusColor",
})
export class StatusColorPipe implements PipeTransform {
  transform(value: number): unknown {
    if (value == 0) {
      return "red";
    }

    if (value == 1) {
      return "yellow";
    }

    if (value == 2) {
      return "green";
    }
  }
}
