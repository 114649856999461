<app-calendar
  [date]="date"
  [data]="data"
  (dateChange)="dateChange($event)"
  (dateSelected)="dateSelected($event)"
></app-calendar>

<div class="icon-row">
  <div class="icon">
    <i class="fas fa-shirt" style="color: black"></i>
    <label for="">Were shirts worn</label>
  </div>

  <div class="icon">
    <i class="fas fa-user-clock"></i>
    <label for="">Arrived on time</label>
  </div>
</div>
<div class="icon-row">
  <div class="icon">
    <i class="fas fa-wine-bottle" style="color: rgb(211, 12, 72)"></i>
    <label for="">Stock Count</label>
  </div>

  <div class="icon">
    <i class="fa-solid fa-cash-register" style="color: green"></i>
    <label for="">Till Count</label>
  </div>

  <div class="icon">
    <i class="fas fa-sun" style="color: gold"></i>
    <label for="">Start Shift</label>
  </div>

  <div class="icon">
    <i class="fas fa-moon" style="color: rgb(117, 116, 116)"></i>
    <label for="">End Shift</label>
  </div>
</div>

<div class="icon-row">
  <div class="icon">
    <i class="fas fa-times" style="color: red"></i>
    <label for="">Not counted</label>
  </div>

  <div class="icon">
    <i class="fas fa-check" style="color: green"></i>
    <label for="">Counted & Correct</label>
  </div>

  <div class="icon">
    <i class="fas fa-exclamation" style="color: yellow"></i>
    <label for="">Counted & Incorrect</label>
  </div>
</div>
