<table>
  <div class="toolbar">
    <button class="toolbar-button" (click)="create()">Create</button>
    <button class="toolbar-button" *ngIf="!editingOrder" (click)="editOrder()">
      Edit Order
    </button>
    <button class="toolbar-button" *ngIf="editingOrder" (click)="saveOrder()">
      Save Order
    </button>
    <input
      class="toolbar-search"
      type="text"
      placeholder="Search..."
      [ngModel]="searchText"
      (input)="onSearchChanged($event)"
    />
  </div>
  <thead>
    <tr>
      <th class="thead-item thead-item-short">
        Id
        <app-sort-by
          name="Id"
          [currentSort]="sortBy"
          (clicked)="sort($event)"
        ></app-sort-by>
      </th>
      <th class="thead-item thead-item-set">
        Name
        <app-sort-by
          name="Name"
          [currentSort]="sortBy"
          (clicked)="sort($event)"
        ></app-sort-by>
      </th>
      <th class="thead-item thead-item-long">
        Type
        <app-sort-by
          name="Type"
          [currentSort]="sortBy"
          (clicked)="sort($event)"
        ></app-sort-by>
      </th>
      <th class="thead-item thead-item-long" style="width: 250px">
        Bars
        <app-sort-by
          name="Bars"
          [currentSort]="sortBy"
          (clicked)="sort($event)"
        ></app-sort-by>
      </th>
      <th class="thead-item thead-item-short" ngbTooltip="Price Per Unit">
        PPU
        <app-sort-by
          name="PPU"
          [currentSort]="sortBy"
          (clicked)="sort($event)"
        ></app-sort-by>
      </th>
      <th class="thead-item thead-item-short" ngbTooltip="Price Per Single">
        PPS (R)
        <app-sort-by
          name="Single"
          [currentSort]="sortBy"
          (clicked)="sort($event)"
        ></app-sort-by>
      </th>

      <th
        class="thead-item thead-item-short"
        ngbTooltip="Quantity of the stock at the Head Office"
      >
        Quantity
        <app-sort-by
          name="Quantity"
          [currentSort]="sortBy"
          (clicked)="sort($event)"
        ></app-sort-by>
      </th>

      <th class="thead-item thead-item-short" style="">Actions</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let i of filteredList">
      <td class="thead-item thead-item-short">{{ i.id }}</td>
      <td class="thead-item thead-item-set">{{ i.name }}</td>
      <td class="thead-item thead-item-long">{{ i.type }}</td>
      <td class="thead-item thead-item-long barsshorts" style="width: 250px">
        <div
          class="barshorthand"
          *ngFor="let b of i.bars | keyvalue"
          [style.backgroundColor]="b.key | stringToHex"
        >
          {{ b.key | initials }}
        </div>
      </td>
      <td class="thead-item thead-item-short">
        {{ i.ppu | currency : "EUR" }}
      </td>
      <td class="thead-item thead-item-short">
        {{ i.singlePrice | currency : "EUR" }}
      </td>
      <td class="thead-item thead-item-short">{{ i.quantity }}</td>

      <td
        class="thead-item thead-item-short"
        style="display: flex; justify-content: center"
      >
        <button *ngIf="!editingOrder" class="icon-button" (click)="openItem(i)">
          ...
        </button>
        <button *ngIf="editingOrder" class="icon-button" (click)="moveUp(i)">
          <i class="fas fa-chevron-up"></i>
        </button>
        <button *ngIf="editingOrder" class="icon-button" (click)="moveDown(i)">
          <i class="fas fa-chevron-down"></i>
        </button>
      </td>
    </tr>
  </tbody>

  <tfoot>
    <tr>
      <td colspan="6">Total: {{ stock.length }}</td>
    </tr>
  </tfoot>
</table>
