import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Device } from "src/app/Classes/device";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { AlertsService } from "src/app/Packages/alerts/alerts.service";
import { LoadingService } from "src/app/Services/loading.service";
import { SensorService } from "src/app/Services/sensor.service";
import { SensorModalComponent } from "../sensor-modal/sensor-modal.component";
import { Click } from "src/app/Classes/click";
import {
  ChartConfiguration,
  ChartDataset,
  ChartEvent,
  ChartOptions,
  ChartType,
} from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { LineChartComponent } from "@swimlane/ngx-charts";
import { enUS, tr } from "date-fns/locale";
import { difference } from "lodash";
import { Auth } from "src/app/auth/auth";

@Component({
  selector: "app-sensors",
  templateUrl: "./sensors.component.html",
  styleUrls: ["./sensors.component.scss"],
})
export class SensorsComponent implements OnInit {
  page: number = 0;
  sortBy = {
    key: "name",
    reverse: false,
  };
  devices: Device[] = [];
  isButton: boolean = false;
  dataSet: [string, Click[]][] = [];
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
  constructor(
    public alert: AlertsService,
    public loading: LoadingService,
    public bi: BarInfo,
    public sensors: SensorService,
    public modal: NgbModal,
    public crd: ChangeDetectorRef,
    public auth: Auth
  ) {}

  ngOnInit(): void {
    this.getDevices();

    this.generateLast24HoursData();
  }

  ngAfterViewInit() {
    this.charts.forEach((chart) => {
      chart.update();
    });
  }

  async getDevices() {
    this.loading.show();
    this.devices = (await this.sensors.getDevices()).map((device) => {
      device.selected = false;
      return device;
    });
    console.log(this.devices);
    this.setSortBy("name", false);
    this.loading.hide();
  }

  async getClicks(deviceId) {
    var now = new Date();
    var yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);
    yesterday.setHours(yesterday.getHours() - 2);
    var clicks = await this.sensors.getClicks(yesterday, now, deviceId);
    console.log(clicks);
    return clicks;
  }

  async selectDevice(device: Device) {
    device.selected = !device.selected;

    if (device.deviceType === "Button") {
      this.isButton = true;
    } else {
      this.isButton = false;
    }
    // deselect all other devices
    for (let d of this.devices) {
      if (d.serialNumber !== device.serialNumber) {
        if (d.deviceType == "Button") {
          if (!this.isButton) {
            d.selected = false;
          }
        }

        if (d.deviceType != "Button") {
          if (this.isButton) {
            d.selected = false;
          }
        }
      }
    }

    this.loading.show();
    this.dataSet = [];
    for (let device of this.devices) {
      if (device.selected) {
        var deviceClicks = await this.getClicks(device.serialNumber);
        this.dataSet.push([device.name, deviceClicks]);
      }
    }

    console.log(this.dataSet);
    var datasets = [];

    var batteryDatasets = [];
    var temperatureDatasets = [];
    var humidityDatasets = [];
    var luminanceDatasets = [];
    var clickDatasets = [];
    var doorDatasets = [];

    this.dataSet.forEach(([deviceName, clicks]) => {
      console.log(deviceName, clicks);
      var device = this.devices.find((d) => d.name === deviceName);
      switch (device.deviceType) {
        case "Button":
          clickDatasets.push({
            label: deviceName,
            data: clicks.map((click) => {
              return {
                x: click.timestamp,
                y:
                  click.clickType === "click"
                    ? 0
                    : click.clickType === "double_click"
                    ? 1
                    : 2,
              };
            }),
            yAxisID: "y-axis-2",
          });

          batteryDatasets.push({
            label: deviceName,
            data: clicks.map((click) => {
              return { y: click.battery, x: click.timestamp };
            }),
            fill: true,
          });

          break;
        case "Temperature":
          // can use humidity and bater on y-axis-1 and temperature on y-axis 2
          temperatureDatasets.push({
            label: deviceName,
            data: clicks.map((click) => {
              return { y: click.data?.TempC_SHT, x: click.timestamp };
            }),
          });

          batteryDatasets.push({
            label: deviceName,
            data: clicks.map((click) => {
              return { y: click.battery, x: click.timestamp };
            }),
            fill: true,
          });

          humidityDatasets.push({
            label: deviceName,
            data: clicks.map((click) => {
              return { y: click.data?.Hum_SHT, x: click.timestamp };
            }),
          });

          //luminance on y-axis-3
          luminanceDatasets.push({
            label: deviceName,
            data: clicks.map((click) => {
              return { y: click.data?.ILL_lux, x: click.timestamp };
            }),
          });
          break;
        case "Door":
          var doorLengthOpen = []; // merges open and closes to get the length of time the door was open
          for (let i = 0; i < clicks.length; i++) {
            if (clicks[i].data?.DoorStatus == "Open") {
              var clickData = Object.assign({}, clicks[i]);
              //remove all next until closed
              for (let j = i + 1; j < clicks.length; j++) {
                if (clicks[j].data?.DoorStatus == "Closed") {
                  i = j;
                  break;
                }
              }

              //add the closed click to the open click
              doorLengthOpen.push({
                start: clickData.timestamp,
                end: clicks[i].timestamp,
                difference:
                  (clicks[i].timestamp - clickData.timestamp) / 1000 / 60,
              });
            }
          }
          console.log(doorLengthOpen);

          doorDatasets.push({
            label: deviceName,
            data: doorLengthOpen.map((click, index) => {
              console.log("adding", click, index);
              return {
                x: index,
                y: click.difference,
                time: click.start,
              };
            }),
          });

          temperatureDatasets.push({
            label: deviceName,
            data: clicks.map((click) => {
              return { y: click.data?.TempC_SHT, x: click.timestamp };
            }),
          });

          batteryDatasets.push({
            label: deviceName,
            data: clicks.map((click) => {
              return { y: click.battery, x: click.timestamp };
            }),

            fill: true,
          });

          humidityDatasets.push({
            label: deviceName,
            data: clicks.map((click) => {
              return { y: click.data?.Hum_SHT, x: click.timestamp };
            }),
          });

          break;
      }
    });
    this.batteryChartData.datasets = batteryDatasets;
    this.temperatureChartData.datasets = temperatureDatasets;
    this.humidityChartData.datasets = humidityDatasets;
    this.luminanceChartData.datasets = luminanceDatasets;
    this.clickChartData.datasets = clickDatasets;
    this.doorChartData.datasets = doorDatasets;
    this.doorChartData.labels = doorDatasets[0]?.data.map((d, i) => i);

    console.log(this.lineChartData);
    this.charts.forEach((chart) => {
      chart.render();
      chart.update();
    });

    //make sure the charts are scaled and sized nicely

    this.crd.detectChanges();
    this.loading.hide();
  }

  createItem() {
    var device = new Device();
    this.openItem(device, true);
  }
  openItem(device: Device, newDevice = false) {
    const modal = this.modal.open(SensorModalComponent, {
      size: "lg",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modal.componentInstance.device = device;
    modal.componentInstance.newDevice = newDevice;

    modal.result.then((result) => {
      this.getDevices();
      console.log(result);
    });
  }

  setSortBy(key, changeDirection = true) {
    console.log("sortuing on", key, changeDirection);
    if (this.sortBy.key === key && !changeDirection) {
    } else if (this.sortBy.key === key) {
      this.sortBy.reverse = !this.sortBy.reverse;
    } else {
      this.sortBy.key = key;
      this.sortBy.reverse = false;
    }

    this.devices = this.devices.sort((a, b) => {
      if (a[this.sortBy.key] === undefined || a[this.sortBy.key] === null) {
        return 1;
      }
      if (b[this.sortBy.key] === undefined || b[this.sortBy.key] === null) {
        return -1;
      }

      if (a[this.sortBy.key] < b[this.sortBy.key]) {
        return this.sortBy.reverse ? 1 : -1;
      } else if (a[this.sortBy.key] > b[this.sortBy.key]) {
        return this.sortBy.reverse ? -1 : 1;
      } else {
        return 0;
      }
    });
  }

  async pageClicked(page) {
    console.log(page);
    this.page = page;
  }

  lineChartData: ChartConfiguration<"line">["data"] = {
    labels: [],
    datasets: [],
  };
  // Chart

  lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute", // Unit for minor ticks
          stepSize: 30, // 20 minutes for minor ticks
          displayFormats: {
            minute: "HH:mm",
            hour: "HH:mm", // Hour format
          },
        },
        ticks: {
          major: {
            enabled: true, // Enable major ticks
          },
        },
        adapters: {
          date: {
            locale: enUS, // Set locale if needed
          },
        },
      },
      "y-axis-1": {
        type: "linear",
        position: "left", // Left side
        title: {
          display: true,
          text: "Percent",
        },
        ticks: {
          color: "blue",
        },
        grid: {
          drawOnChartArea: true, // Allows gridlines for this axis
        },
      },
      "y-axis-2": {
        type: "linear",
        position: "right", // Right side
        title: {
          display: true,
          text: "Temperature",
        },
        ticks: {
          color: "green",
        },
        grid: {
          drawOnChartArea: false, // Prevents gridlines for this axis
        },
      },
      "y-axis-3": {
        type: "linear",
        position: "right", // Right side
        title: {
          display: true,
          text: "Illumination",
        },
        ticks: {
          color: "red",
        },
        grid: {
          drawOnChartArea: false, // Prevents gridlines for this axis
        },
      },
    },
  };

  labels: any[] = [];

  batteryChartData: ChartConfiguration<"line">["data"] = {
    labels: [],
    datasets: [],
  };
  batteryChartOptions: ChartOptions = {
    responsive: true,

    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          drawBorder: true,
          display: false,
        },
        type: "time",
        time: {
          unit: "minute", // Unit for minor ticks
          stepSize: 60, // 20 minutes for minor ticks
          displayFormats: {
            minute: "HH:mm",
            hour: "HH:mm", // Hour format
          },
        },
        ticks: {
          major: {
            enabled: true, // Enable major ticks
          },
        },
        adapters: {
          date: {
            locale: enUS, // Set locale if needed
          },
        },
      },
      y: {
        grid: {
          drawBorder: true,
          display: false,
        },
        type: "linear",
        position: "left", // Left side
        title: {
          display: true,
          text: "Percent",
        },
        ticks: {
          stepSize: 25,
        },
        beginAtZero: true,
        max: 100,
        min: 0,
      },
    },

    elements: {
      line: {
        tension: 0.2,
        fill: true,
      },
      point: {
        radius: 0,
        hitRadius: 12,
        hoverRadius: 12,
        hoverBorderWidth: 2,
      },
    },
  };

  temperatureChartData: ChartConfiguration<"line">["data"] = {
    labels: [],
    datasets: [],
  };

  temperatureChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          drawBorder: true,
          display: false,
        },
        type: "time",
        time: {
          unit: "minute", // Unit for minor ticks
          stepSize: 30, // 20 minutes for minor ticks
          displayFormats: {
            minute: "HH:mm",
            hour: "HH:mm", // Hour format
          },
        },
        ticks: {
          major: {
            enabled: true, // Enable major ticks
          },
        },
        adapters: {
          date: {
            locale: enUS, // Set locale if needed
          },
        },
      },
      y: {
        grid: {
          drawBorder: true,
          display: false,
        },
        type: "linear",
        position: "left", // Left side
        title: {
          display: true,
          text: "Temperature",
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
    elements: {
      line: {
        tension: 0.2,
        fill: true,
      },
      point: {
        radius: 0,
        hitRadius: 12,
        hoverRadius: 12,
        hoverBorderWidth: 2,
      },
    },
  };

  luminanceChartData: ChartConfiguration<"line">["data"] = {
    labels: [],
    datasets: [],
  };

  luminanceChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          drawBorder: true,
          display: false,
        },
        type: "time",
        time: {
          unit: "minute", // Unit for minor ticks
          stepSize: 30, // 20 minutes for minor ticks
          displayFormats: {
            minute: "HH:mm",
            hour: "HH:mm", // Hour format
          },
        },
        ticks: {
          major: {
            enabled: true, // Enable major ticks
          },
        },
        adapters: {
          date: {
            locale: enUS, // Set locale if needed
          },
        },
      },
      y: {
        type: "linear",
        position: "left", // Left side
        title: {
          display: true,
          text: "Illumination (Lux)",
        },
        ticks: {
          stepSize: 100,
        },

        grid: {
          drawBorder: true,
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.2,
        fill: true,
      },
      point: {
        radius: 0,
        hitRadius: 12,
        hoverRadius: 12,
        hoverBorderWidth: 2,
      },
    },
  };

  humidityChartData: ChartConfiguration<"line">["data"] = {
    labels: [],
    datasets: [],
  };

  humidityChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          drawBorder: true,
          display: false,
        },
        type: "time",
        time: {
          unit: "minute", // Unit for minor ticks
          stepSize: 30, // 20 minutes for minor ticks
          displayFormats: {
            minute: "HH:mm",
            hour: "HH:mm", // Hour format
          },
        },
        ticks: {
          major: {
            enabled: true, // Enable major ticks
          },
        },
        adapters: {
          date: {
            locale: enUS, // Set locale if needed
          },
        },
      },
      y: {
        type: "linear",
        position: "left", // Left side
        title: {
          display: true,
          text: "Humidity",
        },
        ticks: {
          stepSize: 10,
        },

        grid: {
          drawBorder: true,
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.2,
        fill: true,
      },
      point: {
        radius: 0,
        hitRadius: 12,
        hoverRadius: 12,
        hoverBorderWidth: 2,
      },
    },
  };

  clickChartData: ChartConfiguration<"line">["data"] = {
    labels: [],
    datasets: [],
  };

  clickChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute", // Unit for minor ticks
          stepSize: 30, // 20 minutes for minor ticks
          displayFormats: {
            minute: "HH:mm",
            hour: "HH:mm", // Hour format
          },
        },
        ticks: {
          major: {
            enabled: true, // Enable major ticks
          },
        },
        adapters: {
          date: {
            locale: enUS, // Set locale if needed
          },
        },
        grid: {
          drawBorder: true,
          display: false,
        },
      },
      y: {
        type: "linear",
        position: "left", // Left side
        title: {
          display: true,
          text: "Clicks",
        },
        ticks: {
          stepSize: 1,
        },

        grid: {
          drawBorder: true,
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0,
        fill: true,
      },
      point: {
        radius: 0,
        hitRadius: 12,
        hoverRadius: 12,
        hoverBorderWidth: 2,
      },
    },
  };

  doorChartData: ChartConfiguration<"bar">["data"] = {
    labels: [],
    datasets: [],
  };

  doorChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          stepSize: 1,
          callback: function (value, index, ticks) {
            // `value` corresponds to the index; map it to the time
            const dataset = this.chart.data.datasets[0]; // Assuming one dataset
            const dataPoint = dataset?.data[index] as any; // Access the dataset
            if (dataPoint?.time) {
              const date = new Date(dataPoint.time);
              return date.toLocaleTimeString(); // Format time as needed
            }
            return value.toString(); // Fallback to index
          },
        },
      },
      y: {
        type: "linear",
        position: "left", // Left side
        title: {
          display: true,
          text: "Door Open Time (minutes)",
        },
        ticks: {
          stepSize: 1,
        },

        grid: {
          drawBorder: true,
          display: false,
        },
      },
    },
    elements: {
      point: {
        radius: 8,
        hitRadius: 12,
        hoverRadius: 12,
        hoverBorderWidth: 2,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItem) {
            return "Door open time";
          },
          label: function (context) {
            var label = context.dataset.label || "";
            if ((context.dataset.data[context.dataIndex] as any).time) {
              var date = new Date(
                (context.dataset.data[context.dataIndex] as any).time
              );
              label = "" + date.toLocaleTimeString();
            }
            if (context.parsed.y !== null) {
              label += " for " + Math.ceil(context.parsed.y) + " minute(s)";
            }
            return label;
          },
        },
      },
    },
  };

  // Generate data for the last 24 hours
  generateLast24HoursData() {
    const data = [];
    const labels = [];
    const date = new Date();
    date.setHours(date.getHours() - 24);
    date.setMinutes(0);
    date.setSeconds(0);
    for (let i = 0; i < 24 * 4; i++) {
      labels.push(date.getTime());
      date.setMinutes(date.getMinutes() + 15);
    }

    this.lineChartData.labels = labels;
    this.batteryChartData.labels = labels;
    this.temperatureChartData.labels = labels;

    console.log(this.lineChartData, this.labels);
  }
}
