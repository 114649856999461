<div class="bar-buttons">
  <button
    *ngFor="let b of myFilter(bI.bars)"
    (click)="bI.setBar(b)"
    class="action-button"
    [disabled]="
      (!auth.accessUser || auth.accessUser.access <= 3) && !(bI.bar === b)
    "
    [class.selected]="bI.bar === b"
  >
    {{ b }}
  </button>
</div>
