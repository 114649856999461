import { Component, Input, OnInit } from "@angular/core";
import { BarInfo } from "src/app/Helpers/functions/BarInfo";
import { StaffAppDatabase } from "src/app/Services/staffappdatabase";

@Component({
  selector: "app-who-is-working",
  templateUrl: "./who-is-working.component.html",
  styleUrls: ["./who-is-working.component.css"],
})
export class WhoIsWorkingComponent implements OnInit {
  @Input("worked") worked = [];

  @Input("showPrice") showPrice = true;
  constructor(private db: StaffAppDatabase, private bI: BarInfo) {
    console.log("constructed");
  }

  ngOnInit(): void {}
}
